<template>
    <div :class="fullScreen ? 'full-screen' : ''">
        <div class="wflow-container" :class="['wflow-container-' + (wflowBtnPostion || 'top') + '-bar', { 'wflow-container-right': wflowview === 'appMain' }, frmViewStyle !== 'WEB' ? 'wflow-container-paper' : '']">
            <!-- 操作按钮 -->
            <div v-if="wflowview !== 'appMain'" class="header-title">
                <div v-if="btnPosition !== 'bottom'" class="section-title">
                    <svg-icon :icon-class="'wf_form'" style="font-size: 44px; color: #2f477e; margin-right: 6px" />
                    <p :class="vo.flFormSerial ? 'l-text' : 'l1-text'">当前状态：{{ curTN.tnNodeName === '' || !curTN.tnNodeName ? (vo.status === 8 ? '已归档' : vo.wfNodeNames) : curTN.tnNodeName }}</p>
                    <p v-if="vo.flFormSerial" class="r-text">业务单编号：{{ vo.flFormSerial }}</p>
                    <div class="formTapBtn">
                        <div class="tapItemBtn" :class="activeName === 'first' ? 'active' : ''" @click="handleTabsClick('first')">处理单</div>
                        <div v-if="showPrijectBtn" class="tapItemBtn" :class="activeName === 'xqspCon' ? 'active' : ''" @click="handleTabsClick('xqspCon')">{{ projectXqName }}</div>
                        <div v-if="((curTN.tnCanEdit == 1 && bodyFileEditor >= 1) || bodyfile != null) && bodyFileEditor != 1 && vo.editoral !== 0" class="tapItemBtn" :class="activeName === 'second' ? 'active' : ''" @click="handleTabsClick('second')">{{ TabTextBody }}</div>
                        <div v-if="frmViewStyle === 'DOC'" class="tapItemBtn" :class="activeName === 'attachment' ? 'active' : ''" @click="handleTabsClick('attachment')">附件</div>
                        <div v-if="!loading && flowChartShow" class="tapItemBtn" :class="activeName === 'flowChart' ? 'active' : ''" @click="handleTabsClick('flowChart')">流程图</div>
                        <div v-if="showLCGZ" class="tapItemBtn" :class="activeName === 'todoLog' ? 'active' : ''" @click="handleTabsClick('todoLog')">流转记录</div>
                        <div v-if="showLCGZ" class="tapItemBtn" :class="activeName === 'toreadLog' ? 'active' : ''" @click="handleTabsClick('toreadLog')">阅文记录</div>
                        <div v-if="showLCGZ" class="tapItemBtn" :class="activeName === 'completeLog' ? 'active' : ''" @click="handleTabsClick('completeLog')">流程分析</div>
                    </div>
                </div>
                <CommandBar v-if="!loading && (wflowBtnPostion === undefined || wflowBtnPostion === 'top')" ref="commandBar" :command-bar="commandBar" :show-btn-names="showBtnNames" />
            </div>
            <div class="mainform-container" :class="{ 'wflow-container-loaded': !loading }">
                <div v-show="activeName === 'first'" class="mainform-container-first">
                    <div v-if="!formEvents" class="page-container" :class="showHistoryIdeaList ? '' : 'not-have-bottom-idealist'">
                        <div v-loading="loading" element-loading-text="正在加载..." />
                    </div>
                    <div v-if="activeName === 'first' && mainformShow" :class="[(submitMode === 'sidebar' && wfJsonData && wfJsonData.routes && wfJsonData.routes.length > 0) || (frmViewStyle !== 'DOC' && wfInstance.busiDataId && activeName !== 'second' && frmLinkedFile && canEdit !== 1) || (frmViewStyle !== 'DOC' && wfInstance.busiDataId && activeName !== 'second' && frmLinkedFile && submitMode !== 'sidebar') || (ViewModeToggle && previewModeToggle === 1) ? 'mainform-have-sidebar' : '', ViewModeToggle && previewModeToggle === 1 && bodyfile && bodyfile.id ? 'mainform-previewModeToggle' : '']">
                        <el-scrollbar :native="false" tag="div">
                            <div v-show="formEvents" class="page-container" :class="showHistoryIdeaList ? '' : 'not-have-bottom-idealist'">
                                <!-- 跟随按钮 -->
                                <bottomBtn v-if="wflowview !== 'appMain' && wfInstance.passenger && wfInstance.passenger.showRightSidebar" ref="bottomB" :wf-instance="wfInstance" :show-submit-idea="wfJsonData != undefined && submitHandle != null" :show-body-file-btn="bodyfile !== null && bodyfile.id" @bottomOpenIdea="bottomClick" />
                                <div v-if="frmViewStyle === 'WEB'" class="form-container">
                                    <!-- 交办便签 -->
                                    <wfhandovernote :wf-jiaobans-received="wfJiaobansReceived" :wf-jiaobans-establish="wfJiaobansEstablish" />
                                    <!-- 处理状态 -->
                                    <div class="form-header">
                                        <div class="form-title">{{ vo.flFormName }}</div>
                                        <FilingSeal v-if="wfInstance.status === 8 || wfInstance.status === 9 || vo.nullify === 1" :wf-instance="wfInstance" :vo="vo" />
                                    </div>
                                    <el-form :model="vo" class="wflow-busiform">
                                        <!-- 拟稿纸顶部左右两个角标 -->
                                        <div v-if="frmViewStyle !== 'WEB'" class="top-page-margin" />
                                        <div v-if="frmViewStyle !== 'WEB'" class="form-title">{{ vo.flFormName }}</div>
                                        <div class="el-table el-table--fit el-table--border">
                                            <div class="el-table__body-wrapper">
                                                <!-- 业务表单 -->
                                                <BusiForm ref="busiForm" :wf-instance="wfInstance" :vo="vo" :cur-t-n="curTN" :can-edit="canEdit" />

                                                <BodyFile v-if="((curTN.tnCanEdit == 1 && bodyFileEditor >= 1) || bodyfile != null) && bodyFileEditor != 1 && vo.editoral !== 0" :body-file-title="$tx('ecwapp.Wflowform.BodyFile.fileBody', '文件正文')" :body-file-show="'table-row'" :vo="vo" :auto-save="true" :cur-t-n="curTN" :fl-bodyfile="bodyfile" :attachments="attachments" :wf-attach-url="wfAttachUrl" accept=".doc,.pdf,.ofd,.docx" :water-mark="true" />
                                            </div>
                                        </div>
                                        <!-- 拟稿纸顶部左右两个角标 -->
                                        <div v-if="frmViewStyle !== 'WEB'" class="bottom-page-margin" />
                                    </el-form>

                                    <!-- 历史意见 -->
                                    <HandleIdea :show-history-idea-list="showHistoryIdeaList" :wf-idea-list="wfIdeaList" class="wfIdeas-container" :wf-json-data="wfJsonData" />

                                    <!-- 富文本编辑器 -->
                                    <RichtextEditor v-if="vo.editoral == 0 || vo.editoral === null || bodyFileEditor == 1" v-model="vo.flContent" class="richtext-editor" toolbar="full" :height="richtextHeight" />

                                    <!-- 提交办理 -->
                                    <SubmitHandle v-if="formEvents && wfJsonData && wfJsonData.routes && submitMode !== 'dialog' && submitMode !== 'sidebar'" :cur-t-n="curTN" :wf-instance="wfInstance" :submit-handle="submitHandle" :wf-json-data="wfJsonData" :common-idea-list="commonIdeaList" :submit-template-list="submitTemplateList" />
                                    <!--关联公文  -->
                                    <AssociatedDocuments v-if="wfInstance.busiDataId && activeName !== 'second' && submitMode === 'sidebar' && frmLinkedFile" :cur-t-n="curTN" :is-save="false" :vo="vo" />
                                    <!-- 附件 -->
                                    <Attachment v-if="formEvents" ref="attachments" :show-download-link="true" :is-sort-attach="true" :cur-t-n="curTN" :vo="vo" :fl-bodyfile="bodyfile" :attachments="attachments" online-file=".doc,.docx,.xls,.xlsx,.ppt,.pptx" :wf-attach-url="wfAttachUrl" :viewer="onlineViewer" :online-editor="officeType" />
                                    <!-- 关联督办事项 -->
                                    <DcdbSumReportTable v-if="(wfInstance.module === 'sumReport' || wfInstance.module === 'dbReport') && wfInstance.wfName !== '会办工作反馈'" ref="sumReport" :vo="vo" :wf-instance="wfInstance" />
                                    <!-- 部门办理情况 -->
                                    <GwglInternalCommsInfo v-if="wfInstance.module === 'internalComms' && (wfInstance.passenger.showInternalComms === true || wfInstance.passenger.showInternalComms === 'true')" :vo="vo" />
                                </div>
                                <!-- form-container -->
                                <div v-else class="form-container">
                                    <el-form>
                                        <BusiForm ref="busiForm" :wf-instance="wfInstance" :vo="vo" :cur-t-n="curTN" :can-edit="canEdit" />
                                    </el-form>
                                    <!-- 历史意见 -->
                                    <HandleIdea v-show="false" :show-history-idea-list="showHistoryIdeaList" :wf-idea-list="wfIdeaList" class="wfIdeas-container" :wf-json-data="wfJsonData" />
                                </div>
                            </div>
                            <!-- page-container -->
                            <WfNodelogList :show-open-time="1" :show-sender="1" />
                        </el-scrollbar>
                    </div>
                    <div v-if="previewModeToggle === 2 && mainformShow && ((formEvents && wfJsonData && wfJsonData.routes && submitMode === 'sidebar') || (frmViewStyle !== 'DOC' && wfInstance.busiDataId && activeName !== 'second' && frmLinkedFile && canEdit !== 1) || (frmViewStyle !== 'DOC' && wfInstance.busiDataId && activeName !== 'second' && frmLinkedFile && submitMode !== 'sidebar'))" class="mainform-sidebar">
                        <el-scrollbar :native="false" tag="div">
                            <!-- 提交办理对话框 -->
                            <div class="submitSidebar">
                                <SubmitDialog v-if="formEvents && wfJsonData && wfJsonData.routes && submitMode === 'sidebar'" :shortcut-button="true" :cur-t-n="curTN" :wf-instance="wfInstance" :submit-handle="submitHandle" :wf-json-data="wfJsonData" :common-idea-list="commonIdeaList" :submit-template-list="submitTemplateList" />
                                <AssociatedDocumentsSidebar v-if="frmViewStyle !== 'DOC' && wfInstance.busiDataId && activeName !== 'second' && frmLinkedFile && submitMode !== 'sidebar'" :cur-t-n="curTN" :is-save="false" :vo="vo" show-mode="sidebar" />
                                <!-- 智能推荐对话框 -->
                                <IntelligentSearch v-if="!canEdit" :page-size="5" :wf-engine-url="wfEngineUrl" :vo="vo"></IntelligentSearch>
                            </div>
                        </el-scrollbar>
                    </div>
                    <div v-if="ViewModeToggle && previewModeToggle === 1 && activeName !== 'second' && bodyfile && bodyfile.id" class="mainform-sidebar sidebar-previewModeToggle">
                        <el-scrollbar :native="false" tag="div">
                            <div class="submitSidebar">
                                <!-- 正文预览框 -->
                                <ViewBodyFile ref="ViewBodyFile" :fl-bodyfile="bodyfile" :file-need-sign="fileNeedSign" :view-revisions="true" :revisions-topdf="false" :wf-attach-url="wfAttachUrl" :water-mark="true" :cur-t-n="curTN" :wf-instance="wfInstance" />
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
                <!-- 预览模式切换按钮 -->
                <!-- <FloatingButtons v-if="ViewModeToggle" v-show="activeName ==='first'&&bodyfile&&bodyfile.id" :preview-mode-toggle="previewModeToggle"></FloatingButtons> -->
                <div v-show="activeName === 'second' && ((curTN.tnCanEdit == 1 && bodyFileEditor >= 2) || bodyfile != null)" class="wflow-busiattach">
                    <!--正文编辑 -->
                    <div v-if="InlineTextEditor" class="mainform-have-sidebar" :style="{ width: InlineTextEditorShow ? '100%' : '' }">
                        <InlineTextEditor v-show="InlineTextEditorShow && officeType == 'ech5'" ref="InlineTextEditor" :fl-bodyfile="bodyfile" :wf-attach-url="wfAttachUrl" :office-type="officeType" :body-file-editor="bodyFileEditor">
                            <template #right-sidebar>
                                <!-- 这里放置您想要在右侧显示的内容 -->
                                <HtglContractClauseBank v-if="officeType == 'ech5' && wfInstance.module === 'htsp'" :vo="vo" :is-bookmark="false" :office-type="officeType" :wf-attach-url="wfAttachUrl" />
                            </template>
                        </InlineTextEditor>

                        <ViewBodyFile ref="ViewBodyFile" :fl-bodyfile="bodyfile" :file-need-sign="fileNeedSign" :view-revisions="true" :revisions-topdf="false" :wf-attach-url="wfAttachUrl" :water-mark="true" :cur-t-n="curTN" :wf-instance="wfInstance" />
                        <!-- 签名、盖章 -->
                        <PdfTools :seal-source="sealType" />
                        <!-- 文字识别 -->
                        <PDFInjector v-if="wfInstance.status < 1" @updateVOData="updateVOData" />
                    </div>

                    <!-- pdf预览 -->
                    <div v-if="!InlineTextEditor" class="mainform-have-sidebar">
                        <ViewBodyFile ref="ViewBodyFile" :fl-bodyfile="bodyfile" :file-need-sign="fileNeedSign" :view-revisions="true" :revisions-topdf="false" :wf-attach-url="wfAttachUrl" :water-mark="true" :cur-t-n="curTN" :wf-instance="wfInstance" />
                        <!-- 签名、盖章 -->
                        <PdfTools :seal-source="sealType" />
                        <!-- 文字识别 -->
                        <PDFInjector v-if="wfInstance.status < 1" @updateVOData="updateVOData" />
                    </div>

                    <div class="mainform-sidebar">
                        <!-- 正文及附件信息 -->
                        <div class="sidebar-attach-container">
                            <el-scrollbar :native="false" tag="div">
                                <BodyFile :body-file-title="$tx('ecwapp.Wflowform.BodyFile.fileBody', '文件正文')" :vo="vo" :auto-save="true" :cur-t-n="curTN" :fl-bodyfile="bodyfile" :attachments="attachments" :wf-attach-url="wfAttachUrl" :file-need-sign="fileNeedSign" accept=".doc,.pdf,.ofd,.docx,.tif,.tiff" :viewer="onlineViewer" :water-mark="true" show-mode="sidebar" />
                                <Attachment ref="attachments" :cur-t-n="curTN" :vo="vo" :fl-bodyfile="bodyfile" :attachments="attachments" online-file=".doc,.docx,.xls,.xlsx,.ppt,.pptx" :wf-attach-url="wfAttachUrl" :viewer="onlineViewer" :show-download-link="true" :online-editor="officeType" :batch-save-namefield="vo.flSubject + '[' + vo.flCreateDept + '].zip'" show-mode="sidebar" />
                                <!-- <AssociatedDocumentsSidebar v-if="frmLinkedFile && frmViewStyle === 'DOC' && bodyfile && bodyfile.id" :cur-t-n="curTN" :is-save="false" :vo="vo" show-mode="sidebar" /> -->
                                <AssociatedDocumentsSidebar v-if="wfInstance.busiDataId && activeName === 'second' && submitMode === 'sidebar' && frmLinkedFile" :vo="vo" show-mode="sidebar" />
                                <!-- 智能推荐对话框 -->
                                <IntelligentSearch :page-size="3" :wf-engine-url="wfEngineUrl" :vo="vo"></IntelligentSearch>
                            </el-scrollbar>
                        </div>
                    </div>
                </div>

                <div v-if="formEvents && frmViewStyle === 'DOC'">
                    <!-- 附件 -->
                    <Attachment v-show="activeName === 'attachment'" ref="attachments" :cur-t-n="curTN" :vo="vo" :fl-bodyfile="bodyfile" :attachments="attachments" :same-name-bodyfile="false" online-file=".doc,.docx,.xls,.xlsx,.ppt,.pptx" :wf-attach-url="wfAttachUrl" :viewer="onlineViewer" :show-download-link="true" :online-editor="officeType" :auto-upload="autoUpload" />
                </div>
                <div v-show="activeName === 'xqspCon'" style="height: calc(100vh - 70px); background: white">
                    <BusiformExt ref="hytzHyyt" :vo="vo" :can-edit="canEdit" :type-name="projectXqName" />
                </div>
                <el-scrollbar v-show="activeName !== 'first' && activeName !== 'xqspCon'" :native="false" tag="div">
                    <WfNodelogList :show-open-time="1" :show-sender="1" />
                </el-scrollbar>

                <!-- <CommandBar v-if="!loading" ref="commandBar" :command-bar="commandBar" style="margin-top: 22px;" /> -->
            </div>
            <!-- mainform-container -->

            <!-- 提交办理对话框 -->
            <SubmitDialog v-if="wfJsonData != undefined && submitHandle != null && submitMode === 'dialog'" :is-modal="true" :cur-t-n="curTN" :wf-instance="wfInstance" :submit-handle="submitHandle" :wf-json-data="wfJsonData" :common-idea-list="commonIdeaList" :submit-template-list="submitTemplateList" />

            <DoingDialog />
            <PrintFile v-if="isShow" />
            <FileSerial />
            <select-dialog v-if="wflowview !== 'appMain'" />
            <ModifyWfNodelog />
            <SelectOuterDeputy />
            <GwglGwjh v-if="wfInstance.passenger && wfInstance.passenger.sendGwFile" />
            <ScanFile v-if="wfInstance.passenger && wfInstance.passenger.shouwenOcr" ref="scanBodyfile" />
            <ForwardFile v-if="wfInstance.passenger && wfInstance.passenger.forwardFile" :wf-instance="wfInstance" />
            <!-- 发送催办 -->
            <UrgeFile :wf-params="wfParams" :elevel="vo.flELevel" :creator="wfInstance.creator" :title="wfInstance.title" />
            <!-- 催办记录 -->
            <ViewUrgedlog />
            <!-- 签名 -->
            <IdeaSignature ref="ideaSignature" />
            <SignFor :vo="vo" :wf-instance="wfInstance" :can-edit="canEdit" />
            <CommandBar v-if="!loading && wflowBtnPostion === 'bottom'" ref="commandBar" :command-bar="commandBar" :btn-position="wflowBtnPostion" />
            <!--公文范本-->
            <docmentModel />
            <BatchProcessing />
            <GwglGwjhReceiptFile />
            <!-- 替换附件 -->
            <ReplaceFile v-if="bodyfile !== null || attachments.length > 0" :attachments="attachments" :bodyfile="bodyfile" :wf-instance="wfInstance" :vo="vo" :wf-attach-url="wfAttachUrl" :update-attach-list="updateAttachList" />

            <!-- Word编辑器：易臣编辑控件：ecexe,金山WPS加载项：wpsjs -->
            <WebOffice v-if="bodyFileEditor === 2 && (officeType == 'ecexe' || officeType == 'wpsjs')" />
            <WpsOffice v-if="bodyFileEditor === 2 && (officeType == 'ecexe' || officeType == 'wpsjs')" :command-bar="commandBar" />
            <!-- Word编辑器：永中web编辑器：yzh5，金山web编辑器：wpsh5，易臣web编辑器：ech5 -->
            <H5Office v-else-if="officeType == 'yzh5' || officeType == 'wpsh5' || officeType == 'ech5'" :office-type="officeType" />
            <!-- 文件交办 -->
            <wfsubmission :cur-tn="curTnObject" :vo="vo" :wf-instance="wfInstance" />
            <!-- 作废文件 -->
            <NullifyRecords :wf-engine-url="wfEngineUrl" :wf-instance="wfInstance"></NullifyRecords>

            <!-- 转收发文弹框通用组件 -->
            <FromDialog v-if="FromDialogShow" ref="FromDialog" :wf-engine-url="wfEngineUrl" :wf-instance="wfInstance" :vo="vo" :bodyfile="bodyfile"></FromDialog>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { fullScreen } from '@/wapp/settings'

export default {
    components: {
        // WebOffice,
        // H5Office
        // BodyFile, Attachment
        // , hytzHyyt, FloatingButtons
    },
    props: {},
    data() {
        return {
            FromDialogShow: false,
            WpsOfficeShow: false,
            frmViewStyle: 'WEB',
            frmLinkedFile: 1, // 是否显示关联文件
            loading: true,
            wfParams: {},
            wfInstance: {},
            formEvents: null,
            vo: {},
            commandBar: [],
            wfIdeaList: [],
            wfJsonData: {},
            submitHandle: null,
            commonIdeaList: [],
            submitTemplateList: [],
            curTN: {},
            refsAttachments: [],
            attachments: [],
            bodyFileEditor: 0,
            bodyfile: null,
            noTracksFile: null, // 清稿文件对象
            fileNeedSign: 0,
            fzTimer: '',
            num: '',
            officeType: 'ecexe',
            onlineViewer: 'pdfjs',
            canEdit: 0,
            activeName: 'first',
            sealType: 'biceng', // 印章类型：biceng：百成盖章、eseal：e签宝
            routeInfo: '',
            showScreen: false,
            isShow: false,
            wflowview: null, // 流程表单打开方式
            submitMode: null,
            wflowBtnPostion: '',
            showLCGZ: false,
            docEditMode: 'RedTitle',
            showHistoryIdeaList: false, // 显示历史意见列表
            showBtnNames: ['套红'],
            isRedTitleBtn: false, // 是否显示套红按钮
            signedFileName: '',
            projectXqName: '',
            showPrijectBtn: false,
            InlineTextEditor: true, // 是否启用本地正文编辑（ech5）
            InlineTextEditorShow: false, // 本地正文编辑控制参数（ech5）
            TabTextBody: '正文',
            ViewModeToggle: true, // 是否启用分屏预览
            actionType: '', // 当前保存状态
            richtextHeight: 400, // 富文本编辑器高度
            previewModeToggle: 2, // 预览模式切换 1 正文预览  2 审批
            frmFullScreen: 0, // 是否全屏（表单控制）
            fullScreen: fullScreen, // 是否全屏（全局控制）
            mainformShow: false,
            flowChartShow: true,
            curTnObject: {},
            wfJiaobansEstablish: [], // 我发起的便签
            wfJiaobansReceived: [] // 我收到的便签
        }
    },
    computed: {
        ...mapGetters(['routes', 'loginUser'])
    },
    watch: {
        commandBar(val) {
            val.forEach((item) => {
                if (item.btnName === '流程跟踪') {
                    this.showLCGZ = true
                }
            })
        },
        formEvents() {
            if (this.formEvents !== null) {
                this.formEvents.onWflowFormOpened(this, this.wfParams, this.wfParams, this.wfEngineUrl)
            }
        },
        frmFullScreen: {
            handler(val) {
                if (val === 1) {
                    this.fullScreen = true
                } else {
                    this.fullScreen = false
                }
            },
            immediate: true
        },
        'curTN.tnCanEdit': {
            handler(val) {
                this.canEdit = val
            }
        },
        frmViewStyle(val) {
            const $scope = this
            // 表单样式为仿真doc时修改提交方式
            if (val === 'DOC') {
                this.fullScreen = false
            }
            if (val === 'DOC' && this.submitMode !== 'sidebar') {
                this.submitMode = 'dialog'

                for (let i = 0; i < $scope.attachments.length; i++) {
                    const originalName = $scope.attachments[i].originalName
                    if (originalName.includes('.doc') || originalName.includes('.docx')) {
                        if ($scope.wfInstance.passenger.docEditMode === 'NoTitle') {
                            this.isRedTitleBtn = true
                        } else {
                            this.isRedTitleBtn = false
                        }
                    }
                }

                this.commandBar.forEach((btn) => {
                    if (btn.btnJavascript !== null && typeof btn.btnJavascript === 'string' && btn.btnJavascript.indexOf('btnSubmitClick()') !== -1) {
                        btn.btnName = '办理'
                        btn.btnJavascriptBak = btn.btnJavascript
                        btn.btnJavascript = 'btnStartDoingClick()'

                        let btnClick = btn.btnJavascript
                        if (btnClick.indexOf('(') !== -1) {
                            let btnParams = btnClick.substring(btnClick.indexOf('(') + 1, btnClick.lastIndexOf(')'))
                            if (btnParams !== '') {
                                btnParams = btnParams.replace(/'/g, '').split(',')
                                btn.btnParams = btnParams
                            }
                            btnClick = btnClick.split('(')[0]
                        }
                        if (this[btnClick] !== undefined) {
                            btn.btnClick = this[btnClick]
                        }
                    }

                    // 套红按钮
                    if ($scope.wfInstance.haveDoc === 2 && btn.btnJavascript === 'btnNoTitleModifyFlTypeClick()') {
                        btn.btnName = '重新套红'
                    }
                })

                this.commandBar = this.commandBar.filter((item) => {
                    if ((item.btnName === '套红' || item.btnName === '重新套红') && this.isRedTitleBtn === false) {
                        // 不满足条件，返回 false，不包含该元素
                        return false
                    } else {
                        // 满足条件，保留该元素
                        return true
                    }
                })

                this.wflowBtnPostion = ''
                this.$nextTick(() => {
                    this.wflowBtnPostion = window.wflowBtnPostion
                })
            }
        },
        bodyfile(file) {
            // 非doc、docx文档不要编辑操作
            this.iframeOnload().then((dom) => {
                if (file.originalName) {
                    const fileNameArr = file.originalName.split('.')
                    const fileType = fileNameArr[fileNameArr.length - 1]
                    const isDoc = ['doc', 'docx'].includes(fileType)
                    if (!isDoc) {
                        const editButton = dom.contentDocument.querySelector('#edit')
                        if (editButton) {
                            editButton.style.display = 'none'
                        }
                    }
                }
            })
        }
    },
    created() {
        if (this.$route.path !== '/wflow') {
            this.wflowview = window.wflowview
        }
        this.submitMode = window.submitMode
        this.wflowBtnPostion = window.wflowBtnPostion
        this.wfParams = this.$route.query

        this.InlineTextEditor = window.InlineTextEditor // 是否启用内嵌式正文编辑，默认启用
        this.ViewModeToggle = window.ViewModeToggle // 是否启用内嵌式正文编辑，默认启用

        if (!this.ViewModeToggle) {
            this.changePreviewModeToggle()
        }

        if (this.wflowview === 'appMain') {
            this.wfParams = this.$wfEngine.getWfParams(this)
            this.$bus.emit('showNavSearch', false)
            document.querySelector('.app-container').style.padding = 0
            this.wflowBtnPostion = 'bottom'
        }
        this.$bus.on('selectConTemplate', this.selectConTemplate)

        setTimeout(() => {
            console.log(this.submitMode, 'submitMode')
            console.log(this.wfJsonData, 'wfJsonData')
            // console.log(this.wfJsonData.routes, 'wfJsonData.routes')

            console.log(this.frmViewStyle, 'frmViewStyle')
            console.log(this.wfInstance.busiDataId, 'wfInstance.busiDataId')
            console.log(this.activeName, 'activeName')
            console.log(this.frmLinkedFile, 'frmLinkedFile')
            console.log(this.submitMode, 'submitMode')

            console.log(this.ViewModeToggle, 'ViewModeToggle')
            console.log(this.previewModeToggle, 'previewModeToggle')
        }, 3000)
    },
    updated() {
        this.$bus.emit('setSubmitSidebarHeight')
        if (document.querySelector('.el-progress--line')) {
            document.querySelector('.el-progress--line').parentNode.style.width = '330px'
        }
    },
    beforeDestroy() {
        localStorage.removeItem('getListData')
        this.$bus.off('selectConTemplate', this.selectConTemplate)
        this.$bus.emit('showNavSearch', true)
        this.$bus.emit('showNavWflowTitle', false, {})
        document.querySelector('.app-container').style.padding = '10px'
    },
    mounted() {
        if (window.opener && window.opener.postMessage) {
            window.opener.postMessage(
                {
                    messageId: 'refresh' // 固定格式
                },
                '*'
            )
        }
        this.loadWfInstance()
        if (this.wflowview !== 'appMain') {
            const cookieStyle = this.Cookies.get('uiStyle')
            let style = ''
            if (cookieStyle) {
                style = cookieStyle
            } else {
                style = this.loginUser.uiStyle
                this.Cookies.set('uiStyle', style)
            }
            document.querySelector('body').setAttribute('id', this.loginUser.orgCode !== 'plat' ? style : 'skinScienceBlue')
        }
        // 仿真表单边线样式js
        this.fzTimer = setInterval(() => {
            console.log(11, this.num)
            const array = document.querySelectorAll('div[layer="3"]')
            if (array.length > 0) {
                array.forEach((item) => {
                    item.parentNode.style = 'border:none'
                })
            }
            const arraya = document.querySelectorAll('div[layer="2"]')
            if (arraya.length > 0) {
                arraya.forEach((item) => {
                    item.parentNode.style = 'border:none'
                })
            }
            const arrayc = document.querySelectorAll('div[layer="4"]')
            if (arrayc.length > 0) {
                arrayc.forEach((item) => {
                    item.parentNode.style = 'border:none'
                })
            }
            const arrayb = document.querySelectorAll('.isFilling-true')
            if (arrayb.length > 0) {
                arrayb.forEach((item) => {
                    // 多行文本铺满
                    let tempHeight = 0
                    if (item.nextElementSibling) {
                        tempHeight += Number(item.nextElementSibling.clientHeight)
                    }
                    if (item.previousElementSibling) {
                        tempHeight += Number(item.previousElementSibling.clientHeight)
                    }
                    tempHeight = Number(item.parentNode.clientHeight) - tempHeight
                    if (item.querySelector('.field-lbl')) {
                        tempHeight = tempHeight - Number(item.querySelector('.field-lbl').clientHeight)
                    }
                    if (item.querySelector('.el-textarea__inner')) {
                        item.querySelector('.el-textarea__inner').style = `min-height:${tempHeight - 18}px !important;`
                    }
                    item.parentNode.style = 'display:flex;flex-direction: column;'
                    item.style = 'width:100%;flex:1'
                    item.parentNode.lastChild.style = 'width:100%;height:100%'
                })
            }
            var elements = document.querySelectorAll('.row-ideaLables')
            for (var i = 0; i < elements.length; i++) {
                var element = elements[i]
                if (element.getAttribute('layer') === '1') {
                    if (element.childNodes.length > 0) {
                        element.childNodes.forEach((item) => {
                            if (item.childNodes.length > 2) {
                                if (item.childNodes[1].classList.contains('ideaContentDiv')) {
                                    item.style = 'border-right:1px solid #333333;border-bottom:1px solid #333333'
                                }
                            }
                        })
                    }
                }
            }
            if (array.length > 0 || arraya.length > 0 || arrayb.length > 0 || arrayc.length > 0 || elements.length > 0) {
                clearInterval(this.fzTimer)
            } else {
                this.num++
                if (this.num > 5) {
                    clearInterval(this.fzTimer)
                }
            }
        }, 500)
    },
    methods: {
        // 获取iframe
        iframeOnload() {
            return new Promise((resolve) => {
                const timer = setInterval(() => {
                    const iframe = document.querySelector('#pdfHtml')
                    if (!iframe) return
                    resolve(iframe)
                    clearInterval(timer)
                }, 1000)
            })
        },
        updateVOData(data) {
            this.$set(this.vo, data.key, data.value)
        },
        bottomClick() {
            if (this.showHistoryIdeaList) {
                this.handleTabsClick('todoLog')
            } else {
                this.$refs.bottomB.rollElement('.wfIdeas-container')
            }
        },
        insertBrowsingHistory(data) {
            const dataUrl = location.hash.substr(1)
            const params = {
                title: data.title,
                busiCode: data.module,
                busiType: data.wfAlias || data.wfName,
                busidataId: data.busiDataId,
                dataUrl
            }
            this.$wappUtils.setBrowsingHistory(this, params)
        },
        loadWfInstance() {
            new Promise((resolve, reject) => {
                if (this.loginUser.staffList.length === 0 && this.wfParams.wfName !== undefined && this.wfParams.busiCode !== undefined) {
                    this.$alert('您的账号没有机构信息，不能创建、处理文件。', '操作提示', {
                        type: 'error',
                        callback: () => {
                            this.btnCloseClick()
                        }
                    })
                    reject('no staff')
                } else if (this.loginUser.staffList.length > 1 && this.wfParams.wfName !== undefined && this.wfParams.busiCode !== undefined) {
                    this.loginUser.staffList.forEach(function (item) {
                        item.value = item.id
                        item.text = item.orgFullName.substring(item.orgFullName.indexOf('-') + 1)
                    })

                    this.$bus.emit(
                        'openListitemDialog',
                        '选择任职部门',
                        this.loginUser.staffList,
                        (rst) => {
                            if (rst.code === 'success') {
                                this.wfParams.staffId = rst.value
                                resolve(true)
                            } else {
                                this.btnCloseClick()
                                reject('cancel')
                            }
                        },
                        null,
                        null,
                        '您在多个部门任职，请选择：'
                    )
                } else {
                    resolve(true)
                }
            }).then(() => {
                const $scope = this
                this.$wfEngine.loadWfInstance(this, this.wfParams).then((rst) => {
                    if (rst.code === 'success') {
                        this.mainformShow = true
                        console.log(this)
                        if ((rst.body.wfInstance.wfName === '会议议题' || rst.body.wfInstance.wfName === '督办立项' || rst.body.wfInstance.wfName === '收文阅办') && this.$route.query.isGw) {
                            if (JSON.parse(localStorage.getItem('gwVo'))) {
                                const obj = JSON.parse(localStorage.getItem('gwVo'))
                                this.wfInstance.bodyDocId = this.$route.query.bodyDocId
                                this.wfInstance.haveDoc = Number(this.$route.query.haveDoc)
                                const oldObj = this.vo
                                if (rst.body.wfInstance.wfName === '收文阅办') {
                                    this.vo = obj
                                    this.vo.id = oldObj.id
                                    this.vo.flSwzh = oldObj.flSwzh
                                } else {
                                    this.vo.flSubject = obj.flSubject
                                }

                                if (JSON.parse(localStorage.getItem('newBodyfile'))) {
                                    this.bodyfile = JSON.parse(localStorage.getItem('newBodyfile'))
                                    // localStorage.removeItem('newBodyfile')
                                }
                                if ((obj.flBodyFile !== null && obj.flBodyFile) || (obj.flBodyFilename && obj.flBodyFilename !== null)) {
                                    this.attachments = obj.passenger.attachArr
                                } else if ((obj.signedFileName !== null || obj.signedFileName !== '') && obj.signedFileName) {
                                    this.attachments = obj.passenger.attachArr
                                } else if (this.wfInstance.haveDoc === 2 && (obj.signedFileName === null || obj.signedFileName === '')) {
                                    var isRedTitle = false
                                    var newObj = obj.passenger.attachArr.map((item) => {
                                        if (item.fileName.startsWith('RedTitle_')) {
                                            item.fileType = 'BODY'
                                            isRedTitle = true
                                        } else if (item.fileName.startsWith('NoTracks_') && !isRedTitle) {
                                            item.fileType = 'BODY'
                                        } else {
                                            item.fileType = 'ATTACH'
                                        }
                                        return item
                                    })
                                    this.attachments = newObj
                                }
                            }
                        }
                        console.log(this)

                        if (JSON.parse(localStorage.getItem('ksqcVo')) && this.$route.query.ksqc) {
                            const obj = JSON.parse(localStorage.getItem('ksqcVo'))
                            const oldId = this.vo.id
                            this.wfInstance.bodyDocId = this.$route.query.bodyDocId
                            this.wfInstance.haveDoc = Number(this.$route.query.haveDoc)
                            this.vo = obj
                            this.vo.id = oldId

                            if (JSON.parse(localStorage.getItem('newBodyfile'))) {
                                this.bodyfile = JSON.parse(localStorage.getItem('newBodyfile'))
                                // localStorage.removeItem('newBodyfile')
                            }

                            this.attachments = obj.passenger.attachArr
                        }

                        if (rst.body.wfInstance.passenger.wfJiaobans && rst.body.wfInstance.passenger.wfJiaobans.length > 0) {
                            this.wfJiaobansReceived = rst.body.wfInstance.passenger.wfJiaobans
                        }
                        this.curTnObject = rst.body.curTN
                        this.getWfJiaoban()

                        this.insertBrowsingHistory(rst.body.wfInstance)
                        this.$bus.emit('showNavWflowTitle', true, this.vo)
                        if (this.showHistoryIdeaList) {
                            this.$bus.emit('showWfNodelogList', this.wfInstance, 'historyIdea')
                        }

                        if (this.wfInstance.passenger.docEditMode) {
                            // 当前模式获取
                            this.docEditMode = this.wfInstance.passenger.docEditMode
                            if (this.wfInstance.module === 'htsp') {
                                this.docEditMode = 'RedTitle'
                                this.wfInstance.passenger.docEditMode = 'RedTitle'
                            }
                        }

                        if (this.docEditMode === 'NoTitle') {
                            // 判断当前是否有doc可编辑文件
                            for (let i = 0; i < $scope.attachments.length; i++) {
                                // 有doc可编辑正文 且 处于 NoTitle 模式下，可显示套红按钮
                                const originalName = $scope.attachments[i].originalName
                                if (originalName.includes('.doc') || originalName.includes('.docx')) {
                                    if (this.docEditMode === 'NoTitle') {
                                        this.isRedTitleBtn = true
                                    } else {
                                        this.isRedTitleBtn = false
                                    }
                                }
                            }
                            // 显示：另存为范文 按钮
                            window.isRedTitleBtn = this.isRedTitleBtn
                        }

                        // 套红按钮
                        if ($scope.wfInstance.haveDoc === 2) {
                            if (this.docEditMode === 'NoTitle') {
                                this.commandBar.forEach((value, index) => {
                                    if (value.btnJavascript === 'btnNoTitleModifyFlTypeClick()') {
                                        value.btnName = '重新套红'
                                    }
                                })
                            } else {
                                this.commandBar = this.commandBar.filter((item) => {
                                    return item.btnJavascript !== 'btnNoTitleModifyFlTypeClick()'
                                })
                            }
                        }

                        this.commandBar.forEach((item) => {
                            if (item.btnName === '上传盖章文件' && this.vo.signedFileName && this.vo.signedFileName !== null && this.vo.signedFileName !== '') {
                                item.btnName = '重新上传盖章文件'
                            }
                            if (item.btnCode === 'changePreviewModeToggle') {
                                item.btnName = this.previewModeToggle === 1 ? '切换单屏' : '切换双屏'
                            }
                        })

                        this.commandBar = this.commandBar.filter((item) => {
                            if ((item.btnName === '套红' || item.btnName === '重新套红') && this.isRedTitleBtn === false) {
                                // 不满足条件，返回 false，不包含该元素
                                return false
                            } else {
                                // 满足条件，保留该元素
                                return true
                            }
                        })

                        if (this.wfInstance.udmNodes) {
                            document.title = this.vo.flFormName + ' - ' + this.wfInstance.title
                        } else {
                            document.title = this.wfInstance.wfName + ' - ' + this.wfInstance.title
                        }
                        this.officeType = this.wfInstance.passenger.officeType || 'ecexe'
                        // this.officeType = 'ech5' // 临时调试用
                        // this.officeType = 'wpsjs' // 临时调试用
                        // this.officeType = 'ecexe' // 临时调试用

                        if (this.officeType === 'ech5' && window.InlineTextEditor) {
                            // 因当前内嵌编辑只支持ech5，故而满足此条件才开启内嵌式编辑正文
                            this.InlineTextEditor = true
                        } else {
                            this.InlineTextEditor = false
                        }
                        this.onlineViewer = this.wfInstance.passenger.onlineViewer || 'pdfjs'

                        let workform = this.curTN.workform || this.wfInstance.workForm
                        // workform = '@/wapp/ecoa/dzgg/form'
                        if (workform.indexOf('@/') === 0) {
                            workform = workform.replace(/@\/mapp/g, '@/wapp')
                            this.$options.components['BusiForm'] = this.$loadComponent(workform)
                            this.$nextTick(() => {
                                if (this.wfInstance.module === 'expenseAccount' || this.wfInstance.workForm.indexOf('/GwglFileForm') > -1) {
                                    this.formEvents = {
                                        onWflowFormOpened: this.$refs.busiForm.onWflowFormOpened,
                                        onWflowFormBeforeSubmit: this.$refs.busiForm.onWflowFormBeforeSubmit,
                                        onWflowFormSubmited: this.$refs.busiForm.onWflowFormSubmited
                                    }
                                    if (this.wfInstance.workForm.indexOf('/GwglFileForm') > -1) {
                                        this.flowChartShow = false
                                    }
                                } else {
                                    this.formEvents = {
                                        onWflowFormOpened: this.$options.components['BusiForm'].methods.onWflowFormOpened,
                                        onWflowFormBeforeSubmit: this.$options.components['BusiForm'].methods.onWflowFormBeforeSubmit,
                                        onWflowFormSubmited: this.$options.components['BusiForm'].methods.onWflowFormSubmited
                                    }
                                    const formCompData = this.$options.components['BusiForm'].data()
                                    if (formCompData.bodyFileEditor) {
                                        this.bodyFileEditor = formCompData.bodyFileEditor
                                        this.fileNeedSign = formCompData.fileNeedSign
                                    }
                                }
                            })
                        } else if (workform.indexOf('/dcontent') !== -1) {
                            workform = workform.split('/dcontent')[1]
                            if (workform.substring(workform.length - 1) === '/') workform = workform.substring(0, workform.length - 1)
                            workform = '#/dcontent/vuebusiform/W' + workform + '.vue?sysCode=' + this.wfInstance.sysCode
                            this.$options.components['BusiForm'] = this.$loadComponent(workform)
                        }

                        this.loading = false

                        try {
                            const query = this.$route.query
                            if (this.curTN.tnNodeID === null && query.portalStatus === '0') {
                                if (window.opener) {
                                    this.$message.warning('您的待办已经处理过，门户待办同步转已办。')
                                    window.opener.postMessage({ messageId: 'todoDone', pSysCode: query.pSysCode, todoId: query.todoId }, '*')
                                }
                            }
                        } catch (e) {
                            console.log(e)
                        }

                        if (this.curTN.openedReadItemList && this.curTN.openedReadItemList.length > 0) {
                            const btnRefresh = window.opener.document.querySelector('.el-icon-refresh')
                            if (btnRefresh !== null) btnRefresh.parentElement.click()
                        }
                        this.$forceUpdate()
                        const hasBodyFile = $scope.attachments.some((item) => item.fileType === 'BODY' && (this.$set(this.vo, 'editoral', 1), true))
                        if (this.wfInstance.module === 'document') {
                            if (this.wfInstance.status === -1) {
                                this.$set(this.vo, 'editoral', null)
                            }

                            if (!hasBodyFile) {
                                this.$set(this.vo, 'editoral', null)
                            }

                            if (this.vo.flContent) {
                                this.$set(this.vo, 'editoral', 0)
                                this.commandBar.forEach((item) => item.btnCode === 'btnOpenWordClick' && (item.btnShow = false))
                            }

                            // 动态控制富文本框高度
                            setTimeout(() => {
                                if (this.vo.editoral === 0 || this.vo.editoral === null) {
                                    const editoral = this.vo.editoral
                                    this.vo.editoral = 1
                                    const h = (s) => document.querySelector(s).offsetHeight
                                    this.richtextHeight = Math.max(h('.mainform-container .page-container') - h('.mainform-container .form-header') - h('.mainform-container .wflow-busiform') - 385, 400)
                                    this.$nextTick(() => {
                                        this.vo.editoral = editoral
                                    })
                                }
                            }, 100)
                        }

                        if (!hasBodyFile) {
                            this.changePreviewModeToggle(2)
                        }

                        if (localStorage.activeName && this.vo.status > -1) {
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.activeName = 'second'
                                    this.changePreviewModeToggle(2)
                                    localStorage.removeItem('activeName')
                                }, 550)
                            })
                        }

                        // 激活流程结束调用智能推荐
                        setTimeout(() => {
                            this.$bus.emit('IntelligentSearch', this)
                        }, 500)

                        // 正文标气名称修改
                        if (this.wfInstance.module === 'tbwjsp') {
                            this.TabTextBody = '投标文件'
                            setTimeout(() => {
                                this.$refs.BodyFile.$el.querySelector('.tdlbl').innerText = '投标文件'
                                this.$refs.BodyFile.$el.querySelector('input').placeholder = '请上传投标文件'
                                console.log(this.$refs.BodyFile.$el.innerText, ' ---- this')
                            }, 1000)
                        } else if (this.wfInstance.module === 'tbhtsp') {
                            this.TabTextBody = '合同正文'
                            setTimeout(() => {
                                this.$refs.BodyFile.$el.querySelector('.tdlbl').innerText = '合同文件'
                                this.$refs.BodyFile.$el.querySelector('input').placeholder = '请上传合同文件'
                                console.log(this.$refs.BodyFile.$el.innerText, ' ---- this')
                            }, 1000)
                        }
                    } else {
                        this.$alert(rst.message, '操作提示', {
                            type: 'error',
                            callback: () => {
                                this.btnCloseClick()
                            }
                        })
                    }
                })
            })
        },
        // 获取当前环节创建的交办信息
        getWfJiaoban() {
            this.$http({
                method: 'post',
                url: 'base/wfJiaoban/listdata',
                params: {
                    pageNum: 1,
                    pageSize: 60000,
                    urlsearch: `nodeLogId:${this.curTnObject.tnID}`
                }
            }).then((res) => {
                if (res.code === 'success') {
                    this.wfJiaobansEstablish = res.body.pageInfo.list
                }
            })
        },
        btnCloseClick() {
            if (this.wflowview === 'appMain') {
                history.back()
                this.$store.dispatch('tagsView/delView', this.$route)
            } else {
                window.close()
            }
        },
        btnSaveAndCloseClick() {
            this.btnSubmitClick('SaveAndClose')
        },
        btnSaveClick() {
            this.btnSubmitClick('Save')
        },
        btnClaimClick() {
            this.$confirm('您是否确认认领办理权限？', '确认操作', { type: 'warning' }).then(() => {
                this.btnSubmitClick('SaveAndClaim')
            })
        },
        btnDeleteClick() {
            this.$confirm('您是否确认注销文件？', '确认操作', { type: 'warning' }).then(() => {
                this.$bus.emit('showDoingDialog')
                this.$wfEngine.saveWfInstance(this, 'WriteOff', this.wfParams).then((res) => {
                    if (res.code === 'success') {
                        try {
                            if (window.opener) {
                                const btnRefresh = window.opener.document.querySelector('.el-icon-refresh')
                                if (btnRefresh !== null) {
                                    btnRefresh.parentElement.click()
                                } else {
                                    window.opener.postMessage({ messageId: 'refresh' }, '*')
                                }
                            }
                        } catch (e) {
                            window.opener.postMessage({ messageId: 'refresh' }, '*')
                            console.log(e)
                        }

                        this.btnCloseClick()
                    } else {
                        this.$bus.emit('closeDoingDialog')
                        this.$alert(res.message, '操作提示', { type: 'error' })
                    }
                })
            })
        },
        btnScanFileClick() {
            this.$bus.emit('btnScanFileClick', this)
        },
        btnStampBodyFileClick() {
            // 上传盖章文件事件
            this.handleTabsClick('second')
            // if ($scope.activeName !== 'second') $scope.activeName = 'second'
            this.$nextTick(() => {
                setTimeout(() => {
                    this.$bus.emit('btnStampBodyFileClick', this.btnStampBodyFileClick)
                }, 10)
            })
        },
        // 关注文件
        btnAttendFileClick() {
            if (this.curTN.tnID) {
                this.$bus.emit('workflowSetAttend', this, 1)
            } else {
                this.$alert('当前文件无法关注', '操作提示', { type: 'warning' })
            }
        },
        btnVerificationSignature() {
            // 验签
            const $scope = this
            if (!$scope.vo.sealFileName || $scope.vo.sealFileName === '') {
                this.$alert('请盖章后再验签。', '操作提示', { type: 'warning' })
            } else {
                this.$http({
                    method: 'get',
                    url: '/seal/esign/verifySeal?fileKey=' + $scope.vo.sealFileName,
                    contentType: 'application/x-www-form-urlencoded'
                }).then((response) => {
                    if (response.code === 'success') {
                        this.$message.success('验签成功')
                    } else {
                        this.$message.error(response.message)
                    }
                })
            }
        },
        btnStampClick(stampType) {
            // 盖章
            this.embeddedEditorToggle(true, 'btnStampClick')
            const $scope = this
            if ($scope.activeName !== 'second') $scope.activeName = 'second'
            this.$nextTick(() => {
                this.$bus.emit('btnFindStamp', $scope, stampType)
            })
        },
        // 文件交办
        btnSubmission() {
            console.log('btnSubmissionOpen', this.curTnObject, this.wfJiaobansEstablish, this.vo)
            this.curTnObject.flSubject = this.vo.flSubject
            if (this.wfJiaobansEstablish.length > 0) {
                this.$bus.emit('btnSubmissionOpen', this.curTnObject, this.wfJiaobansEstablish)
            } else {
                this.$bus.emit('btnSubmissionOpen', this.curTnObject, [])
            }
        },
        // 会议通知转督办
        btnToDuBan() {
            this.isShow = true
            this.$nextTick(() => {
                this.$bus.emit('btnToDuBan', this)
                this.isShow = false
            })
        },
        //通用弹框
        btnOpenFromDialog(formType, formShow) {
            this.FromDialogShow = false
            this.FromDialogShow = true
            this.$options.components['FromDialog'] = Vue.options.components[formType]
            this.$nextTick(() => {
                this.$bus.emit(formShow, this)
            })
        },
        btnExchangeReceiptClick() {
            this.$bus.emit('receiptFiles', this.wfInstance, 1)
        },
        btnTianxiewenhaoClick() {
            this.$bus.emit('btnTianxiewenhaoClick', this)
        },
        btnModifyAttachClick() {
            // 替换附件
            if (!this.bodyfile && this.attachments.length === 0) {
                this.$message.warning('没有可替换的附件！')
                return
            }
            this.$bus.emit('clickReplaceFile')
        },
        // 清稿事件
        clearEditingTraces() {
            console.log('清稿clearEditingTraces')
            // 清稿标识 NoTracks
            this.btnOpenWordClick('NoTracks')
        },
        btnOpenWordClick(type) {
            // 正文编辑打开
            this.formValidate(() => {
                // if (!this.vo.editoral && this.wfInstance.module === 'document') {
                //     return this.$message.warning('请先切换到office编辑器！')
                // }
                if (this.bodyFileEditor !== 2 && this.wfInstance.passenger.docEditMode !== 'NoTitle') {
                    this.$alert('当前表单不支持编辑正文！', '操作提示', { type: 'warning' })
                    return
                }

                new Promise((resolve, reject) => {
                    if (this.InlineTextEditor && this.vo.status < 2) {
                        this.btnSubmitClick('AutoSave', (saveRst, a, b, c) => {
                            if (saveRst) {
                                console.log(this.commandBar, '这里控制编辑正文按钮隐藏')
                                this.embeddedEditorToggle(true)
                                this.$nextTick(() => {
                                    resolve(true)
                                })
                            } else {
                                return
                            }
                        })
                    } else {
                        resolve(true)
                    }
                }).then((result) => {
                    if (!result) return
                    new Promise((resolve, reject) => {
                        const secretHint = this.wfInstance.passenger.fileSecretHint
                        if (secretHint !== undefined && secretHint !== '') {
                            this.$alert(secretHint, '操作提示', {
                                confirmButtonText: '确定',
                                type: 'warning',
                                callback: (action) => {
                                    resolve(true)
                                }
                            })
                        } else {
                            resolve(true)
                        }
                    }).then((result) => {
                        if (this.wfInstance.passenger.docEditMode) {
                            // 当前模式获取   RedTitle or NoTitle
                            this.docEditMode = this.wfInstance.passenger.docEditMode
                            if (this.wfInstance.module === 'htsp') {
                                this.docEditMode = 'RedTitle'
                                this.wfInstance.passenger.docEditMode = 'RedTitle'
                            }
                        }
                        if (!result) return
                        if (this.vo.signedFileName && this.vo.signedFileName !== null && this.vo.signedFileName !== '') {
                            this.$confirm('该公文已经盖章，重新编辑正文后需要重新盖章！<br>是否继续？', '确认操作', { type: 'warning', dangerouslyUseHTMLString: true })
                                .then(() => {
                                    this.$bus.emit('btnOpenWordClick', this, type)
                                })
                                .catch(() => {
                                    this.embeddedEditorToggle(false)
                                    return
                                })
                        } else {
                            // && this.wfInstance === 0
                            if (this.docEditMode === 'NoTitle') {
                                if (this.wfInstance.haveDoc === 2) {
                                    // 再次套红  secondRed
                                    this.$confirm(`该公文已经套红，重新编辑正文后需要重套红！<br>是否继续？`, '确认操作', { type: 'warning', dangerouslyUseHTMLString: true })
                                        .then(() => {
                                            this.$bus.emit('btnOpenWordClick', this, type)
                                        })
                                        .catch(() => {
                                            // 处理选择否的情况
                                            this.embeddedEditorToggle(false)
                                            return
                                        })
                                } else if (this.wfInstance.haveDoc === 1) {
                                    this.$bus.emit('btnOpenWordClick', this, type)
                                } else {
                                    this.$bus.emit('selectDocmentModel')
                                }
                            } else {
                                this.$bus.emit('btnOpenWordClick', this, type)
                            }
                        }
                    })
                })
            })
        },
        /**
         * 内嵌式编辑器打开/取消动作
         */
        embeddedEditorToggle(Boolean, type) {
            if (this.InlineTextEditor) {
                if (type === 'btnStampClick') {
                    this.InlineTextEditorShow = false
                } else {
                    this.InlineTextEditorShow = Boolean
                }

                this.commandBar.forEach((item) => {
                    if (item.btnName === '编辑正文' || item.btnName === '套红' || item.btnName === '重新套红') {
                        item.btnShow = !Boolean
                    }
                })
            }
        },
        btnModifyFlTypeClick() {
            this.activeName = 'second'
            this.embeddedEditorToggle(true)
            window.setTimeout(() => {
                this.$bus.emit('btnModifyFlTypeClick', this)
            }, 0)
        },
        btnNoTitleModifyFlTypeClick() {
            this.activeName = 'second'
            this.embeddedEditorToggle(true)
            window.setTimeout(() => {
                this.$bus.emit('btnNoTitleModifyFlTypeClick', this)
            }, 0)
        },
        selectConTemplate() {
            this.btnSubmitClick('AutoSave', (saveRst, a, b, c) => {
                if (saveRst) {
                    this.activeName = 'second'
                    this.embeddedEditorToggle(true)
                    window.setTimeout(() => {
                        this.$bus.emit('selectContractTemplate', this)
                    }, 0)
                } else {
                    return
                }
            })
        },
        btnSendFileClick(sendType, area) {
            console.log(this.vo, 449)
            if (sendType === undefined) sendType = 'deptFile'
            // this.$bus.emit('btnSendFileClick', this, sendType, area)
            const flSerial = this.vo.flSerial || this.vo.flFwzh
            if (!flSerial) {
                this.$confirm('您要发送的公文没有填写文号，请确认是否继续发送？', '确认操作', { type: 'warning' }).then(() => {
                    this.$bus.emit('btnSendFileClick', this, sendType, area)
                })
            } else if (!this.vo.signedFileName) {
                this.$confirm('您要发送的公文没有盖章，请确认是否继续发送？', '确认操作', { type: 'warning' }).then(() => {
                    this.$bus.emit('btnSendFileClick', this, sendType, area)
                })
            } else {
                this.$bus.emit('btnSendFileClick', this, sendType, area)
            }
        },
        async queryIsCollection() {
            const result = await this.$http({
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: [
                    { compare: '=', field: 'busiDataId', merge: true, value: this.wfInstance.busiDataId },
                    { compare: '=', field: 'ownerId', merge: true, value: this.loginUser.personId },
                    { compare: '=', field: 'orgCode', merge: true, value: this.loginUser.orgCode }
                ],
                url: 'user/collection/querydata'
            })
            if (result && result.code === 'success') {
                return result.body.listdata ? result.body.listdata[0] : null
            } else {
                return null
            }
        },
        // 收藏文件
        async btnCollectionClick() {
            const collectionVo = await this.queryIsCollection()
            if (collectionVo) {
                collectionVo.createTime = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                collectionVo.title = this.wfInstance.title
                collectionVo.module = this.wfInstance.module
                this.$http({
                    method: 'PUT',
                    url: 'user/collection/update',
                    data: collectionVo
                }).then((result) => {
                    if (result.code === 'success') {
                        this.$message.success('更新收藏成功')
                    } else {
                        this.$message.error('更新收藏失败：' + result.message)
                    }
                })
            } else {
                this.$http({
                    method: 'POST',
                    contentType: 'application/x-www-form-urlencoded',
                    url: 'user/collection/addNew'
                }).then((res) => {
                    if (res.code === 'success') {
                        const params = res.body.vo
                        params.busiDataId = this.wfInstance.busiDataId
                        params.operationType = 1
                        params.sendUser = this.wfInstance.creator
                        params.title = this.wfInstance.title
                        params.module = this.wfInstance.module
                        params.type = '文件'
                        params.url = location.hash.substr(1)
                        this.$http({
                            method: 'POST',
                            contentType: 'application/x-www-form-urlencoded',
                            url: 'user/collection/insert',
                            data: params
                        }).then((result) => {
                            if (result.code === 'success') {
                                this.$message.success('收藏成功')
                            } else {
                                this.$message.error('收藏失败：' + result.message)
                            }
                        })
                    }
                })
            }
        },
        btnViewSendlogClick() {
            this.$bus.emit('btnViewSendlogClick', this)
        },
        btnPrintWebFormClick() {
            this.isShow = true
            this.$nextTick(() => {
                this.$bus.emit('btnPrintWebFormClick', this)
                this.isShow = false
            })
        },
        btnPrintFileClick() {
            this.isShow = true
            this.$nextTick(() => {
                this.$bus.emit('btnPrintFileClick', this)
                this.isShow = false
            })
        },
        // 下载拟稿纸
        btnPrintGzClick() {
            this.isShow = true
            this.$nextTick(() => {
                this.$bus.emit('btnPrintGzClick', this, 'DOC')
                this.isShow = false
            })
        },
        // 打印拟稿纸
        btnPrintGzInlineClick() {
            this.isShow = true
            this.$nextTick(() => {
                this.$bus.emit('btnPrintGzClick', this, 'PDF')
                this.isShow = false
            })
        },
        btnCreateCpbClick(cpbType) {
            this.isShow = true
            this.$nextTick(() => {
                this.$bus.emit('btnPrintGzClick', this, 'PDF')
                this.isShow = false
            })
        },
        btnSaveAsClick() {
            this.isShow = true
            this.$nextTick(() => {
                this.$bus.emit('btnSaveAsClick', this)
            })
        },
        btnForwardClick(busiType) {
            // 起草关联发文：busiType='qcglfw'，普通转发文：busiType为空
            this.$bus.emit('btnForwardClick', this, busiType)
        },
        btnSubmitClick(actionType, saveCallback, formSubmitType) {
            if (window.bodyEditor && window.bodyEditor.status === 'work') {
                // 开始时间到完成时间的秒数
                const timeLength = (new Date().getTime() - window.bodyEditor.lastTime.getTime()) / 1000
                if (timeLength < 30) {
                    this.$alert('正在编辑正文，不允许关闭，请先完成正文编辑！', '操作提示', { type: 'warning' })
                    return
                }
            } else if (document.querySelector('#webnotifyspan') && document.querySelector('#webnotifyspan').innerText) {
                const saveInfo = JSON.parse(document.querySelector('#webnotifyspan').innerText)
                if (saveInfo.code === 'opened' && saveInfo.uploadPath.indexOf(this.wfInstance.busiDataId) !== -1) {
                    this.$alert('正在编辑正文，不允许关闭，请先完成正文编辑！', '操作提示', { type: 'warning' })
                    return
                }
            }

            this.actionType = actionType

            const _this = this
            this.formValidate(
                () => {
                    if (this.curTN.tnWaitNode) {
                        this.$alert('您需要等待“' + this.curTN.tnWaitNode + '”处理结束后，才能继续处理!', '操作提示', { type: 'warning' })
                        return
                    }
                    if (actionType === undefined) actionType = 'Submit'
                    this.actionType = actionType

                    if (this.InlineTextEditorShow && actionType !== 'AutoSave') {
                        this.$refs.InlineTextEditor.$refs.ecweboffice.save()
                        return
                    }

                    if (this.$refs['scanBodyfile'] && this.$refs['scanBodyfile'].attachAdds !== null) {
                        this.$refs['scanBodyfile'].uploadBodyFile(actionType)
                        return
                    }

                    if (actionType.indexOf('Submit') !== -1 && this.wfInstance.status < 1 && (this.bodyfile === null || this.bodyfile.name === '') && this.bodyFileEditor > 1 && this.fileIsMust !== 0 && (this.wfInstance.module !== 'document' || this.editoral)) {
                        let errmsg = ''
                        if (this.bodyFileEditor === 2 || this.bodyFileEditor === 4) {
                            errmsg = '请编辑正文！'
                        } else {
                            errmsg = '请上传正文！'
                        }
                        this.activeName = 'second'
                        this.$alert(errmsg, '操作提示', { type: 'warning' })
                        return
                    }

                    this.actionType = formSubmitType || actionType

                    this.submitHandle.submitType = actionType
                    new Promise((resolve, reject) => {
                        this.formEvents.onWflowFormBeforeSubmit(this, { resolve, reject })
                    }).then((result) => {
                        if (!result) return

                        const res = this.$wfEngine.checkSubmitHandle(this, actionType)
                        if (res.code !== 'success') {
                            if (this.submitMode !== 'dialog') {
                                this.activeName = 'first'
                                this.$nextTick(() => {
                                    const element = document.querySelector('.submit-handle-container')
                                    document.querySelector('.mainform-container .el-scrollbar__wrap').scrollTop = Number(element ? element.offsetTop : 46) - 45
                                    this.$alert(res.message, '操作提示', { type: 'warning' })
                                })
                            } else {
                                this.$alert(res.message, '操作提示', { type: 'warning' })
                            }
                            return
                        }
                        new Promise((resolve, reject) => {
                            if (actionType === 'Submit') {
                                let confirmMsg = ''
                                if (this.curTN.tnIdeaIsMust === 9 && this.curTN.tnIdeaLabelID !== null && (this.submitHandle.curUserIdea === null || this.submitHandle.curUserIdea.trim() === '')) {
                                    confirmMsg = '您还未填写意见，是否继续' + this.wfJsonData.routes[this.submitHandle.route.index].name + '？'
                                } else if (this.curTN.tnIdeaSignType !== 8) {
                                    confirmMsg = '您是否确认' + this.wfJsonData.routes[this.submitHandle.route.index].name + '？'
                                }
                                if (confirmMsg !== '') {
                                    this.$confirm(confirmMsg, '确认操作', { type: 'warning' })
                                        .then(() => {
                                            resolve(true)
                                        })
                                        .catch((err) => {
                                            if (err !== 'cancel') {
                                                console.error(err)
                                            }
                                            reject(err)
                                        })
                                } else {
                                    resolve(true)
                                }
                            } else {
                                resolve(true)
                            }
                        }).then((result) => {
                            if (actionType !== 'AutoSave') this.$bus.emit('showDoingDialog')

                            this.$wfEngine
                                .checkOuterDeputy(this, this.wfParams)
                                .then(async (res) => {
                                    if (res.code !== 'success') {
                                        return Promise.reject(res)
                                    }

                                    // 上传附件
                                    let allSuccess = true
                                    for (let i = 0; i < _this.refsAttachments.length; i++) {
                                        const uploadRes = await _this.refsAttachments[i].uploadWflowAttach(this)
                                        if (uploadRes.code === 'success') {
                                            if (allSuccess) {
                                                if (this.refsAttachments.length === i + 1) {
                                                    return uploadRes
                                                }
                                            }
                                        } else {
                                            allSuccess = false
                                            return uploadRes
                                        }
                                    }

                                    if (!allSuccess) {
                                        this.$bus.emit('closeDoingDialog')
                                        return Promise.reject({ code: 'failure', message: '上传文件失败' })
                                    } else {
                                        return res
                                    }
                                })
                                .then((res) => {
                                    if (res.code !== 'success') {
                                        return Promise.reject(res)
                                    }
                                    // 添加签名
                                    if (this.curTN.tnIdeaSignType === 8 && actionType === 'Submit') {
                                        return this.$refs.ideaSignature.getSignature()
                                    } else {
                                        return Promise.resolve()
                                    }
                                })
                                .then((res) => {
                                    // 保存数据
                                    if (res) this.curTN.passenger.handWrittenSign = res
                                    if (actionType === 'AutoSave' && this.wfInstance.module === 'shouwen' && this.wfInstance.status === -1) {
                                        return { code: 'success', message: 'auto save success' }
                                    } else {
                                        return this.$wfEngine.saveWfInstance(this, actionType, this.wfParams)
                                    }
                                })
                                .then((res) => {
                                    if (res.code !== 'success') {
                                        return Promise.reject(res)
                                    }
                                    try {
                                        if (window.opener) {
                                            const btnRefresh = window.opener.document.querySelector('.el-icon-refresh')
                                            if (btnRefresh !== null) {
                                                btnRefresh.parentElement.click()
                                            } else {
                                                window.opener.postMessage({ messageId: 'refresh' }, '*')
                                            }
                                        }
                                    } catch (e) {
                                        window.opener.postMessage({ messageId: 'refresh' }, '*')
                                    }
                                    if (actionType === 'AutoSave') {
                                        this.actionType = ''
                                        if (saveCallback !== undefined) {
                                            saveCallback(true)
                                        }
                                    }
                                    this.formEvents.onWflowFormSubmited(this)

                                    this.$bus.emit('closeDoingDialog')

                                    if (actionType.indexOf('Save') === -1) {
                                        // 不等于Save、AutoSave、SaveAndReload
                                        setTimeout(() => {
                                            this.btnCloseClick()
                                        }, 200)
                                    } else if (actionType === 'Save' || actionType === 'SaveAndReload' || actionType === 'SaveAndClaim' || actionType === 'FlowAdminSave') {
                                        this.$message.success(res.message)

                                        if (this.$route.query.busiCode !== undefined) {
                                            const wfParams = { wfEngineUrl: this.wfParams.wfEngineUrl }
                                            wfParams.wfInstanceId = res.body.wfInstance.id
                                            wfParams.wfNodelogId = res.body.curTN.tnID

                                            this.$router.replace({ path: '/wflow', query: wfParams })

                                            actionType = 'SaveAndReload'
                                        }

                                        if (actionType === 'SaveAndReload' || actionType === 'Save' || actionType === 'SaveAndClaim') {
                                            setTimeout(() => {
                                                location.reload()
                                            }, 200)
                                        }
                                    } else if (actionType === 'SaveAndClose') {
                                        setTimeout(() => {
                                            this.btnCloseClick()
                                        }, 200)
                                    }

                                    if (this.signedFileName) {
                                        this.signedFileName = ''
                                        this.btnSubmitClick('Save')
                                    }
                                })
                                .catch((err) => {
                                    if (err.code && err.code === 'cancelSign') {
                                        return this.$bus.emit('closeDoingDialog')
                                    }
                                    this.$bus.emit('closeDoingDialog')
                                    this.$alert(err.message, '操作提示', { type: 'error' })
                                })
                        })
                    })
                },
                (tip, event) => {
                    this.activeName = 'first'
                    if (saveCallback !== undefined) {
                        saveCallback(false)
                    }
                    if (event) {
                        this.$nextTick(() => {
                            const eventPosition = event.getBoundingClientRect()
                            const scrolltop = document.querySelector('.mainform-container .el-scrollbar__wrap').scrollTop
                            document.querySelector('.mainform-container .el-scrollbar__wrap').scrollTop = scrolltop + (eventPosition.top - 102)
                        })
                    }
                }
            )
        },
        btnForwardWflow(type, module, startName) {
            if (type == 'ksqc') {
                module = this.wfEngineUrl
                startName = this.wfInstance.wfName
            }
            if (this.vo.flSubject) {
                const attachArr = JSON.parse(JSON.stringify(this.attachments))
                if (attachArr.length > 0) {
                    attachArr.forEach((item) => {
                        item.forward = true
                        if (item.fileType === 'BODY') {
                            item.fileType = 'ATTACH'
                        }
                    })
                }
                this.vo.passenger.attachArr = attachArr || []
                if (this.bodyfile) {
                    console.log(this.bodyfile)
                    localStorage.setItem('newBodyfile', JSON.stringify(this.bodyfile))
                }
                const gwVo = JSON.stringify(this.vo)
                localStorage.setItem('gwVo', gwVo)
                window.open(`#/wflow?wfEngineUrl=` + module + `&busiCode=` + module.split('/')[1] + `&wfName=` + startName + `&startNodeName=&` + type + `=1&bodyDocId=` + this.wfInstance.bodyDocId + `&haveDoc=` + this.wfInstance.haveDoc)
            } else {
                this.$message.warning('请填写文件标题！')
            }
        },
        btnViewflowlogClick() {
            this.$bus.emit('showWfNodelogList', this.wfInstance)
        },
        btnGuiDangClick() {
            console.log(5666)
        },
        // 发送催办
        btnUrgeFileClick() {
            this.$bus.emit('showUrgeFile', this.wfInstance)
        },
        // 催办记录
        btnViewUrgedlogClick() {
            this.$bus.emit('showViewUrgedlog', this.wfInstance)
        },
        btnModifyFlowLog(modifyType) {
            this.$bus.emit('showModifyWfNodelog', this.wfInstance, this.curTN, modifyType, this.modifyWfNodelogUrl)
        },
        btnStartDoingClick() {
            this.$bus.emit('btnStartDoingClick')
        },
        btnQianshou() {
            this.$bus.emit('btnQianshou', this.vo)
        },
        handleTabsClick(tabName) {
            if (tabName === 'second' || tabName === 'attachment') {
                if (this.canEdit && this.wfInstance.module !== 'shouwen') {
                    this.formValidate(
                        () => {
                            this.activeName = tabName
                        },
                        () => {
                            this.activeName = 'first'
                        }
                    )
                } else {
                    this.activeName = tabName
                }
            } else {
                this.activeName = tabName
            }
            if (!this.flowChartShow) return
            this.$nextTick(() => {
                if (tabName === 'first') {
                    this.$bus.emit('showWfNodelogList', this.wfInstance, 'historyIdea')
                } else if (tabName === 'second' || tabName === 'attachment') {
                    this.$bus.emit('showWfNodelogList', this.wfInstance, 'none')
                } else {
                    this.$bus.emit('showWfNodelogList', this.wfInstance, tabName)
                }
            })
            // if (tabName !== 'first' || (!this.bodyfile || !this.bodyfile.id)) {
            //     this.changePreviewModeToggle(2)
            // } else {
            //     this.changePreviewModeToggle(1)
            // }

            if (this.activeName === 'xqspCon' && this.projectXqName === '会议议题') {
                this.$refs.hytzHyyt.openFn()
            }
        },
        btnSendEmail() {
            var productList = this.$store.getters.productCodeList
            var values = Object.values(productList)
            if (!values.includes('ecyemail')) {
                // 判断是否开通了 内部邮箱 产品
                this.$alert('暂未开通产品，发送失败。', '操作提示', { type: 'warning' })
                return
            }
            let attachIds = ''
            this.attachments.forEach((attach) => {
                if (attach.id) {
                    if (attachIds !== '') attachIds += ','
                    attachIds += attach.id
                }
            })
            const openUrl = '#/sendmail?wfEngineUrl=' + this.wfParams.wfEngineUrl + '&busiCode=' + (this.wfParams.busiCode || this.wfParams.wfEngineUrl.split('/')[1]) + '&srcBusiDataId=' + this.vo.id + '&fileId=' + attachIds
            window.open(openUrl)
        },
        // 关联公文
        associatedDocuments() {
            if (this.wfInstance.status < 0) {
                this.$alert('请先保存表单再进行操作!', '操作提示', { type: 'warning' })
                return
            }
            this.$bus.emit('associatedDocuments', this)
        },
        // 阅文意见
        btnWriteToReadIdea() {
            this.$bus.emit('writeToReadIdea', this)
        },
        // 添加阅文人
        btnAddReader(toReadNodeName, dialogTitle) {
            // toReadNodeName：阅文环节名称，必选，默认为curTN.passenger.toReadedNodeName
            // dialogTitle：添加阅文人对话框名称，可选，默认为“添加阅文人”
            this.$bus.emit('btnAddReader', this, toReadNodeName, dialogTitle)
        },
        // 已阅
        btnReadedClick() {
            this.$bus.emit('btnReadedClick', this)
        },
        btnAddWfNodeUsers() {
            this.$bus.emit('showModifyWflogAddUser', this.wfInstance, null, 'addUser', '添加协办人')
        },
        // 聘期总结
        async employmentSummary(type) {
            this.$bus.emit('employmentSummary', type)
        },
        // 打印聘期总结
        printEmploymentSummary(type) {
            this.$bus.emit('printEmploymentSummary', type)
        },
        changePreviewModeToggle(mode, flang) {
            // if (this.ViewModeToggle) {
            //     this.previewModeToggle = mode
            //     if (mode === 1) {
            //         document.documentElement.style.setProperty('--sidebar-width', 'auto')
            //     } else {
            //         document.documentElement.style.setProperty('--sidebar-width', '350px')
            //     }
            // } else {
            //     this.previewModeToggle = 2
            //     document.documentElement.style.setProperty('--sidebar-width', '350px')
            // }
            if (flang) {
                const num = this.previewModeToggle === 2 ? 1 : 2
                this.previewModeToggle = this.ViewModeToggle ? Number(num) : 2
                document.documentElement.style.setProperty('--sidebar-width', this.previewModeToggle === 1 ? 'auto' : '350px')
                this.commandBar.forEach((item) => {
                    if (item.btnCode === 'changePreviewModeToggle') {
                        item.btnName = this.previewModeToggle === 1 ? '切换单屏' : '切换双屏'
                    }
                })
            } else {
                this.previewModeToggle = this.ViewModeToggle ? Number(mode) : 2
                document.documentElement.style.setProperty('--sidebar-width', this.previewModeToggle === 1 ? 'auto' : '350px')
            }
        },
        // 还原文件
        restoreWfInstance(type) {
            console.log(this, 'this 还原文件')
            this.$confirm('您是否确认还原该文件？', '确认操作', { type: 'warning' }).then(() => {
                this.$http({
                    url: 'workflow/wfTaskItemTrash/restoreWfInstance',
                    method: 'POST',
                    params: {
                        wfInstanceIds: this.wfInstance.id
                    }
                }).then((res) => {
                    if (res.code === 'success') {
                        this.$message.success(res.message)
                        setTimeout(() => {
                            location.reload()
                        }, 200)
                    } else {
                        this.$message.warning(res.message)
                    }
                })
            })
        },
        // 作废文件
        fileInvalidate() {
            if (!this.vo.nullify) {
                // this.$confirm('您是否确认作废该文件？', '确认操作', { type: 'warning' }).then(() => {
                this.$bus.emit('openNullifyRecordsDialog', this)
                // this.$http({
                //     url: this.wfEngineUrl + '/nullifyWfInstanceByBusiDataId',
                //     method: 'POST',
                //     params: {
                //         busiDataIds: this.wfInstance.busiDataId
                //     }
                // }).then((res) => {
                //     if (res.code === 'success') {
                //         this.$message.success(res.message)
                //         setTimeout(() => {
                //             location.reload()
                //         }, 200)
                //     } else {
                //         this.$message.error(res.message)
                //     }
                // })
                // })
            } else {
                this.restoreWfInstance('invalidate')
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '~@/wapp/common/styles/variables.module.scss';
$pageWidth: 1200px;
$fullBox: calc(100% - 40px);
$fullTable: calc(100% - 13%);
/deep/#talForms .el-tabs__nav-wrap {
    display: none;
}
/deep/.el-table--fit {
    border-radius: 0 !important;
}
.wflow-container {
    background-color: #f0f2f8;
    font-weight: 400;
    font-size: $contentFontSize;
    @media (max-width: 1569px) {
        min-width: 1280px;
        .mainform-have-sidebar {
            width: calc(100% - 280px);
        }

        .mainform-previewModeToggle {
            .page-container {
                width: 980px;
                .form-container {
                    width: 860px;
                }
            }
            min-width: 1000px;
            width: calc(100% - var(--sidebar-width));
        }

        .mainform-sidebar {
            width: 280px;
            max-width: var(--sidebar-width);
        }
        .sidebar-previewModeToggle {
            width: calc(100% - 1000px);
        }
        .page-container {
            width: 1000px;
            .form-container {
                width: 800px;
            }
        }
    }
    @media (min-width: 1570px) {
        min-width: 1570px;
        .mainform-have-sidebar {
            width: calc(100% - 350px);
        }
        .mainform-previewModeToggle {
            .page-container {
                width: 1100px;
                .form-container {
                    width: 1000px;
                }
            }
            min-width: 1120px;
            width: calc(100% - var(--sidebar-width));
        }

        .mainform-sidebar {
            width: 350px;
            max-width: var(--sidebar-width);
        }
        .sidebar-previewModeToggle {
            width: calc(100% - 1120px);
        }
        .page-container {
            width: 1200px;
            .form-container {
                width: 1000px;
            }
        }
    }

    @media (min-width: 2555px) {
        min-width: 2560px;

        .mainform-have-sidebar {
            width: calc(100% - 350px);
        }

        .mainform-previewModeToggle {
            min-width: 50%;
            width: 50%;
        }

        .mainform-sidebar {
            width: 350px;
        }

        .sidebar-previewModeToggle {
            width: 50%;
        }

        .page-container {
            width: 1600px;
            .form-container {
                width: 1400px;
            }
        }
    }

    .sidebar-previewModeToggle {
        // .el-scrollbar__thumb
        /deep/ .el-scrollbar__thumb {
            opacity: 0 !important;
        }
    }

    .header-title {
        min-width: 1200px;
        padding: 12px 0;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        justify-content: space-between;
        color: #999;
        font-size: 12px;
        background: #fff;
        .route-info {
            margin-left: 16px;
            display: flex;
            align-items: center;
        }
        .section-title {
            display: flex;
            margin-right: 11px;
            margin-left: 10px;
            font-size: 13px;
            align-items: center;
            position: relative;
            .formTapBtn {
                position: absolute;
                bottom: 0px;
                display: flex;
                left: 50px;
                font-family: MicrosoftYaHei;
                color: #000000;
                font-size: 14px;
                width: 440px;
                .tapItemBtn {
                    position: relative;
                    margin-right: 15px;
                }
                .active {
                    position: relative;
                    color: var(--subjectColor);
                    font-weight: bold;
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -10px;
                        left: 0px;
                        width: 100%;
                        height: 2px;
                        background: var(--subjectColor);
                    }
                }
                .tapItemBtn:hover {
                    cursor: pointer;
                    color: var(--subjectColor);
                }
            }
            .l-text {
                padding-right: 10px;
                position: relative;
                top: -12px;
                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 1px;
                    height: 14px;
                    background-color: #d8d8d8;
                }
            }
            .l1-text {
                padding-right: 10px;
                position: relative;
            }
            .r-text {
                padding-left: 10px;
                position: relative;
                top: -12px;
            }
        }
    }
    /deep/ .el-icon-arrow-up:before {
        color: #333;
    }
    /deep/ .attachments-container {
        .el-table {
            .el-table__body:not(.wflow-attach) {
                tr:first-child {
                    td {
                        background: $label-header-bgcolor;
                        color: #000;
                        .el-button {
                            background: transparent !important;
                        }
                    }
                }
            }
        }
    }
    /deep/ .attachments-container {
        .el-table {
            .el-table__body:not(.wflow-attach) {
                #output_area {
                    tr:first-child {
                        td {
                            background: #fff;
                            color: #000;
                        }
                    }
                }
            }
        }
    }

    .mainform-container {
        overflow-y: hidden;
        overflow-x: hidden;
        margin-top: 0px;
        .el-scrollbar {
            width: 100%;
            height: calc(100vh - 70px);
            /deep/ .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }

        .mainform-have-sidebar {
            display: inline-block;
        }
        .mainform-sidebar {
            display: inline-block;
            vertical-align: top;
            background-color: #fff;
            border-left: solid 1px #f3f3f3;
        }
        .sidebar-attach-container {
            background-color: #fff;
            position: relative;
        }
        .not-have-bottom-idealist {
            min-height: calc(100vh - 90px) !important;
        }
        .page-container {
            min-height: 100%;
            background: #fff;
            position: relative;
            margin: 20px auto;

            .form-container {
                position: relative;
                margin: 0 auto;
                padding-top: 30px;
                padding-bottom: 20px;
                /deep/.el-form .el-table__body .tdlbl .required {
                    position: absolute;
                    left: 6px;
                }
                /deep/.el-form .el-table__body .tdlbl {
                    text-align: left;
                }
                /deep/.el-table__cell.tdlbl {
                    padding: 4px 6px 4px 15px !important;
                }
                .form-header {
                    padding-bottom: 20px;
                    .form-title {
                        font-size: 24px;
                        font-weight: normal;
                        text-align: center;
                        color: #545454;
                    }
                }
                .wflow-busiform {
                    /deep/.el-table {
                        border-radius: 0 !important;
                    }
                }
            }

            /deep/ .el-loading-mask {
                margin-top: 100px;
            }
        }
    }
    /deep/ .richtext-container {
        .el-table.el-table--border {
            border-color: #999;
            &::before,
            &::after {
                background-color: #999;
            }
        }
    }
}
/deep/ .el-form .el-table__body {
    line-height: normal;
}

.wflow-container-top-bar .wflow-container-loaded {
    height: calc(100vh - 70px);
}
.wflow-container-bottom-bar {
    .wflow-container-loaded {
        height: calc(100vh - 70px);
        padding-bottom: 98px;
    }
    /deep/ .bottom-bar {
        @media (max-width: 1569px) {
            width: 980px;
        }
        @media (min-width: 1570px) {
            width: 1200px;
        }
        margin-bottom: 10px;
    }
}
// 打开关联发文时
.open-link-box {
    .mainform-container {
        .el-scrollbar {
            /deep/ .el-scrollbar__wrap {
                .el-scrollbar__view {
                    width: calc(100% - 270px);
                }
            }
        }
    }
    &.wflow-container-bottom-bar {
        /deep/ .bottom-bar {
            margin-right: calc(((100% - 350px - #{$pageWidth}) / 2) + 350px);
        }
    }
}

// 右下工作区域打开时
.wflow-container-right {
    background-color: #fff;
    position: relative;
    height: calc(100vh - 60px - 50px - 10px - 50px);
    overflow-y: hidden;
    overflow-x: hidden;
    .mainform-container {
        padding-bottom: 80px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 0;
    }
    /deep/ .bottom-bar {
        position: absolute;
        width: calc(100% - 5px);
        bottom: 1px;
        margin: 0;
        margin-left: 2px;
    }
}
.wflow-container .el-form .el-table.el-table--border td {
    border-bottom-color: #999 !important;
}
/deep/.sidebar-area-title {
    .el-button--primary {
        width: 3px !important;
    }
}
/deep/.sidebar-area-content {
    .el-radio-group,
    .el-radio {
        width: 100% !important;
    }
}
/deep/.not-have-bottom-idealist {
    min-height: auto !important;
}
.submitSidebar {
    min-height: calc(100vh - 73px);
}

/deep/.el-table__cell .el-collapse {
    border-top: none;
    border-bottom: none;
    min-height: 55px;
}

.wflow-container /deep/ .el-form .el-table.el-table--border td {
    border-bottom-color: #999 !important;
}
.wflow-container /deep/.el-dialog .el-form .el-table.el-table--border td {
    border-bottom-color: #d8d8d8 !important;
}
.wfIdeas-container {
    /deep/.ideaContentDiv {
        .donext-attach {
            .svg-icon {
                margin-top: 5px;
                width: 15px;
                height: 15px;
            }
        }
    }
}
/deep/.wflow-busiform .tableBody .el-table__body td.tdw17{
    padding: 4px 6px 4px 15px;
}
//全屏样式
.full-screen {
    .wflow-container {
        @media (max-width: 1569px) {
            min-width: 1280px;
            .mainform-have-sidebar {
                width: calc(100% - 280px);
            }

            .mainform-container-first .mainform-previewModeToggle {
                .page-container {
                    width: 980px;
                    .form-container {
                        width: 860px;
                    }
                }
                min-width: 1000px;
                width: calc(100% - var(--sidebar-width));
            }

            .mainform-sidebar {
                width: 280px;
                max-width: var(--sidebar-width);
            }
            .mainform-container-first .sidebar-previewModeToggle {
                width: calc(100% - 1000px);
            }
            .page-container {
                width: 1000px;
                .form-container {
                    width: 800px;
                }
            }
        }
        @media (min-width: 1570px) {
            min-width: 1570px;
            .mainform-have-sidebar {
                width: calc(100% - 350px);
            }
            .mainform-container-first .mainform-previewModeToggle {
                .page-container {
                    width: 1100px;
                    .form-container {
                        width: 1000px;
                    }
                }
                min-width: 1120px;
                width: calc(100% - var(--sidebar-width));
            }

            .mainform-sidebar {
                width: 350px;
                max-width: var(--sidebar-width);
            }
            .mainform-container-first .sidebar-previewModeToggle {
                width: calc(100% - 1120px);
                max-width: unset;
            }
            .page-container {
                width: 1200px;
                .form-container {
                    width: 1000px;
                }
            }
        }
        @media (min-width: 2555px) {
            min-width: 2560px;

            .mainform-have-sidebar {
                width: calc(100% - 350px);
            }

            .mainform-container-first .mainform-previewModeToggle {
                min-width: 50%;
                width: 50%;
            }

            .mainform-sidebar {
                width: 350px;
            }

            .mainform-container-first .sidebar-previewModeToggle {
                width: 50%;
            }

            .page-container {
                width: 1600px;
                .form-container {
                    width: 1400px;
                }
            }
        }
        .mainform-container {
            .page-container {
                margin: 20px auto;
                width: $fullBox;
                .form-container {
                    width: $fullTable;
                    /deep/.el-form .el-table__body .tdlbl {
                        text-align: left;
                    }
                    /deep/.el-form .el-table.el-table--border td {
                        position: relative;
                    }
                    /deep/.el-form .el-table__body td.tdw17 {
                        width: 15.6%;
                    }
                    /deep/.el-form .el-table__body td.tdw33 {
                        width: 34.4%;
                    }
                    /deep/.el-form .el-table__body td.tdw83 {
                        width: 84.4%;
                    }
                    /deep/.el-form .el-table__body td.tdw13 {
                        width: 10.4%;
                    }
                    /deep/.el-form .el-table__body td.tdw20 {
                        width: 22.9%;
                    }
                    /deep/.el-form .el-table__body .tdlbl .required {
                        position: absolute;
                        left: 6px;
                    }
                    /deep/.el-table__cell.tdlbl {
                        padding: 4px 6px 4px 15px !important;
                    }
                }
            }
        }
    }
    @media (min-width: 1921px) and (max-width: 3839px) {
        .wflow-busiattach {
            display: flex;
        }
        .mainform-container-first {
            display: flex;
        }
        .wflow-container .mainform-container .page-container {
            // width: calc((100% - 60px) / var(--devicePixelRatio));
            width: calc(100% - 60px);
        }
        .wflow-container .mainform-have-sidebar {
            width: auto;
            flex: 1;
        }
        .wflow-container .mainform-sidebar {
            width: 15%;
            max-width: 15%;
        }
    }
    @media (min-width: 3840px) {
        .mainform-container-first {
            display: flex;
        }
        .wflow-container .mainform-container .page-container {
            width: 3200px;
        }
        .wflow-container .mainform-have-sidebar {
            width: auto;
            flex: 1;
        }
        .wflow-container .mainform-sidebar {
            width: 15%;
            max-width: 580px;
        }
    }
}
</style>
