<template>
    <div style="height: 100%">
        <personOrderCalendar ref="personOrder" :applet-data="appletData" @viewObject="viewObject" @addNewForm="addnew" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import personOrderCalendar from './components/calendar'
const moment = require('moment')
export default {
    components: {
        personOrderCalendar
    },
    props: {
        appletData: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            edata: '', // dayClick的日期
            typeDeatil: 'meet',
            moduleInfoMeetRoom: {
                formTemplate: 'dcontent/busiformurl?vwtype=&url=/hygl/meeting/form.html',
                winWidth: 1000,
                delUrl: 'hygl/meeting/delete',
                addNewUrl: 'hygl/meeting/addNew',
                insertUrl: 'hygl/meeting/insert',
                moduleCode: 'meeting',
                winHeight: 600,
                updateUrl: 'hygl/meeting/update',
                listDataUrl: `hygl/meeting/listdata`,
                getByIdUrl: 'hygl/meeting/getById'
            },
            busiformBtns: [],
            room: {},
            rooms: {}
        }
    },
    computed: {
        ...mapGetters(['loginUser'])
    },
    watch: {},
    created() {
        let formurl = this.moduleInfoMeetRoom.formTemplate.split('url=')[1]
        formurl = formurl.replace(/\//g, '.')
        formurl = '#/dcontent/vuebusiform/W/' + formurl.substring(1).replace('.html', '.vue')
        formurl += '?sysCode=' + (this.$route.meta.sysCode || '')
        this.$options.components['BusiForm'] = this.$loadComponent(formurl)
        this.$bus.on('getRoom', this.getRoom)
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true)
        this.$bus.emit('hiddenAdvSearch', false)
        this.$bus.$off('viewObject')
        this.$bus.off('getRoom', this.getRoom)
    },
    mounted() {
        console.log(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
        this.$bus.$on('viewObject', (resultobj) => {
            const _this = this
            _this.selectRoom(resultobj.meetRoomId)
            _this.typeDeatil = resultobj.typeMeet
            if (resultobj.preordainType === '1') {
                _this.moduleInfoMeetRoom.getByIdUrl = 'hygl/meeting/getByGroupId'
            } else {
                _this.moduleInfoMeetRoom.getByIdUrl = 'hygl/meeting/getById'
            }
            _this.viewFuc(resultobj)
        })
        this.$bus.emit('showNavSearch', false)
        this.$bus.emit('hiddenAdvSearch', true)
    },
    methods: {
        getRoom(room) {
            this.rooms = room
        },
        // 会议室信息查询
        selectRoom(roomID) {
            return new Promise((resolve, reject) => {
                this.$http({
                    method: 'POST',
                    url: 'hygl/meetingRoom/querydata',
                    contentType: 'application/x-www-form-urlencoded',
                    data: [
                        { compare: '=', field: 'id', merge: true, value: roomID },
                        {
                            compare: '=',
                            field: 'orgCode',
                            merge: true,
                            value: this.loginUser.orgCode
                        }
                    ]
                }).then((res) => {
                    if (res.code === 'success') {
                        this.room = res.body.listdata[0]
                        resolve(true)
                    }
                })
            })
        },
        // 表单加载准备
        onBasicFormBeforeOpen($scope, vo, defer) {
            vo.canNumPeople = this.room.numPeople
            if (this.room.weeks !== null) {
                vo.weeks = this.room.weekProperties
            }
            if (vo.week !== null) {
                const type = vo.week.split(',')
                const typeArray = []
                type.forEach((v) => {
                    typeArray.push({
                        text: v,
                        value: v
                    })
                })
                vo.passenger['weekOptions'] = typeArray
            }
            if (vo.serviceProperties !== null) {
                const type = vo.serviceProperties.split(',')
                const typeArray = []
                type.forEach((v) => {
                    typeArray.push({
                        text: v,
                        value: v
                    })
                })
                vo.passenger['servicePropertiesOptions'] = typeArray
            } else {
                if (this.room.serviceProperties) {
                    console.log(3.2, this.room.serviceProperties)
                    const type = this.room.serviceProperties.split(',')
                    const typeArray = []
                    type.forEach((v) => {
                        typeArray.push({
                            text: v,
                            value: v
                        })
                    })
                    vo.passenger['servicePropertiesOptions'] = typeArray
                }
            }
            if (vo.deviceProperties !== null) {
                const type = vo.deviceProperties.split(',')
                const typeArray = []
                type.forEach((v) => {
                    typeArray.push({
                        text: v,
                        value: v
                    })
                })
                vo.passenger['devicePropertiesOptions'] = typeArray
            } else {
                if (this.room.deviceProperties) {
                    const type = this.room.deviceProperties.split(',')
                    const typeArray = []
                    type.forEach((v) => {
                        typeArray.push({
                            text: v,
                            value: v
                        })
                    })
                    vo.passenger['devicePropertiesOptions'] = typeArray
                }
            }
            defer.resolve(true)
        },
        // 新增
        addnew(edata) {
            return false
            // this.edata = edata
            // this.moduleInfoMeetRoom.edata = moment(edata).format('YYYY-MM-DD')
            // this.$nextTick(() => {
            //     this.$bus.emit('openBusiform', this.moduleInfoMeetRoom, null, 1)
            // })
        },
        // 查看
        async viewFuc(vo) {
            this.busiformBtns = [
                {
                    id: 'btn_close',
                    btnName: '关 闭',
                    btnIcon: 'close',
                    btnClick: 'closeDialog',
                    btnPermissio: '',
                    btnType: ''
                }
            ]

            if (vo.typeMeet === 'meet') {
                var url = '/#/wflow?wfEngineUrl=hygl/hyap&busiDataId=' + vo.id
                window.open(url)
            } else {
                this.$bus.emit('openBusiform', this.moduleInfoMeetRoom, vo, 0)
            }
        },
        /**
         * 加载数据准备，qparams为查询参数对象，不需要返回值
         */
        onListDataBeforeLoad: function ($scope, qparams) {
            qparams.urlsearch = `orgCode:${this.loginUser.orgCode}`
            console.log('vwMyMeeting listdata before load..')
        },

        // 删除
        deleteIds(id) {},
        // 表单打开
        onBasicFormOpened($scope, vo) {
            console.log('99999')
        },
        // 表单提交事件，vo为待提交的对象，校验成功：defer.resolve(true)，校验失败：defer.reject(reason)
        onBasicFormBeforeSubmit($scope, vo, defer) {
            vo.weekProperties = vo.weeks
            console.log('提交', vo)
            if (vo.approve !== 0) {
                vo.status = 1
            } else {
                vo.status = 2
            }
            if (vo.preordainType === '1' && vo.value2 && vo.value1) {
                vo.beginTime = vo.value2[0] + ' ' + vo.value1[0]
                vo.endTime = vo.value2[1] + ' ' + vo.value1[1]
            } else {
                //
            }
            // if (vo.preordainType === '0') {
            var stTime = moment(vo.beginTime).format('YYYY-MM-DD HH:mm')
            var stTime2 = moment(vo.beginTime).format('YYYY-MM-DD') + ' ' + this.rooms.beginTime.substr(11, 5)
            var enTime = moment(vo.endTime).format('YYYY-MM-DD HH:mm')
            var enTime2 = moment(vo.endTime).format('YYYY-MM-DD') + ' ' + this.rooms.endTime.substr(11, 5)
            stTime = new Date(stTime).getTime()
            stTime2 = new Date(stTime2).getTime()
            enTime = new Date(enTime).getTime()
            enTime2 = new Date(enTime2).getTime()

            const weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
            const startWeekDay = weeks[new Date(vo.beginTime).getDay()]
            const endWeekDay = weeks[new Date(vo.endTime).getDay()]
            if (vo.preordainType === '0') {
                if (this.rooms.weekProperties.indexOf(startWeekDay) === -1 || this.rooms.weekProperties.indexOf(endWeekDay) === -1) {
                    this.$alert(this.$tx('hygl.message.meetingOpenIn2', { day: this.rooms.weekProperties }, `会议室开放周期为‘${this.rooms.weekProperties}’！请在开放周期内预定`), this.$tx('common.operationTip', '操作提示'), { type: 'warning' })
                    defer.reject()
                    return
                }
            }

            if (stTime < stTime2 || enTime > enTime2 || stTime > enTime) {
                this.$alert(this.$tx('hygl.message.meetingOpenIn3', { day: this.rooms.beginTime.substr(11, 5) + ' - ' + this.rooms.endTime.substr(11, 5) }, `会议室开放时间为${this.rooms.beginTime.substr(11, 5) + ' - ' + this.rooms.endTime.substr(11, 5)}`), this.$tx('common.operationTip', '操作提示'), { type: 'warning' })
                defer.reject()
                return
            }
            // }

            // if (new Date(vo.beginTime).getTime() < new Date(moment(new Date()).format('YYYY-MM-DD HH:mm')).getTime()) {
            //     this.$alert('会议室开始时间不能早于当前时间',this.$tx('common.operationTip','操作提示'), { type: 'warning' })
            //     defer.reject()
            //     return
            // }
            if (vo.beginTime > vo.endTime) {
                this.$alert(this.$tx('hygl.message.meetingBegin', '结束时间不能早于开始时间'), this.$tx('common.operationTip', '操作提示'), { type: 'warning' })
                defer.reject()
                return
            }
            // if (!vo.numPeople) {
            //     this.$message.error('参会人数是必须的！')
            //     defer.reject()
            // }
            if (vo.numPeople > vo.canNumPeople) {
                this.$message.error(this.$tx('hygl.message.meetingNumExceed', '不能超过会议室可容纳人数'))
                defer.reject()
                return
            }
            defer.resolve(true)
        },
        // 表单提交完成
        onBasicFormSubmited($scope, vo) {
            this.$refs.personOrder.queryDataHy()
        }
    }
}
</script>

<style scoped lang="scss">
</style>

