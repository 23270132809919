const moment = require('moment')
import Vue from 'vue'

/**
 * Parse the String|Number to Date
 * @param {(Object|string|number)} time
 * @returns {Date}
 */
export function parseTime(time) {
    if (arguments.length === 0) {
        return null
    }

    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if (typeof time === 'string') {
            if (/^[0-9]+$/.test(time)) {
                time = parseInt(time)
            } else {
                time = time.replace(/-/g, '/')
            }
        }
        if (typeof time === 'number' && time.toString().length === 10) {
            time = time * 1000
        }
        date = new Date(time)
    }

    return date
}

/**
 * @param {(Object|string|number)} time
 * @param {string} format 日程格式化字符串，无此参数时，根据time和当前时间差返回"刚刚、x分钟、x小时、1天前"等时间描述；指定此参数时，返回moment(d).format(format)字符串
 * @returns {string}
 */
export function formatTime(time, format) {
    let d = parseTime(time)
    if (time === null) {
        d = Date().now()
    }
    const now = Date.now()
    const diff = (now - d) / 1000

    if (format === undefined) {
        if (diff < 30) {
            return '刚刚'
        } else if (diff < 3600) {
            // less 1 hour
            return Math.floor(diff / 60) + '分钟前'
        } else if (diff < 3600 * 24) {
            return Math.floor(diff / 3600) + '小时前'
        } else if (diff < 3600 * 24 * 2) {
            return '1天前'
        } else if (d.getFullYear() < parseTime(now).getFullYear()) {
            return d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate()
        } else {
            return d.getMonth() + 1 + '/' + d.getDate()
        }
    } else {
        format = format.replace('yyyy', 'YYYY').replace('dd', 'DD')
        return moment(d).format(format)
    }
}

/**
 * @param {string} url 包含?param1=value1&param2=value2格式字符串
 * @returns {Object}
 */
export function getQueryObject(url) {
    url = url == null ? window.location.href : url
    const search = url.substring(url.lastIndexOf('?') + 1)
    const obj = {}
    const reg = /([^?&=]+)=([^?&=]*)/g
    search.replace(reg, (rs, $1, $2) => {
        const name = decodeURIComponent($1)
        let val = decodeURIComponent($2)
        val = String(val)
        obj[name] = val
        return rs
    })
    return obj
}

/**
 * @param {string} str
 * @returns {number} str字节长度，英文、数字1个字节，汉字2个字节
 */
export function byteLength(str) {
    // returns the byte length of an utf8 string
    let s = str.length

    for (var i = str.length - 1; i >= 0; i--) {
        const code = str.charCodeAt(i)
        if (code > 0x7f && code <= 0x7ff) s++
        else if (code > 0x7ff && code <= 0xffff) s += 1
        if (code >= 0xdc00 && code <= 0xdfff) i--
    }
    return s
}

/**
 * @param {Array} actual
 * @returns {Array} 清除无效元素后的新数组
 */
export function cleanArray(actual) {
    const newArray = []
    for (let i = 0; i < actual.length; i++) {
        if (actual[i]) {
            newArray.push(actual[i])
        }
    }
    return newArray
}

/**
 * @param {Object} json
 * @returns {string} param1=value1&param2=value2格式字符串
 */
export function param(json) {
    if (!json) return ''
    return cleanArray(
        Object.keys(json).map((key) => {
            if (json[key] === undefined) return ''
            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
        })
    ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
    const search = url.split('?')[1]
    if (!search) {
        return {}
    }
    return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}')
}

/**
 * @param {string} val html字符串
 * @returns {string} 清除html标签后的纯文本
 */
export function html2Text(val) {
    const div = document.createElement('div')
    div.innerHTML = val
    return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
    if (typeof target !== 'object') {
        target = {}
    }
    if (Array.isArray(source)) {
        return source.slice()
    }
    Object.keys(source).forEach((property) => {
        const sourceProperty = source[property]
        if (typeof sourceProperty === 'object') {
            target[property] = objectMerge(target[property], sourceProperty)
        } else {
            target[property] = sourceProperty
        }
    })
    return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
    if (!element || !className) {
        return
    }
    let classString = element.className
    const nameIndex = classString.indexOf(className)
    if (nameIndex === -1) {
        classString += '' + className
    } else {
        classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length)
    }
    element.className = classString
}

/**
 * @param {string} type 可选，
 * @returns {Date} 默认返回当前时间，type值为'start’时当前时长向前90天
 */
export function getTime(type) {
    if (type === 'start') {
        return new Date().getTime() - 3600 * 1000 * 24 * 90
    } else {
        return new Date(new Date().toDateString())
    }
}

/**
 * 短时间内不重复触发一个事件
 * @param {Function} func 防抖动函数
 * @param {number} wait 设置一个门槛值，表示两次执行函数的最小间隔时间。
 * @param {boolean} immediate 是否立即执行
 * @return {*}
 */
export function debounce(func, wait, immediate) {
    let timeout, args, context, timestamp, result

    const later = function () {
        // 据上一次触发时间间隔
        const last = +new Date() - timestamp

        // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last)
        } else {
            timeout = null
            // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
            if (!immediate) {
                result = func.apply(context, args)
                if (!timeout) context = args = null
            }
        }
    }

    return function () {
        context = this
        args = arguments
        timestamp = +new Date()
        const callNow = immediate && !timeout
        // 如果延时不存在，重新设定延时
        if (!timeout) timeout = setTimeout(later, wait)
        if (callNow) {
            result = func.apply(context, args)
            context = args = null
        }

        return result
    }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
    if (!source && typeof source !== 'object') {
        throw new Error('error arguments', 'deepClone')
    }
    const targetObj = source.constructor === Array ? [] : {}
    Object.keys(source).forEach((keys) => {
        if (source[keys] && typeof source[keys] === 'object') {
            targetObj[keys] = deepClone(source[keys])
        } else {
            targetObj[keys] = source[keys]
        }
    })
    return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array} 去重后的新数组，仅支持es6
 */
export function uniqueArr(arr) {
    return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
    const timestamp = +new Date() + ''
    const randomNum = parseInt((1 + Math.random()) * 65536) + ''
    return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
    return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
    if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
    if (hasClass(ele, cls)) {
        const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
        ele.className = ele.className.replace(reg, ' ')
    }
}

/**
 * 格式化文件尺寸大小
 * @param {number} fileSize
 * @returns {string} 单位为B、KB、MB、GB的字符串
 */
export function formatFileSize(fileSize) {
    if (fileSize < 1024) {
        return fileSize + 'B'
    } else if (fileSize < 1024 * 1024) {
        let temp = fileSize / 1024
        temp = temp.toFixed(2)
        return temp + 'KB'
    } else if (fileSize < 1024 * 1024 * 1024) {
        let temp = fileSize / (1024 * 1024)
        temp = temp.toFixed(2)
        return temp + 'MB'
    } else {
        let temp = fileSize / (1024 * 1024 * 1024)
        temp = temp.toFixed(2)
        return temp + 'GB'
    }
}

/**
 * @param {string} path
 * @returns {Boolean}   paht中包括http:、https:、mailto:、tel:返回true
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 触发表单元素输入校验事件
 * @param {parent} 包含输入元素的父级组件
 * @returns 无
 */
export function formItemValidate(parent) {
    if (parent.$children) {
        parent.$children.forEach((item) => {
            if (item.validate && item.rules !== undefined) {
                if (item.$el.querySelector('.is-checked') === null) {
                    item.validate().then((rst) => {
                        const tooltip = item.$children[0]
                        if (tooltip !== undefined) {
                            if (hasClass(tooltip.$el, 'el-tooltip')) {
                                if (!rst.valid) {
                                    tooltip.disabled = false
                                    tooltip.content = rst.errors[0]
                                    tooltip.$el.errorContent = rst.errors[0]
                                    addClass(tooltip.$el, 'invalid-field')
                                } else {
                                    tooltip.disabled = true
                                    removeClass(tooltip.$el, 'invalid-field')
                                }
                            } else if (hasClass(tooltip.$el, 'van-field')) {
                                const errorInput = tooltip.$el.querySelector('.van-field__control')
                                if (!rst.valid) {
                                    tooltip.error = true
                                    if (errorInput !== null) {
                                        errorInput.setAttribute('error-content', rst.errors[0])
                                    }
                                } else {
                                    tooltip.error = false
                                    if (errorInput !== null) {
                                        errorInput.removeAttribute('error-content')
                                    }
                                }
                            }
                        }
                    })
                }
            } else if (item.$children.length > 0) {
                formItemValidate(item)
            }
        })
    }
}

/**
 * 获取浏览器版本
 * @returns {string} 浏览器版本
 */
export function getExplore() {
    const Sys = {}
    const ua = navigator.userAgent.toLowerCase()
    if (ua.match(/rv:([\d.]+)\) like gecko/)) {
        Sys.ie = ua.match(/rv:([\d.]+)\) like gecko/)[1]
    } else if (ua.match(/msie ([\d\.]+)/)) {
        Sys.ie = ua.match(/msie ([\d\.]+)/)[1]
    } else if (ua.match(/edge\/([\d\.]+)/)) {
        Sys.edge = ua.match(/edge\/([\d\.]+)/)[1]
    } else if (ua.match(/firefox\/([\d\.]+)/)) {
        Sys.firefox = ua.match(/firefox\/([\d\.]+)/)[1]
    } else if (ua.match(/(?:opera|opr).([\d\.]+)/)) {
        Sys.opera = ua.match(/(?:opera|opr).([\d\.]+)/)[1]
    } else if (ua.match(/chrome\/([\d\.]+)/)) {
        Sys.chrome = ua.match(/chrome\/([\d\.]+)/)[1]
    } else if (ua.match(/version\/([\d\.]+).*safari/)) {
        Sys.safari = ua.match(/version\/([\d\.]+).*safari/)[1]
    }

    // 根据关系进行判断
    if (Sys.ie) return 'IE: ' + Sys.ie
    if (Sys.edge) return 'EDGE: ' + Sys.edge
    if (Sys.firefox) return 'Firefox: ' + Sys.firefox
    if (Sys.chrome) return 'Chrome: ' + Sys.chrome
    if (Sys.opera) return 'Opera: ' + Sys.opera
    if (Sys.safari) return 'Safari: ' + Sys.safari
    return 'Unkonwn'
}

/**
 * 对象数组排序
 * @param {Array} array 对象数组
 * @param {string} key 排序属性名称
 * @param {string} 排序方式，asc或desc，默认为asc
 * @returns {Array} 排序后的数组
 */
export function sortByKey(array, key, mode) {
    return array.sort(function (a, b) {
        const x = a[key]
        const y = b[key]
        if (mode === 'desc') {
            return x > y ? -1 : x < y ? 1 : 0
        } else {
            return x < y ? -1 : x > y ? 1 : 0
        }
    })
}

/**
 * 生成uuid
 */
export function uuid() {
    const r = []
    const e = '0123456789abcdef'
    for (let t = 0; t < 36; t++) {
        r[t] = e.substr(Math.floor(16 * Math.random()), 1)
    }
    r[14] = '4'
    r[19] = e.substr((3 & r[19]) | 8, 1)
    r[8] = r[13] = r[18] = r[23] = ''
    return r.join('')
}

/**
 * 加密（需要先加载lib/aes/aes.min.js文件）
 * @param word
 * @returns {*}
 */
export function aesEncrypt(word) {
    var key = window.CryptoJS.enc.Utf8.parse('abcdefgabcdefg12')
    var srcs = window.CryptoJS.enc.Utf8.parse(word)
    var encrypted = window.CryptoJS.AES.encrypt(srcs, key, { mode: window.CryptoJS.mode.ECB, padding: window.CryptoJS.pad.Pkcs7 })
    return encrypted.toString()
}

/**
 * 解密
 * @param word
 * @returns {*}
 */
export function aesDecrypt(word) {
    var key = window.CryptoJS.enc.Utf8.parse('abcdefgabcdefg12')
    var decrypt = window.CryptoJS.AES.decrypt(word, key, { mode: window.CryptoJS.mode.ECB, padding: window.CryptoJS.pad.Pkcs7 })
    return window.CryptoJS.enc.Utf8.stringify(decrypt).toString()
}

/**
 *
 */
export function determineOS() {
    const userAgent = window.navigator.userAgent
    if (/windows|win32/i.test(userAgent)) {
        return 'Windows'
    } else if (/linux/i.test(userAgent)) {
        return 'Linux'
    } else {
        return '其他操作系统' // 例如：macOS、iOS、Android 等
    }
}

//替换标题中的特殊符号，使用可以做为操作系统文件名
export function getSaveAsFileName(title) {
    var reg = /\//g
    title = title.replace(reg, '')

    reg = /\\/g
    title = title.replace(reg, '')

    reg = / /g
    title = title.replace(reg, '')

    reg = /\*/g
    title = title.replace(reg, '')

    reg = /\:/g
    title = title.replace(reg, '：')

    reg = /\</g
    title = title.replace(reg, '〈')

    reg = /\>/g
    title = title.replace(reg, '〉')

    reg = /\?/g
    title = title.replace(reg, '？')

    reg = /\,/g
    title = title.replace(reg, '，')

    reg = /\'/g
    title = title.replace(reg, '‘')

    reg = /\"/g
    title = title.replace(reg, '“')
    var maxLength = 300
    var endLength = 2
    if (title.replace(/[^\x00-\xff]/g, 'aa').length > maxLength) {
        var endStr = title.substring(title.length - endLength)
        do {
            title = title.substring(0, title.length - 10) + '..' + endStr
        } while (title.replace(/[^\x00-\xff]/g, 'aa').length > maxLength)
    }
    return title
}

// url:下载地址
Vue.prototype.fileDownload = (url) => {
    const a = document.createElement('a')
    a.href = url
    a.style.display = 'none'
    a.download = ''// 设置下载文件的名称(可选)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

