<template>
    <el-container>
        <el-main style="padding: 9px">
            <div class="calendar-container">
                <div id="calendar" class="metting-calendar" />
            </div>
        </el-main>
    </el-container>
</template>

<script>
import $ from 'jquery'
require('fullcalendar')
if (!window.jQuery) {
    window.jQuery = $
}

const moment = require('moment')

export default {
    components: {},
    props: {
        appletData: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            calendar: null,
            typeDeatil: '',

            hyArr: [],
            options: {
                locale: this.$i18n.locale === 'zhCN' ? 'zh-cn' : 'en-nz',
                header: {
                    right: 'prev,title,next,today',
                    center: '',
                    left: ''
                },
                buttonText: {
                    // 头部按钮的显示文本信息
                    today: this.$tx('hygl.components.toMonth', '本月'),
                    month: this.$tx('hygl.components.toMonth', '本月')
                },
                eventColor: '#409eff',
                eventTextColor: '#fff',
                allDayText: '', // 日历上方显示全天信息的文本
                firstDay: 1,
                defaultView: 'month',
                timeFormat: 'HH:mm',
                titleFormat: 'YYYY-MM',
                navLinks: false, // 设置日期是否可点
                editable: false,
                eventLimit: true,
                views: {
                    day: {
                        eventLimit: 5
                    }
                },
                eventLimitText: '显示更多会议',
                lazyFetching: false,
                droppable: true,
                slotLabelFormat: 'HH',
                slotEventOverlap: false,
                fixedWeekCount: false, // 是否固定日历高度
                schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
                events: [], // 个人日程
                // eventLimitClick: (cellInfo, jsEvent) => {
                //     cellInfo.segs = cellInfo.hiddenSegs
                //     console.log(cellInfo, jsEvent)
                // },
                dayClick: (event, jsEvent, view) => {
                    // const edata = moment(event).format('YYYY-MM-DD') + ' 00:00:00'
                    // this.addNew(edata)
                },
                eventClick: (event) => {
                    // this.view(event.id)
                    this.typeDeatil = event.typeMeet
                    this.$bus.emit('viewObject', event)
                },

                eventMouseover: (event, ev, view) => {
                    console.log('event', event)

                    // var delbtn_html = '<a class = "event_del btn btn-xs"></a>'
                    // var $targetEle = $(ev.currentTarget).children('.fc-content')
                    var $targetEle = $(ev.currentTarget)
                    // $targetEle.append(delbtn_html)
                    $('.event_del').click((e) => {
                        e.stopPropagation()
                        this.deleteIds(event.id)
                    })
                    let widthLeft = ''
                    if ($targetEle[0].offsetParent.className === 'fc-event-container') {
                        widthLeft = Number($targetEle[0].offsetParent.offsetLeft) + 100
                    } else {
                        widthLeft = '100'
                    }
                    // className, fc-event-container
                    // 事件提示
                    if (view.name !== 'listWeek') {
                        const fcWidth = document.querySelector('.fc-view').offsetWidth
                        if (widthLeft > fcWidth - 280) {
                            console.log('dayu')
                            widthLeft = widthLeft - 280
                        }
                        $(ev.currentTarget).append(this.tipTem)
                        $('#event-detail').css({ opacity: '100', width: '220px', top: '0px', left: '' + widthLeft + 'px', height: '130px' })
                        const eventTitle = event.title
                        const eventStart = moment(event.start).format('YYYY-MM-DD HH:mm')
                        const eventEnd = moment(event.end).format('YYYY-MM-DD HH:mm')
                        // const eventEnd = event.end
                        const eventCreatePerson = event.createPerson
                        const eventPlace = event.place
                        const _this = this
                        $('.tip-title').text(eventTitle)
                        $('.tip-start').text(_this.$tx('hygl.components.startTime', '开始时间') + '：' + eventStart)
                        $('.tip-end').text(_this.$tx('hygl.components.endTime', '结束时间') + '：' + eventEnd)
                        $('.tip-createPerson').text(_this.$tx('hygl.hyyt.appointedBy', '预约人') + '：' + eventCreatePerson)
                        $('.tip-place').text(_this.$tx('hygl.components.fields.meetingName', '会议室名称') + '：' + eventPlace)
                        $('#event-detail').click((e) => {
                            e.stopPropagation()
                        })
                    }
                },
                eventMouseout: (event, ev) => {
                    $('.event_del').remove()
                    $('#event-detail').css('opacity', 0).remove()
                }
            },
            vo: {},
            tipTem: `
                  <div id="event-detail">
                      <div class="detail-list">
                          <div class="tip-title" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" />
                          <div class="tip-start" />
                          <div class="tip-end" />
                          <div class="tip-createPerson" />
                          <div class="tip-place" />
                      </div>
                  </div>
              `,
            start: '', // 日历开始时间
            end: '' // 日历结束时间
        }
    },
    computed: {
        curUser() {
            return this.$store.getters.loginUser
        }
    },
    watch: {},
    created() {
        this.$bus.emit('showNavSearch', false) // 取消右上搜索按钮
    },
    beforeDestroy() {
        this.$bus.emit('showNavSearch', true) // 还原右上搜索按钮
        $(document).off('click', '.fc-toolbar .fc-button') // 卸载按钮绑定事件
        this.calendar = null
    },
    mounted() {
        this.$nextTick(() => {
            // debugger
            this.renderCalendar()
            $('.fc-right').on('click', '.fc-prev-button', () => {
                this.initRoomData()
            })
            $('.fc-right').on('click', '.fc-next-button', () => {
                this.initRoomData()
            })
            $('.fc-right').on('click', '.fc-today-button', () => {
                this.initRoomData()
            })
            // $('.fc-right').on('click', '.fc-day-number', () => {
            //     return
            // })
            const _this = this
            $(document).click(function (event) {
                if (event.target.classList[0] === 'fc-today-button') {
                    _this.initRoomData()
                }
            })
        })
    },
    methods: {
        renderCalendar() {
            // 渲染日历
            if (this.calendar) {
                $('#calendar').fullCalendar('destroy')
            }
            this.calendar = $('#calendar').fullCalendar(this.options)
            this.initRoomData()
        },
        renderEvent(events) {
            // 重新渲染事件
            $('#calendar').fullCalendar('removeEvents') // 删除原有事件
            $('#calendar').fullCalendar('addEventSource', events) // 添加一个日程事件源
            this.$nextTick(() => {
                $(`.fc-basic-view .fc-body .fc-row`).css('max-height', '160px')
            })
        },
        getviews() {
            // 取得视图对象信息
            return $('#calendar').fullCalendar('getView')
        },
        async initRoomData(views) {
            if (this.appletData.interfaceStatus === 0 || window.openInterface) {
                if (this.appletData.name === '会议日程') {
                    return
                }
            }
            const view = this.getviews()
            this.start = view.start
            this.end = view.end
            this.$http({
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: [
                    {
                        compare: '<=',
                        field: 'beginTime',
                        merge: false,
                        value: moment(this.end).format('YYYY-MM-DD HH:mm:ss')
                    },
                    {
                        compare: '>=',
                        field: 'endTime',
                        merge: false,
                        value: moment(this.start).format('YYYY-MM-DD HH:mm:ss')
                    },
                    {
                        compare: 'in',
                        field: 'status',
                        merge: false,
                        value: '1,3'
                    },
                    {
                        compare: 'like',
                        field: 'attendUserId',
                        merge: false,
                        value: `%${this.$store.getters.loginUser.personId}%`
                    },
                    {
                        compare: '=',
                        field: 'orgCode',
                        merge: true,
                        value: this.$store.getters.loginUser.orgCode
                    }
                ],
                url: '/hygl/meeting/querydata'
            }).then((res) => {
                if (res.code === 'success') {
                    const result = res.body.listdata
                    const events = []
                    $.each(result, (index, item) => {
                        events.push({
                            id: item.preordainType === '1' ? item.groupId : item.id,
                            title: item.meetName,
                            start: item.beginTime.split(' ')[0] + ' ' + item.beginTime.split(' ')[1],
                            end: item.endTime.split(' ')[0] + ' ' + item.endTime.split(' ')[1],
                            createPerson: item.applayName,
                            place: item.meetName,
                            color: new Date(item.beginTime).getTime() > new Date().getTime() ? '#409EFF' : '#f2f2f2',
                            textColor: new Date(item.beginTime).getTime() > new Date().getTime() ? '#fff' : '#333',
                            block: true,
                            typeMeet: 'meetRoom',
                            preordainType: item.preordainType,
                            meetRoomId: item.meetRoomId
                        })
                    })

                    this.queryDataHy().then((val) => {
                        this.options.events = events.concat(val)
                        // 重新渲染事件
                        this.renderEvent(this.options.events)
                    })
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        queryDataHy(views) {
            // 查询个人某段范围内的日程
            const view = this.getviews()
            this.start = view.start
            this.end = view.end
            return new Promise((resolve, reject) => {
                this.$http({
                    method: 'POST',
                    contentType: 'application/x-www-form-urlencoded',
                    data: [
                        {
                            compare: '<=',
                            field: 'startTime',
                            merge: false,
                            value: moment(this.end).format('YYYY-MM-DD HH:mm:ss')
                        },
                        {
                            compare: '>=',
                            field: 'endTime',
                            merge: false,
                            value: moment(this.start).format('YYYY-MM-DD HH:mm:ss')
                        },
                        {
                            compare: 'in',
                            field: 'status',
                            merge: false,
                            value: '1,2'
                            // 'value': '2'
                        },
                        {
                            compare: '=',
                            field: 'personId',
                            merge: false,
                            value: this.$store.getters.loginUser.personId
                        },
                        {
                            compare: '=',
                            field: 'orgCode',
                            merge: true,
                            value: this.$store.getters.loginUser.orgCode
                        },
                        {
                            compare: '!=',
                            field: 'meetPersonType',
                            merge: true,
                            value: 4
                        }
                    ],
                    url: '/hygl/vwMyMeeting/querydata'
                }).then((response) => {
                    if (response.code === 'success') {
                        const result = response.body.listdata
                        const events = []
                        $.each(result, (index, item) => {
                            events.push({
                                id: item.id,
                                title: item.flSubject,
                                // start: item.startTime.split(' ')[0] + ' ' + item.startTime.split(' ')[1],
                                // end: item.endTime.split(' ')[0] + ' ' + item.endTime.split(' ')[1],
                                start: item.startTime,
                                end: item.endTime,
                                // end: '2024-08-16 00:30:00',
                                createPerson: item.flCreator,
                                place: item.meetPlace,
                                color: item.meetStatus === 0 ? '#409EFF' : item.meetStatus === 1 ? '#409EFF' : '#f2f2f2',
                                textColor: item.meetStatus === 0 ? '#fff' : item.meetStatus === 1 ? '#fff' : '#333',
                                block: true,
                                typeMeet: 'meet'
                            })
                        })
                        console.log('events', events)

                        return resolve(events)
                    } else {
                        reject(false)
                    }
                })
                // return promise
            })
        },
        // 新增
        addNew(edata) {
            this.$emit('addNewForm', edata)
        },

        // 删除
        deleteIds(id) {
            this.$emit('deleteIds', id)
        }
    }
}
</script>
  <style lang="scss" scoped>
/deep/#event-detail {
    width: 200px;
    height: 100px;
}

/deep/.metting-calendar .fc-event {
    padding: 0 10px;
    margin-top: 3px;
}
/deep/.metting-calendar .fc-h-event,
.metting-calendar .fc-event.fc-short {
    position: inherit;
}
// /deep/.calendar .fc-h-event, .calendar .fc-event.fc-short{
// position: relative;
// }
/deep/.metting-calendar .fc-event-container a {
    line-height: 32px;
    // color: #333 !important;
}
/deep/.fc-right {
    h2 {
        font-size: 16px !important;
        margin: 0 10px;
        display: inline;
        color: #333 !important;
    }
    button {
        display: inline;
        color: #303133;
        outline: none;
        border: none;
        background-color: transparent;
        background-image: none;
        box-shadow: none !important;
        &:last-child {
            color: var(--bgColor);
        }
        &:focus,
        &:hover {
            color: var(--bgColor);
        }
    }
}
/deep/.fc-icon-left-single-arrow:after {
    font-size: 150%;
    font-weight: 300;
    top: -37%;
}
/deep/.fc-icon-right-single-arrow:after {
    font-size: 150%;
    font-weight: 300;
    top: -37%;
}
/deep/.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
/deep/.fc-unthemed .fc-popover .fc-header {
    font-size: 16px !important;
    line-height: 24px !important;
    background-color: #f2f2f2 !important;
}
/deep/.fc-popover.fc-more-popover .fc-h-event,
/deep/.fc-popover.fc-more-popover .fc-event.fc-short {
    // background-color: #fff !important;
    border-color: #fff !important;
    border-bottom: 1px solid #f2f2f2;
}
/deep/.fc-basic-view .fc-body .fc-row {
    height: 200px !important;
}
/deep/.fc-toolbar.fc-header-toolbar {
    margin: 10px !important;
}
/deep/.fc-unthemed .fc-popover {
    width: 300px !important;
}
/deep/.metting-calendar table tr th.fc-day-header {
    height: 34px !important;
    line-height: 34px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    border-right-color: #d8d8d8;
    background-color: #f2f5fb !important;
}
/deep/.metting-calendar table tr th.fc-day-header {
    color: #666666 !important;
}
/deep/ td {
    border: 1px solid #dee2e6;
}

/deep/.fc-scroller.fc-day-grid-container {
    height: auto !important;
}
/deep/.fc-state-down,
.fc-state-hover {
    border: none;
    list-style: none;
    outline: none;
    box-shadow: none;
}
/deep/.fc-content {
    text-overflow: ellipsis;
}
</style>
  <style lang="scss">
@import '~@/wapp/common/styles/element-variables.scss';
#event-detail {
    opacity: 0;
    transition: opacity 0.4s;
    position: absolute;
    cursor: auto;
    top: 28px;
    right: -2px;
    background: #fff;
    padding: 10px 16px;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    font-size: 12px;
    line-height: 2;
    min-width: 158px;
    word-wrap: break-word;
    z-index: 99;
    color: #333;
    box-shadow: 5px 5px 5px #909399;
    .arrow {
        border: 7px solid transparent;
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-bottom-color: #ebeef5;
        right: 20px;
        top: -14px;
    }
    .arrow:after {
        border: 6px solid transparent;
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-bottom-color: #fff;
        left: -5px;
        top: -6px;
        content: '';
    }
    .detail-list {
        margin-bottom: 10px;
    }
    .tip-title {
        font-size: 14px;
        font-weight: bold;
    }
}

.fc-state-default.fc-corner-left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.fc-state-default.fc-corner-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.calendar-container .metting-calendar table tr td {
    padding: 0;
}
.fc-scroller.fc-time-grid-container {
    height: auto !important;
}

.fc {
    .fc-toolbar{
        display: inline !important;
    }
    .fc-axis {
        padding: 0 5px !important;
        background: #f8f8f8;
    }
    .fc-content-skeleton .fc-axis {
        background: transparent;
    }
    .fc-button-group > * {
        background: transparent;
        padding: 9px 15px;
        font-size: 12px;
        line-height: 12px;
        color: #606266;
        height: 33px;
        border-color: rgb(220, 223, 230);
        box-shadow: none;
        outline: none;
    }
    .fc-button-group > *:hover,
    .fc-button-group .fc-state-active {
        /* background: $--color-primary; */
        box-shadow: none;
        color: $--color-primary;
        border-color: $--color-primary;
    }
    .fc-button-group .fc-state-disabled:hover {
        color: #606266;
        border-color: rgb(220, 223, 230);
    }
    .fc-list-table {
        tr td {
            padding: 8px 14px !important;
            border-left: 0;
            border-right: 0;
        }
        .fc-list-heading td {
            background: #f8f8f8;
            color: $--color-primary;
            font-size: 14px;
            font-weight: normal;
        }
    }
}
.metting-calendar.fc-unthemed td.fc-today {
    color: var(--bgColor) !important;
    background: #f2f8fe;
}
.metting-calendar {
    width: 100%;
    color: #606266;

    .fc-toolbar h2 {
        font-size: 22px;
        color: $--color-primary;
        font-weight: normal;
    }
    table tr th.fc-day-header {
        color: #666;
        height: 34px;
        line-height: 34px;
        background-color: #f8f8f8;
    }
    .fc-event-container {
        padding: 0;
    }
    .fc-event-dot {
        background-color: $--color-primary !important;
    }
    .fc-event {
        font-size: 14px;
        line-height: 1.6;
        min-height: 40px;
        border-radius: 0;
        padding: 4px 10px;
        overflow: visible;
        .event_del {
            position: absolute;
            right: 2px;
            top: 0;
        }
    }
    .fc-h-event,
    .fc-event.fc-short {
        min-height: 20px;
    }
    .fc-day-grid-event .fc-time {
        font-weight: normal;
        margin-right: 8px;
    }
    /* .nonwork{
          position: relative;
      }
      .fc-other-month.nonwork{
          opacity: 0.6;
      }
      .nonwork a{
          color: #f73939;
      }
      td.nonwork:before{
          content: "休";
          position: absolute;
          left: 8px;
          top: 3px;
          font-size: 12px;
          color: #ff0000;
      } */
}
</style>

