<template>
    <div class="header-container" :class="{ 'hideTopMenus-header-container': !showTopMenus }">
        <logo />
        <hamburger v-if="loginUser.orgCode === 'plat'" id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" :is-show="isShow" @toggleClick="toggleSideBar" />
        <allAppProver v-if="loginUser.orgCode !== 'plat' && menuQuick == 1 && productCode === 'ecyoa'" class="hamburger-container" />
        <div v-if="loginUser.orgCode == 'plat'" class="header-navbar header-navbar-plat">
            <topMenu v-if="showTopMenus" @showToggle="showToggle" />
            <tags-view v-if="needTagsView && !showTopMenus" />
        </div>
        <div v-if="loginUser.orgCode !== 'plat'" class="header-navbar header-navbar-top">
            <topMenu v-if="showTopMenus && (layoutStyle === 0 || !layoutStyle)" :style-layout="layoutStyle" @showToggle="showToggle" />
            <tags-view v-if="needTagsView && !showTopMenus" />
        </div>
        <!-- 顶部背景图 -->
        <div v-if="bgUrl" class="bgcImg" @click="docFn">
            <!-- <img id="img-bg" style="height:60px;position: absolute;right: -94px;" :src="bgcImg" alt=""> -->
            <img id="img-bg" style="height: 60px; position: absolute; right: 0; top: 0" :src="bgUrl" alt="" />
        </div>
        <!-- 搜索 -->
        <div v-if="layoutStyle === 1" style="display: inline-block; margin-left: 10px; width: 280px; z-index: 9999; padding-right: 15px; text-align: right; position: relative">
            <input ref="inputA" v-model="searchKeyA" placeholder="请输入关键词查询" class="searchA" style="width: 280px; border: 1px solid #fff; height: 32px; border-radius: 2px; margin-top: 16px; font-size: 13px; outline: none; padding: 0px 24px 0 10px" type="text" @keyup.enter="enterinput" @focus="focusFn" @blur="blurFn" />
            <i style="cursor: pointer; position: absolute; right: 16px; bottom: 8px; color: #000" class="el-icon-search" @click="enterinput" />
        </div>
        <div v-if="isSetupShow" class="right-menu" :class="layoutStyle === 1 ? 'right-menu-style' : ''" style="display: flex">
            <!-- <div v-if="Searchshow&&loginUser&&loginUser.userType!='plat'" ref="searchIcon" style="margin-right: 10px;cursor: pointer;">
        <svg
          style=" width: 22px;height: 22px;margin-top: 19px;"
          ria-hidden="true"
          class="svg-icon-font"
        >
          <use :xlink:href="'#icon-souaaaa'" />
        </svg>
      </div> -->
            <div v-if="isShowSearch && (layoutStyle === 0 || !layoutStyle)" style="width: 200px; z-index: 9999; padding-right: 15px; text-align: right; position: relative">
                <input ref="inputA" v-model="searchKeyA" class="searchA" style="border: 1px solid #fff; height: 24px; border-radius: 10px; margin-top: 19px; font-size: 12px; width: 0px; outline: none; padding: 0px 24px 0 10px" type="text" @keyup.enter="enterinput" @focus="focusFn" @blur="blurFn" />
                <i style="cursor: pointer; position: absolute; right: 21px; bottom: 21px; color: #000" class="el-icon-search" @click="enterinput" />
            </div>
            <div v-if="Searchshow && loginUser && loginUser.userType != 'plat' && !isShowSearch && (layoutStyle === 0 || !layoutStyle)" ref="searchIcon" style="margin-right: 10px; cursor: pointer" @click="isShowSearchFn">
                <el-tooltip content="搜索" effect="dark" placement="bottom">
                    <div style="height: 100%">
                        <svg-icon class-name="svg-icon-font" icon-class="souaaaa" style="width: 22px; height: 22px; margin-top: 19px; color: var(--textColor); cursor: pointer" />
                    </div>
                </el-tooltip>
            </div>
            <!-- 消息提醒 -->
            <template>
                <el-badge :value="messageNum" :hidden="messageNum < 1 ? true : false" :max="99" class="item" :class="layoutStyle === 1 ? 'badgelayout' : ''">
                    <el-tooltip content="消息提醒" effect="dark" placement="bottom">
                        <div class="right-menu-item edit-box" style="overflow: hidden">
                            <!-- <span style="float:left;">|&nbsp;</span> -->
                            <notification style="float: left; font-size: 18px" @flushTotal="MessageTotal" />
                        </div>
                    </el-tooltip>
                </el-badge>
                <span v-if="layoutStyle === 1" style="cursor: pointer; font-size: 13px; color: var(--textColor); position: relative; top: 2px; left: -13px">消息</span>
            </template>
            <!-- 换肤 -->
            <template v-if="loginUser.orgCode !== 'plat'">
                <!-- <div class="right-menu-item skin-box">
          <el-tooltip class="item" effect="dark" content="个性换肤" placement="bottom">
            <svg-icon class-name="organ-icon" icon-class="theme" style="cursor: pointer;" @click="openFn" />
          </el-tooltip>
        </div> -->
                <div class="right-menu-item skin-box">
                    <el-tooltip class="item" effect="dark" content="个性换肤" placement="bottom">
                        <div v-if="layoutStyle === 0 || !layoutStyle">
                            <svg-icon class-name=" organ-icon" icon-class="theme" style="cursor: pointer; color: var(--textColor)" @click="gotoPages('basicsetup')" />
                        </div>
                        <div v-else style="cursor: pointer" @click="gotoPages('basicsetup')">
                            <svg-icon class-name=" organ-icon" icon-class="theme3" style="font-size: 17px; cursor: pointer; color: var(--textColor)" />
                            <span style="font-size: 13px; margin-left: 6px; color: var(--textColor)">换肤</span>
                        </div>
                    </el-tooltip>
                </div>
            </template>
            <template v-if="loginUser.orgCode !== 'plat' && canShowChat">
                <div class="right-menu-item skin-box">
                    <el-tooltip content="客服咨询" effect="dark" placement="bottom">
                        <div>
                            <svg-icon class-name="chat-icon" icon-class="wxChat" @click="gotoChat" />
                        </div>
                    </el-tooltip>
                </div>
            </template>
            <template>
                <div class="right-menu-item skin-box">
                    <el-tooltip class="item" effect="dark" content="帮助中心" placement="bottom">
                        <div v-if="layoutStyle === 0 || !layoutStyle">
                            <svg-icon class-name=" organ-icon" icon-class="question" style="cursor: pointer; color: var(--textColor)" @click="gotoHelp" />
                        </div>
                        <div v-else style="cursor: pointer" @click="gotoHelp">
                            <svg-icon class-name=" organ-icon" icon-class="helps" style="font-size: 17px; cursor: pointer; color: var(--textColor)" />
                            <span style="font-size: 13px; margin-left: 6px; color: var(--textColor)">帮助</span>
                        </div>
                    </el-tooltip>
                </div>
            </template>
            <!-- 手机端下载 -->
            <template v-if="loginUser.orgCode !== 'plat'">
                <!-- <div class="right-menu-item skin-box">
          <el-tooltip class="item" effect="dark" content="手机端下载" placement="bottom">
            <div>
              <svg-icon class-name=" organ-icon" icon-class="dowPhApp" style="cursor: pointer; color:var(--textColor);" @click="gotoPages('basicsetup')" />
            </div>
          </el-tooltip>
        </div> -->
                <el-popover placement="bottom" trigger="hover" :popper-class="'login-APPDownload1'" :visible-arrow="false">
                    <div class="login-APPDownload-box1 login-APPDownload-app1">
                        <!-- <img class="login-APPDownload-img" :src="'./images/w-imgs/login/downLoadCode.png'"> -->
                        <img class="login-APPDownload-img1" :src="appDownloadQrCode" />
                        <div class="login-APPDownload-text1">
                            扫描二维码
                            <br />
                            下载手机客户端
                        </div>
                    </div>
                    <template slot="reference">
                        <svg-icon v-if="layoutStyle === 0 || !layoutStyle" class-name=" organ-icon" icon-class="dowPhApp" style="cursor: pointer; color: var(--textColor); margin: 20px 5px; font-size: 19px" />
                        <span v-else style="cursor: pointer; padding: 0 15px">
                            <svg-icon class-name=" organ-icon" icon-class="downapps" style="font-size: 17px; cursor: pointer; color: var(--textColor); margin: 22px 6px" />
                            <span style="font-size: 13px; position: relative; top: -21px; color: var(--textColor)">下载APP</span>
                        </span>
                    </template>
                </el-popover>
            </template>
            <template v-if="device !== 'mobile'">
                <el-tooltip v-if="organList && organList && organList.length > 1" content="点击切换单位" effect="dark" placement="bottom">
                    <OrganSelect class="right-menu-item hover-effect" />
                </el-tooltip>
            </template>

            <template>
                <el-tooltip content="语言切换" effect="dark" placement="bottom">
                    <lang-select class="right-menu-item hover-effect set-language" />
                </el-tooltip>
            </template>

            <!-- 系统管理 -->
            <template v-if="loginUser.orgCode !== 'plat' && isAdmin">
                <div class="right-menu-item skin-box">
                    <el-tooltip class="item" effect="dark" content="系统管理" placement="bottom">
                        <div v-if="layoutStyle === 0 || !layoutStyle">
                            <svg-icon class-name=" organ-icon" icon-class="set up_a" style="cursor: pointer; color: var(--textColor)" @click="gotoPages('deptMgr')" />
                        </div>
                        <div v-else style="cursor: pointer" @click="gotoPages('deptMgr')">
                            <svg-icon class-name=" organ-icon" icon-class="setups" style="font-size: 17px; cursor: pointer; color: var(--textColor)" />
                            <span style="font-size: 13px; margin-left: 6px; color: var(--textColor)">设置</span>
                        </div>
                    </el-tooltip>
                </div>
            </template>

            <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
                <div class="avatar-wrapper">
                    <img :src="photoUrl" class="user-avatar" />
                    <!-- <i class="el-icon-caret-bottom" />
          <div class="welcome"><div style="line-height:18px;">欢迎</div><div style="line-height:18px;">{{ nickname }}</div></div> -->
                </div>
                <el-dropdown-menu slot="dropdown">
                    <router-link to="/">
                        <el-dropdown-item>
                            {{ $t('navbar.dashboard') }}
                        </el-dropdown-item>
                    </router-link>

                    <p v-if="loginUser && loginUser.userType != 'plat' && showPersonStatus" style="margin: 0" @click="gotoPages('person_info')">
                        <el-dropdown-item>
                            {{ $t('navbar.profile') }}
                        </el-dropdown-item>
                    </p>

                    <p v-if="loginUser && loginUser.userType != 'plat' && ($route.meta.parentIconCode === 'portal' || $route.meta.code === 'grMH.svg' || $route.meta.code === 'dwMH.svg' || $route.meta.code === 'jtMH.svg' || $route.meta.code === 'djMH.svg' || $route.meta.code === 'xzMH.svg')" style="margin: 0" @click="showAuto">
                        <el-dropdown-item>首页设置</el-dropdown-item>
                    </p>

                    <p v-if="loginUser && loginUser.userType == 'plat' && loginUser.sysCode != 'eccloud'" style="margin: 0" @click="backToPlatWorkspace()">
                        <el-dropdown-item>管理工作台</el-dropdown-item>
                    </p>

                    <!--a target="_blank" href="https://github.com/PanJiaChen/vue-element-admin/">
            <el-dropdown-item>
              {{ $t('navbar.github') }}
            </el-dropdown-item>
          </!--a>
          <a-- target="_blank" href="https://panjiachen.github.io/vue-element-admin-site/#/">
            <el-dropdown-item>Docs</el-dropdown-item>
          </a-->
                    <el-dropdown-item divided>
                        <span style="display: block" @click="logout">{{ $t('navbar.logOut') }}</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div v-else class="right-menu" style="display: flex">
            <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
                <div class="avatar-wrapper">
                    <img :src="photoUrl" class="user-avatar" />
                    <!-- <i class="el-icon-caret-bottom" />
          <div class="welcome"><div style="line-height:18px;">欢迎</div><div style="line-height:18px;">{{ nickname }}</div></div> -->
                </div>
                <el-dropdown-menu slot="dropdown">
                    <router-link to="/">
                        <el-dropdown-item>
                            {{ $t('navbar.dashboard') }}
                        </el-dropdown-item>
                    </router-link>

                    <p v-if="loginUser && loginUser.userType != 'plat' && showPersonStatus" style="margin: 0" @click="gotoPages('person_info')">
                        <el-dropdown-item>
                            {{ $t('navbar.profile') }}
                        </el-dropdown-item>
                    </p>

                    <p v-if="loginUser && loginUser.userType != 'plat' && ($route.meta.parentIconCode === 'portal' || $route.meta.code === 'grMH.svg' || $route.meta.code === 'dwMH.svg' || $route.meta.code === 'jtMH.svg' || $route.meta.code === 'djMH.svg' || $route.meta.code === 'xzMH.svg')" style="margin: 0" @click="showAuto">
                        <el-dropdown-item>首页设置</el-dropdown-item>
                    </p>

                    <p v-if="loginUser && loginUser.userType == 'plat' && loginUser.sysCode != 'eccloud'" style="margin: 0" @click="backToPlatWorkspace()">
                        <el-dropdown-item>管理工作台</el-dropdown-item>
                    </p>

                    <!--a target="_blank" href="https://github.com/PanJiaChen/vue-element-admin/">
            <el-dropdown-item>
              {{ $t('navbar.github') }}
            </el-dropdown-item>
          </!--a>
          <a-- target="_blank" href="https://panjiachen.github.io/vue-element-admin-site/#/">
            <el-dropdown-item>Docs</el-dropdown-item>
          </a-->
                    <el-dropdown-item divided>
                        <span style="display: block" @click="logout">{{ $t('navbar.logOut') }}</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- <elCardShow v-if="elCardShow" ref="elCardShow" :el-card-show="elCardShow" class="elCardShowclass" @searchinput="searchinput" /> -->

        <div v-if="designTime" class="designTimeHeader" />
    </div>
</template>

<script>
import Logo from './Logo'
import TopMenu from './TopMenu'
import TagsView from '../TagsView'
import { mapGetters, mapState } from 'vuex'
import Hamburger from '@/wapp/common/components/Hamburger'
import OrganSelect from './OrganSelect'
// import elCardShow from './elCardShow'
import LangSelect from '@/wapp/common/components/LangSelect'
import Notification from './Notification'
import Cookies from 'js-cookie'
import allAppProver from './allAppProver.vue'
let timer = null
export default {
    components: {
        Logo,
        TopMenu,
        OrganSelect,
        Hamburger,
        TagsView,
        // elCardShow,
        LangSelect,
        Notification,
        allAppProver
    },
    props: {
        designTime: { type: Boolean, default: false },
        styleLayout: {
            type: Number,
            default: 0
        },
        bgUrl: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isShow: true,
            nickname: '',
            photoUrl: '',
            showCustom: null,
            gxh: false,
            showshow: false,
            showPersonStatus: true,
            elCardShow: false,
            elCardShowWatch: false,
            Searchshow: window.Searchshow,
            isShowSearch: false,
            searchKeyA: '',
            messageNum: localStorage.messageNum || 0,
            bgcImg: '',
            uiStyle: '',
            listColor: [],
            isOpenEs: false,
            // app下载二维码
            appDownloadQrCode: '',
            isSetupShow: true,
            canShowChat: false,
            layoutStyle: 0,
            menuQuick: 1,
            productCode: ''
        }
    },
    computed: {
        ...mapGetters(['sidebar', 'avatar', 'device', 'loginUser', 'organList', 'routes']),
        ...mapState({
            needTagsView: (state) => state.settings.tagsView
        }),
        showTopMenus() {
            console.log('this.$store.state.settings', this.$store.state.settings)
            console.log('this.$store.state.settingsecyoa', this.$store)
            return this.$store.state.settings.showTopMenus
        },
        isCollapse() {
            return !this.sidebar.opened
        }
    },
    watch: {
        elCardShowWatch: {
            handler(val) {
                clearTimeout(timer)
                timer = null
                if (val) {
                    this.elCardShow = true
                } else {
                    timer = setTimeout(() => {
                        this.elCardShow = false
                        clearTimeout(timer)
                        timer = null
                    }, 300)
                }
            }
        }
    },
    created() {
        console.log(328, window.layoutStyle, this.bgUrl)
        // this.layoutStyle = window.layoutStyle
        // 判断是否不需要显示右上角设置项
        const setupShow = Cookies.get('setupShow')
        if (setupShow) {
            this.isSetupShow = false
        }
        // 是否开启聊天功能
        this.openChat()
        this.getAppDownloadQrCode()
        const cookieStyle = this.Cookies.get('uiStyle')
        let style = ''
        if (cookieStyle !== null && cookieStyle !== undefined) {
            style = cookieStyle
        } else {
            this.Cookies.set('uiStyle', style)
            style = this.loginUser.uiStyle
        }
        document.querySelector('body').setAttribute('id', this.loginUser.orgCode != 'plat' ? style : 'skinScienceBlue')
        this.$bus.on('showPerson', this.showPerson)
        document.addEventListener('mouseover', this.searchTimer)
        if (this.$route.query.sysName) {
            if (this.$route.query.muneCode) {
                this.moreClick(this.$route.query.muneCode)
            }
            localStorage.setItem('sys-menuName', this.$route.query.sysName)
        }
        this.isAdmin = this.$store.state.user.roles.includes('dwAdministrators')
        this.MessageTotal()
        this.getIsEs()
        this.showMenuQuick()
    },
    mounted() {
        this.nickname = this.loginUser.personName || this.loginUser.nickname
        this.photoUrl = this.avatar
        this.productCode = sessionStorage.getItem('productCode')
        if (!this.productCode) {
            this.productCode = window.productCode
        }
        if (this.productCode !== '') {
            this.photoUrl += '&productCode=' + this.productCode
        }

        this.$http({
            // 访问用户基本设置信息
            method: 'GET',
            contentType: 'application/x-www-form-urlencoded',
            params: { id: this.$store.getters.loginUser.userId },
            url: 'base/user/getById'
        }).then(
            (response) => {
                if (response.code === 'success') {
                    this.uiStyle = response.body.vo.uiStyle
                    // this.getStyleListData()
                } else {
                    this.$message.error(response.message)
                }
            },
            (err) => {
                console.log(err)
            }
        )
    },
    destroyed() {
        this.$bus.off('showPerson', this.showPerson)
        document.removeEventListener('mouseover', this.searchTimer)
    },
    methods: {
        showMenuQuick() {
            this.$http({
                method: 'get',
                contentType: 'application/x-www-form-urlencoded',
                params: {
                    orgCode: this.loginUser.orgCode,
                    paramName: 'ecyoa_showMenuQuick'
                },
                url: 'base/paramConfig/findParamValueByName'
            }).then((res) => {
                if (res.code === 'success') {
                    this.menuQuick = res.body.value == '1'
                }
            })
        },
        openChat() {
            this.$http({
                method: 'get',
                contentType: 'application/x-www-form-urlencoded',
                params: {
                    orgCode: 'standard',
                    paramName: 'base.isOpenChat'
                },
                url: 'base/paramConfig/findParamValueByName'
            }).then((res) => {
                if (res.code === 'success') {
                    this.canShowChat = res.body.value === 'true'
                }
            })
        },
        gotoChat() {
            this.$http({
                url: '/gdgl/sessionParent/getLoginUserStatus',
                method: 'get'
            })
                .then((res) => {
                    if (res.code === 'success') {
                        if (res.body.flag) {
                            this.$confirm('该账号已在别处登录是否继续执行？', '操作确认', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            })
                                .then(() => {
                                    window.open('/#/kflt?isAdmin=1')
                                })
                                .catch(() => {})
                        } else {
                            window.open('/#/kflt?isAdmin=1')
                        }
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        gotoHelp() {
            window.open('/#/helpCenter?logoTitle=易臣云办公帮助中心')
        },
        // 获取app下载二维码
        getAppDownloadQrCode() {
            this.appDownloadQrCode = (window.appVerApiUrl || process.env.VUE_APP_BASE_API) + `/mpp/install/downloadQrCode?apigateway=${window.location.origin}/apigw/&deleteWhite=true` + '&r=' + Math.random() + '&packageName=' + window.appPackageName
        },
        // 判断是否开启es
        getIsEs() {
            this.$http({
                method: 'get',
                url: '/common/elasticsearch/openElasticsearch'
            }).then((res) => {
                // console.log('是否开启es搜索', res.body.openElasticsearch)
                if (res.code === 'success') {
                    this.isOpenEs = res.body.openElasticsearch
                }
            })
        },
        getStyleListData() {
            const data = new FormData()
            data.append('urlsearch', 'orgCode:' + this.$store.getters.loginUser.orgCode)
            this.$http({
                // 获取颜色列表
                method: 'POST',
                data: data,
                url: 'base/styleConfig/listdata'
            }).then(
                (response) => {
                    if (response.code === 'success') {
                        this.listColor = response.body.pageInfo.list
                        if (this.uiStyle && this.listColor.length > 0) {
                            this.listColor.forEach((item) => {
                                if (item.inHoliday) {
                                    if (item.topbarBgImg) {
                                        this.bgcImg = process.env.VUE_APP_BASE_API + '/base/styleConfig/download?nameField=topbarBgImg&dataField=topbarBgImgFile&id=' + item.id
                                    }
                                } else if (this.uiStyle === item.styleCode) {
                                    if (item.topbarBgImg) {
                                        this.bgcImg = process.env.VUE_APP_BASE_API + '/base/styleConfig/download?nameField=topbarBgImg&dataField=topbarBgImgFile&id=' + item.id
                                    }
                                }
                            })
                        }
                    } else {
                        this.$message.error(response.message)
                    }
                },
                (err) => {
                    console.log(err)
                }
            )
        },
        docFn() {
            this.isShowSearch = false
        },
        enterinput() {
            // console.log(this.searchKeyA)
            if (this.searchKeyA.trim() !== '') {
                if (this.isOpenEs) {
                    this.moreClick('qwjs')
                } else {
                    this.moreClick('wjcx')
                }
                this.$bus.emit('newSearch', this.searchKeyA)
                this.searchKeyA = ''
                this.isShowSearch = false
            }
        },
        // 跳转页面
        moreClick(code) {
            // console.log('nowId', this.nowId, code)
            const toPath = this.getMenuCode(code)
            if (toPath && toPath.path) {
                let path
                switch (toPath.meta.level) {
                    case 3:
                        path = toPath.meta.activeMenu || toPath.path
                        break
                    case 4:
                        path = toPath.meta.activeMenu + '/' + toPath.path
                        break
                    default:
                        path = toPath.path
                }
                path = path + `?seachKeyWj=${this.searchKeyA}`
                this.$router.push({ path: path })
            }
        },
        // 递归查询菜单代码
        getMenuCode(code) {
            const pathlist = []
            const pathObj = {}
            let toPath = {}
            function findCode(ary) {
                ary.forEach((val) => {
                    if (val.children && val.meta && val.meta.code !== code) {
                        findCode(val.children)
                    } else if (val.meta && val.meta.code === code) {
                        toPath = val
                        if (!pathObj[val.path]) {
                            pathObj[val.path] = val
                            pathlist.push(val)
                        }
                        return false
                    }
                })
            }
            findCode(this.routes)

            if (pathlist.length < 2) {
                return toPath
            } else {
                return toPath
                // let filterpath = null
                // if (this.header && this.header.filterPath) {
                //     filterpath = this.header.filterPath(pathlist)
                // } else if (this.filterPath !== null) {
                //     filterpath = this.filterPath(pathlist)
                // }
                // return filterpath
            }
        },
        MessageTotal() {
            this.$http({
                method: 'GET',
                url: '/message/vwInbox/getToReadCount',
                params: {
                    orgCode: this.loginUser.orgCode
                }
            }).then((res) => {
                this.messageNum = res.body.toReadCount > 0 ? res.body.toReadCount : ''
                localStorage.setItem('messageNum', this.messageNum)
            })
        },
        blurFn() {
            // console.log('失焦')
            this.isShowSearch = false
        },
        focusFn() {
            // console.log('聚焦')
            this.isShowSearch = true
        },
        isShowSearchFn() {
            // console.log('点击搜索')
            this.isShowSearch = true
            this.$nextTick(() => {
                $('.searchA').animate(
                    {
                        width: '185px'
                    },
                    200,
                    'linear'
                )

                this.$refs.inputA.focus()
            })
        },
        openFn() {
            const url = location.href.split('#')[0]
            window.open(url + '#/lm/21b5eaae603f4e468ebf5f0f70937b27/c027b3228e2e4eaead46d8735ade8332', '_self')
        },
        searchTimer(e) {
            var _this = this
            var aaa = false
            var bbb = false
            const elCardShow1 = _this.$refs.elCardShow
            if (elCardShow1) {
                aaa = elCardShow1.$el.contains(e.target)
            }
            // if (topMenu) {
            //     const topMenu1 = topMenu.$refs.elCardShow
            //     if (topMenu1) {
            //         aaa = topMenu1.$el.contains(e.target)
            //     }
            // }

            const searchIcon = _this.$refs.searchIcon
            if (searchIcon) {
                bbb = searchIcon.contains(e.target)
            }

            if (aaa || bbb) {
                _this.elCardShowWatch = true
            } else {
                // timer = setTimeout(() => {
                _this.elCardShowWatch = false
            }
            // })
        },
        searchinput(item) {
            this.elCardShow = item
        },
        showPerson(show) {
            this.showPersonStatus = show
        },
        showAuto() {
            if (this.gxh === true) {
                this.showshow = false
            } else {
                this.showshow = true
            }
            this.$bus.emit('showCustomFuc', this.showshow)
        },
        async logout() {
            await this.$store.dispatch('user/logout')

            await this.$store.dispatch('tagsView/delAllViews')
            this.Cookies.set('topMenuIndex', '')
            this.$router.push(`/login`)
            // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
            sessionStorage.setItem('setSysMenuName', '首页')
            localStorage.setItem('subProducts', 0)
        },
        toggleSideBar() {
            this.$store.dispatch('app/toggleSideBar')
            this.$bus.emit('toggleClick')
            this.$bus.emit('theSiderShow')
        },
        getCookie(cname) {
            var name = cname + '='
            var ca = document.cookie.split(';')
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i].trim()
                if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
            }
            return ''
        },
        showToggle(boo) {
            this.isShow = boo
        },
        // 递归查询菜单代码
        // getMenuCode(code) {
        //     let toPath
        //     function findCode(ary) {
        //         ary.forEach(val => {
        //             if (val.children) {
        //                 findCode(val.children)
        //             }
        //             if (!val.children && val.meta && val.meta.code === code) {
        //                 toPath = val
        //                 return false
        //             }
        //         })
        //     }
        //     findCode(this.routes)
        //     return toPath
        // },
        // 跳转页面
        gotoPages(code) {
            const toPath = this.getMenuCode(code)
            if (toPath && toPath.path) {
                let path
                switch (toPath.meta.level) {
                    case 3:
                        path = toPath.meta.activeMenu || toPath.path
                        break
                    case 4:
                        path = toPath.meta.activeMenu + '/' + toPath.path
                        break
                    default:
                        path = toPath.path
                }
                this.$router.push({ path: path })
            }
        },
        backToPlatWorkspace() {
            this.$http({
                method: 'GET',
                url: 'base/sysSetup/switchSysLogin',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then((response) => {
                if (response.code === 'success') {
                    this.$store
                        .dispatch('user/tokenSsoLogin', response.body)
                        .then(async (res) => {
                            location = '/'
                        })
                        .catch((error) => {
                            this.error = error.msg
                        })
                } else {
                    this.error = response.msg
                    this.getLoginQrCode()
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import '~@/wapp/common/styles/variables.module.scss';

.hamburger-container {
    height: $headerHeight;
    line-height: $headerHeight;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;
    z-index: 99;
    .hamburger {
        fill: $headerText;
    }
}

.header-navbar {
    float: left;
    top: 0px;
    &.collapse {
        left: 54px;
    }

    .el-menu::after,
    .el-menu::before {
        display: none;
    }

    .breadcrumb-container {
        float: left;
    }

    .tags-view-container {
        width: calc(100vw - #{$sideBarWidth} - 50px - 250px);
        height: 60px;
        border-bottom: 0px;

        .scroll-container {
            height: 60px;
            .el-scrollbar__wrap {
                height: 77px;
                .tags-view-item {
                    font-size: 14px;
                    height: 60px;
                    line-height: 60px;
                }
            }
        }
    }
    .el-menu-item {
        border-bottom: none !important;
    }
}
// .header-navbar-top{
//     // width: 0;
//   width: 660px !important;
// }
.header-container {
    width: 100%;
    position: fixed;
    background: $headerBackground;
    top: 0px;
    z-index: 1999;

    .designTimeHeader {
        margin: 0 0;
        width: 100%;
        height: $headerHeight;
        background: white;
        opacity: 0.5;
    }
}
.hideTopMenus-header-container {
    border-bottom: $borderSolid;
    background: #fff !important;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    z-index: 1000;

    .hamburger {
        fill: $dark;
    }
    .breadcrumb-container {
        float: left;
        height: $headerHeight;
        line-height: $headerHeight !important;
    }

    .right-menu-item {
        color: $dark !important;
    }

    .sidebar-logo-container {
        background: $dark-menu-bgcolor;
        .sidebar-logo-link .sidebar-title {
            /* color: var(--textColor)!important; */
            color: $headerText !important;
        }
    }

    .tags-view-container .tags-view-wrapper .tags-view-item {
        border-bottom: none;
    }
}
.right-menu-style {
    .right-menu-item {
        display: inline-block;
        padding: 0 15px !important;
        height: 100%;
        font-size: 18px;
        color: $headerText;
        vertical-align: text-bottom;

        &.hover-effect {
            cursor: pointer;
            transition: background 0.3s;

            &:hover {
                background: rgba(0, 0, 0, 0.025);
            }
        }
    }
}
.right-menu {
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100%;
    line-height: $headerHeight;

    &:focus {
        outline: none;
    }

    .right-menu-item {
        display: inline-block;
        padding: 0 8px;
        height: 100%;
        font-size: 18px;
        color: $headerText;
        vertical-align: text-bottom;

        &.hover-effect {
            cursor: pointer;
            transition: background 0.3s;

            &:hover {
                background: rgba(0, 0, 0, 0.025);
            }
        }
    }

    .avatar-container {
        margin-right: 10px;

        .avatar-wrapper {
            /* margin-top: 5px; */
            position: relative;
            float: right;

            .user-avatar {
                cursor: pointer;
                width: 38px;
                height: 38px;
                border-radius: 50%;
                margin-top: 10px;
                border: #fff solid 2px;
            }

            .el-icon-caret-bottom {
                cursor: pointer;
                font-size: 12px;
                float: right;
                line-height: $headerHeight;
            }

            .welcome {
                font-size: 12px;
                float: right;
                margin: 0px 5px;
                padding-top: 10px;
                height: $headerHeight;
                word-break: break-word;
                word-wrap: break-word;
                text-align: center;
                max-width: 80px;
            }
        }
    }
}

.header-container {
    background: var(--bgColor);
    color: var(--textColor);
    .el-menu-item {
        background: var(--bgColor);
        color: var(--textColor) !important;
        font-weight: 700;
        &.is-active {
            background: var(--bgColorActive) !important;
            color: var(--textColorActive) !important;
            font-weight: 700;
        }
        &:hover {
            color: var(--textColorActive) !important;
            background: var(--bgColorActive) !important;
        }
        &.top_sub_menu {
            .el-menu--horizontal {
                .el-menu--popup {
                    background: var(--bgColor);
                    color: var(--textColor) !important;
                    .el-menu-item {
                        &:hover {
                            color: var(--textColorActive) !important;
                            background: var(--bgColorActive) !important;
                        }
                    }
                }
            }
            &.is-active {
                .el-submenu__title {
                    background: unset;
                    color: var(--textColorActive) !important;
                }
            }
        }
    }
}

.bgcImg {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    // width: calc(100% - 920px);
    // text-align: center;
    // position: relative;
    width: 10px;
    position: absolute;
    right: 0;
    top: 0;
}
.login-APPDownload1 {
    width: 150px;
    height: 176px;
    padding: 0px;
    display: flex;
}
.login-APPDownload-box1 {
    position: relative;
    flex: 1;
    margin: 10px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    .login-APPDownload-img1 {
        width: 100px;
        height: 100px;
    }
    .login-APPDownload-icon {
        width: 70px;
        height: 70px;
        color: #ccc;
    }
    .login-APPDownload-text1 {
        font-size: 12px;
        color: #333333;
        line-height: 19px;
        text-align: center;
    }
    .login-APPDownload-btn {
        width: 138px;
        &.is-plain {
            background-color: #fff;
            &:hover {
                background-color: #3a8ee6;
            }
            &:active {
                background-color: #3a8ee6;
            }
            &:focus {
                background-color: #3a8ee6;
            }
        }
    }
    .login-APPDownload-download {
        background-color: #f7f8fa;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .login-APPDownload-icon {
            color: #999;
        }
    }
    &:hover {
        .login-APPDownload-download {
            display: flex;
        }
    }
}
</style>
<style scoped lang="scss">
/deep/.el-badge__content.is-fixed {
    top: 19px;
    right: 23px;
    z-index: 1;
}

/deep/.badgelayout .el-badge__content.is-fixed {
    top: 17px;
    right: 28px;
    z-index: 1;
}
.el-popover {
    padding: 0px;
}
.header-navbar-plat {
    width:calc(100% - 610px);
}
</style>
