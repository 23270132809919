module.exports = {
    title: '易臣云办公',
    logo: './images/logo.png',
    loginType: 'ProductPanel', //  可选值   RightPanel || TechPanel || PortalPanel || PlatPanel  || ProductPanel || zwPanal || oldLogin  选不同页面
    headerbgColor: '',
    fullScreen: true, // 流程和新开窗口表单是否全屏
    listDataBorder: true, // 列表边框线样式

    /**
     * @type {boolean} true | false
     * @description Whether show the settings right-panel
     */
    showSettings: false,

    /**
     * @type {boolean} true | false
     * @description Whether need tagsView
     */
    tagsView: true,

    /**
     * @type {boolean} true | false
     * @description Whether fix the header
     */
    fixedHeader: false,

    /**
     * @type {boolean} true | false
     * @description Whether show the showTopMenus
     */
    showTopMenus: true,

    /**
     * @type {boolean} true | false
     * @description Whether need stripeTable
     */
    stripeTable: true,
    addStyle: true // 皮肤
}
