module.exports = {
    navbar: {
        dashboard: '首页',
        github: '项目地址',
        logOut: '退出登录',
        profile: '个人中心',
        theme: '换肤',
        size: '布局大小'
    },
    login: {
        title: '系统登录',
        mobileLogin: '手机号登录',
        accountLogin: '账号登录',
        logIn: '登录',
        usermobile: '手机号',
        username: '用户账号',
        orgCode: '单位编码/机构代码',
        password: '密码',
        kaptcha: '验证码',
        forget: '忘记密码',
        remember: '记住密码',
        trial: '申请试用',
        any: '随便填',
        thirdparty: '第三方登录',
        thirdpartyTips: '本地不能模拟，请结合自己业务进行模拟！！！'
    },
    tagsView: {
        refresh: '刷新',
        close: '关闭',
        closeOthers: '关闭其它',
        closeAll: '关闭所有'
    },
    settings: {
        title: '系统布局配置',
        theme: '主题色',
        tagsView: '开启 Tags-View',
        fixedHeader: '固定 Header',
        showTopMenus: '开启 TopMenu'
    },
    options: {
        msgTypes: {
            0: '站内信',
            1: '短信通知'
        },
        yesOrNo: {
            0: '否',
            1: '是'
        },
        successFailed: {
            0: '失败',
            1: '成功'
        },
        validOrInvalid: {
            0: '无效',
            1: '有效'
        },
        disableOrEnable: {
            0: '禁用',
            1: '启用'
        }
    },
    pageBtns: {
        add: '新增',
        addTo: '添加',
        addToAttach: '添加附件',
        edit: '编辑',
        del: '删除',
        save: '保 存',
        cancel: '取 消',
        sure: '确 定',
        look: '查看',
        update: '修改',
        close: '关 闭',
        operate: '操作',
        reset: '重置',
        search: '查询',
        resetSearch: '重置查询',
        export: '导出',
        import: '导入',
        print: '打印',
        download: '下载',
        forward: '转发',
        submit: '提 交',
        back: '退回',
        saveAdd: '保存并新增',
        send: '发 送',
        ok: '确 认',
        isCompleted: '办结',
        escalation: '上报',
        signFor: '签收',
        open: '开启',
        saveAndClose: '保存关闭',
        urgeProcessing: '催办',
        setting: '设置',
        preview: '预览',
        upload: '上传',
        comDel: '彻底删除',
        uploadFile: '上传文件',
        downFile: '下载文件',
        addFile: '添加文件',
        saveAsFile: '另存文件',
        deleteFile: '删除文件',
        more: '更多'
    },
    common: {
        loading: '正在加载...',
        noData: '暂无数据',
        operationTip: '操作提示',
        confirm: '确认操作',
        saveSuccess: '保存成功',
        noAttach: '暂无附件',
        attach: '附件',
        title: '标题',
        Monday: '周一',
        Tuesday: '周二',
        Wednesday: '周三',
        Thursday: '周四',
        Friday: '周五',
        Saturday: '周六',
        day: '天',
        week: '周',
        month: '月',
        year: '年',
        today: '今天',
        Sunday: '周日',
        enable: '启用',
        disable: '禁用',
        to: '至',
        yes: '是',
        no: '否',
        nothing: '无',
        selectArea: '选择区域',
        selectAreaPlaceholder: '请选择区域',
        selectOrg: '选择机构',
        selectOrgPlaceholder: '请选择机构',
        selectOrgPer: '选择机构人员',
        selectOrgPlaceholderPer: '请选择机构人员',
        selectGroup: '选择群组',
        groupPlaceholder: '请选择群组',
        selectDepart: '选择部门',
        departPlaceholder: '请选择部门',
        selectKsment: '选择科室',
        pSelectKsment: '请选择科室',
        selectPerson: '选择人员',
        personPlaceholder: '请选择人员',
        fileName: '文件名',
        fileSize: '文件大小',
        fileType: '文件类型',
        all: '全部',
        plChoose: '请选择',
        plChooseContent: '请选择{content}',
        plInput: '请输入',
        plInputCon: '请输入内容',
        plInputContent: '请输入{content}',
        startTimePlaceholder: '请选择开始时间',
        endTimePlaceholder: '请选择结束时间'
    },
    // fields: {
    //     index: '序号',
    //     flCreateDate: '申请时间 ',
    //     flCreatorPhone: '联系电话',
    //     flCreateDept: '申请部门',
    //     flCreator: '申请人',
    //     wfNodeNames: '处理环节',
    //     startDate: '开始日期',
    //     endDate: '结束日期',
    //     remark: '备注',
    //     status: '状态',
    //     operate: '操作',
    //     column_operate: '操作',
    //     flSubject: '文件标题',
    //     name: '姓名',
    //     overDate: '完成时限',
    //     startTime: '开始时间',
    //     endTime: '结束时间',
    //     phone: '联系电话'
    // },
    wfdProcess: {
        //流程常用环节、路径、意见栏
        nodes: {
            //start、bmsh为环i18n代码
            start: '发起申请',
            bmsh: '部门审核',
            bmhq: '部门会签',
            proposed: '拟办',
            distribute: '分发',
            ldps: '领导批示',
            handle: '办理',
            completed: '办结',
            end: '结束'
        },
        paths: {
            //toBmsh为路径i18n代码
            toBmsh: '送部门审核',
            toBmhq: '送部门会签',
            toProposed: '送拟办',
            toDistribute: '送分发',
            toLdps: '送领导批示',
            toHandle: '送办理',
            toCompleted: '办结',
            toEnd: '结束'
        },
        ideaLabels: {
            bmshIdea: '部门审核意见',
            bmhqIdea: '部门会签意见',
            proposedIdea: '拟办意见',
            distributeIdea: '分发意见',
            ldpsIdea: '领导批示意见',
            handleIdea: '办理意见',
            completedIdea: '办结意见'
        },
        operations: {
            close: '关闭',
            save: '保存',
            saveAndClose: '保存关闭',
            writeoff: '注销文件',
            print: '打印',
            printer: '打印',
            submit: '提交',
            readed: '已阅',
            readIdea: '阅文意见',
            contestClaim: '认领',
            addReader: '传阅',
            wflowTrace: '流程跟踪',
            viewBodyDoc: '查看正文',
            modifyBodyDoc: '修改正文',
            editBodyDoc: '编辑正文',
            modifyBusiform: '修改表单信息',
            formModifiedTraces: '查看修改痕迹',
            callback: '收回文件',
            modifyWflog: '流程管控',
            donext: '代办',
            SendFileToDept: '发送本单位',
            printCld: '打印处理单',
            printNgz: '打印拟稿纸',
            fileJb: '文件交办',
            forwardDb: '转督办',
            selectLinkFile: '选择关联文件',
            switchSingleDualScreen: '切换单双屏',
            cancellation: '注销',
            fillDocNumber: '填写文号',
            setRed: '套红',
            bodyFileSign: '上传盖章文件',
            pdfFileSign: '盖章',
            saveAs: '另存为',
            sendFile: '发送文件',
            forward: '转发',
            SendFileToCorp: '发送外单位',
            viewSendlog: '发送跟踪',
            printCpb: '打印呈批表',
            printFile: '打印文件',
            transferOffice: '转办',
            addWfNodeUsers: '增加拟办人'
        }
    },
    menuItem: {
        // 顶部栏目导航、左导航菜单
        portal: '门户',
        processCenter: '流程中心',
        appCenter: '应用中心',
        console: '系统设置',
        //工作台菜单
        workbench: '工作台',
        personalAffairs: '个人事务',
        todoTasks: '待办工作',
        todoFile: '待办文件',
        toReadFile: '待阅文件',
        organizerFile: '协办文件',
        suspendFile: '挂起文件',
        completedWork: '已办工作',
        myRead: '我已阅的',
        urgencyWork: '催办工作',
        urgencyMe: '催办我的',
        urgencyProcessed: '已处理',
        myUrgency: '我催办的',
        attentionFiles: '关注的文件',
        myMessage: '我的消息',
        unRead: '未读',
        read: '已读',
        // scheduleManagement: '日程管理',
        // personalSchedule: '个人日程安排',
        // leadershipOfficial: '领导公务安排',
        searchRetrieve: '查询检索',
        fileSearch: '文件查询',
        InformationSearch: '资料查询',
        fullText: '全文查询',
        workSettings: '工作设置',
        toDoReminder: '待办提醒',
        commonOpinions: '常用意见',
        opinionSignature: '意见签名',
        processTemplate: '办理模板',
        outboundAut: '外出授权',
        simulationForm: '仿真表单验证',
        qualityAnomaly: '质量异常信息',
        mailList: '通讯录',
        personalSettings: '个人设置',
        skinPeeler: '个性换肤',
        personalData: '个人资料',
        modifyAvatar: '修改头像',
        changePassword: '修改密码',
        // 公共
        todo: '待办',
        done: '已办',
        myDraft: '我起草的',
        all: '全部',
        draft: '草稿',
        processingProgress: '处理中',
        ended: '已结束',
        tobeReviewed: '待阅',
        readAlready: '已阅',
        myCreated: '我创建的',
        notSubmit: '未提交',
        submitted: '已提交',
        pendingProcessing: '待处理',
        myHandled: '我处理的',
        qwjs: '全文检索'
    }
}
