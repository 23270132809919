import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ELEMENT from 'element-ui'
import Cookies from 'js-cookie'
import { lang } from 'ecinc-cloud-wappaio'

const enLocale = require('@/global/lang/en')
const zhCNLocale = require('@/global/lang/zhCN')
const esLocale = require('@/global/lang/es')
const jaLocale = require('@/global/lang/ja')

const common = {
    en: {
        ...ELEMENT.lang.en,
        ...enLocale,
        ...lang.aiomessages.en
    },
    zhCN: {
        ...ELEMENT.lang.zhCN,
        ...zhCNLocale,
        ...lang.aiomessages.zhCN
    },
    es: {
        ...ELEMENT.lang.es,
        ...esLocale,
        ...lang.aiomessages.es
    },
    ja: {
        ...ELEMENT.lang.ja,
        ...jaLocale,
        ...lang.aiomessages.ja
    }
}

const yoabase = require('ecinc-cloud-yoabase')
const yoaecoa = require('ecinc-cloud-yoaecoa')
const yoagwgl = require('ecinc-cloud-yoagwgl')
const yoaclgl = require('ecinc-cloud-yoaclgl')
const yoaemail = require('ecinc-cloud-yoaemail')
// const yoahygl = require('ecinc-cloud-yoahygl')
const yoadcdb = require('ecinc-cloud-yoadcdb')

const messages = {} // 创建一个新对象用于存放合并后的结果
const keys = Object.keys(common) // 获取common对象的所有第一级键
keys.forEach((key) => {
    // 合并common和各产品中对应键下的对象
    messages[key] = {
        ...common[key],
        ...yoabase.default.$messages[key],
        ...yoaecoa.default.$messages[key],
        ...yoagwgl.default.$messages[key],
        ...yoaclgl.default.$messages[key],
        ...yoaemail.default.$messages[key],
        // ...yoahygl.default.$messages[key],
        ...yoadcdb.default.$messages[key]
    }
})

Vue.use(ELEMENT, {
    size: Cookies.get('size') || 'medium', // set element-ui default size
    i18n: (key, value) => i18n.t(key, value)
})
Vue.use(VueI18n)

const i18n = new VueI18n({
    // set locale
    // options: en | zh | es
    locale: lang.getLanguage(),
    // set locale messages
    messages
})

i18n.generateTitle = lang.generateTitle
i18n.getPageTitle = lang.getPageTitle

Vue.prototype.$tx = lang.$tx

export default i18n
