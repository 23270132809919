module.exports = {
    navbar: {
        // Topbar常用按钮
        dashboard: 'Dashboard',
        github: 'Github',
        logOut: 'Log Out',
        profile: 'Profile',
        theme: 'Theme',
        size: 'Global Size'
    },
    login: {
        // 登录界面
        title: 'Login Form',
        mobileLogin: 'Mobile',
        accountLogin: 'Account',
        logIn: 'Login',
        usermobile: 'Mobile number',
        username: 'User account',
        orgCode: 'Organization code',
        password: 'Password',
        kaptcha: 'Kaptcha',
        forget: 'Forget?',
        remember: 'Remember',
        trial: 'No account?Trial account',
        any: 'any',
        thirdparty: 'Or connect with',
        thirdpartyTips: 'Can not be simulated on local, so please combine you own business simulation! ! !'
    },
    tagsView: {
        // 工作区快捷导航右键菜单
        refresh: 'Refresh',
        close: 'Close',
        closeOthers: 'Close Others',
        closeAll: 'Close All'
    },
    menuItem: {
        // 顶部栏目导航、左导航菜单
        portal: 'Portal',
        processCenter: 'Process Center',
        appCenter: 'App Center',
        console: 'AdminConsole',
        // 工作台菜单
        workbench: 'Workbench',
        personalAffairs: 'Personal Affairs',
        todoTasks: 'To-Do Tasks',
        todoFile: 'To-Do Files',
        toReadFile: 'Files to Read',
        organizerFile: 'Co-organized Files',
        suspendFile: 'Suspended Files',
        completedWork: 'Completed Work',
        myRead: 'Read by Me',
        urgencyWork: 'Urgent Work',
        urgencyMe: 'Urgent for Me',
        urgencyProcessed: 'Processed Urgently',
        myUrgency: 'Urgent by Me',
        attentionFiles: 'Files Under Attention',
        myMessage: 'My Messages',
        unRead: 'Unread',
        read: 'Read',
        // "scheduleManagement": "Schedule Management",
        // "personalSchedule": "Personal Schedule",
        // "leadershipOfficial": "Leadership Official Schedule",
        searchRetrieve: 'Search and Retrieve',
        fileSearch: 'File Search',
        InformationSearch: 'Information Search',
        fullText: 'Full-Text Search',
        workSettings: 'Work Settings',
        toDoReminder: 'To-Do Reminder',
        commonOpinions: 'Common Opinions',
        opinionSignature: 'Opinion Signature',
        processTemplate: 'Processing Template',
        outboundAut: 'Outbound Authorization',
        simulationForm: 'Simulation Form Verification',
        qualityAnomaly: 'Quality Anomaly Information',
        mailList: 'Address Book',
        personalSettings: 'Personal Settings',
        skinPeeler: 'Personal Skin Changer',
        personalData: 'Personal Data',
        modifyAvatar: 'Modify Avatar',
        changePassword: 'Change Password',
        // 公共
        todo: 'To-Do',
        done: 'Done',
        myDraft: 'Drafted by Me',
        all: 'All',
        draft: 'Draft',
        processingProgress: 'Processing',
        ended: 'Ended',
        tobeReviewed: 'To Be Reviewed',
        readAlready: 'Already Read',
        myCreated: 'Created by Me',
        notSubmit: 'Not Submitted',
        submitted: 'Submitted',
        pendingProcessing: 'Pending Processing',
        myHandled: 'Handled by Me',
        qwjs: 'full-text searching'
    },
    paramConfig: {
        // 表单及列表页面属性标签-参数设置模块
        formTitle: 'Param Config',
        fields: {
            paramName: 'Parameter name',
            paramValue: 'Parameter value',
            paramUnit: 'Parameter unit',
            paramDesc: 'Parameter desc'
        }
    },
    settings: {
        // 用户个性设置面板
        title: 'Page style setting',
        theme: 'Theme Color',
        tagsView: 'Open Tags-View',
        fixedHeader: 'Fixed Header',
        showTopMenus: 'Open TopMenu'
    },
    options: {
        flType: {
            单位发文: 'Unit Document',
            联合发文: 'Joint Document',
            共青团文件: 'Communist Youth League Document',
            纪检发文: 'Disciplinary Inspection Document',
            办公室文件: 'Office Document',
            请示发文: 'Request Document',
            平级发文: 'Peer Document',
            单位通知: 'Unit Notification',
            会议通知: 'Meeting Notification',
            会议纪要: 'Meeting Minutes',
            人事任命: 'Personnel Appointment'
        },
        flELevel: {
            紧急: 'Urgent',
            平件: 'Ordinary Document',
            平急: 'Ordinary Urgent',
            急件: 'Urgent Document',
            加急: 'Expedited',
            特急: 'Special Urgent',
            特提: 'Special Request'
        },

        flSLevel: {
            平件: 'Ordinary Document',
            秘密: 'Secret',
            内部资料: 'Internal Information',
            机密: 'Confidential',
            绝密: 'Top Secret'
        },
        dbType: {
            0: 'Document Supervision',
            1: "Leader's Instructions",
            2: 'Key Task',
            3: 'Meeting Decision'
        },
        dbMode: {
            0: 'Weekly',
            1: 'Monthly',
            2: 'Quarterly',
            3: 'One-time',
            4: 'Custom'
        },
        importantLevel: {
            0: 'Ordinary',
            1: 'Important',
            2: 'Very Important'
        },
        urgentLevel: {
            0: 'Ordinary',
            1: 'Emergent',
            2: 'Urgent',
            3: 'Extremely Urgent'
        },
        dbSource: {
            0: 'Assigned by superior units',
            1: 'Assigned by the decision-making level',
            2: 'Assigned by department leaders',
            3: 'Official document assignment',
            4: 'Conference assignment'
        },

        msgTypes: {
            0: 'Mail',
            1: 'SMS notification'
        },
        yesOrNo: {
            0: 'NO',
            1: 'YES'
        },
        successFailed: {
            0: 'Failed',
            1: 'Success'
        },
        validOrInvalid: {
            0: 'Invalid',
            1: 'Valid'
        },
        disableOrEnable: {
            0: 'Disable',
            1: 'Enable'
        }
    },
    common: {
        pleaseSelect: 'Please select',
        loading: 'Loading...',
        noData: 'No data available',
        operationTip: 'Operation tip',
        confirm: 'Confirm operation',
        saveSuccess: 'Saved successfully',
        noAttach: 'No attachments',
        attach: 'Attachment',
        title: 'Title',
        Monday: 'Monday',
        Tuesday: 'Tuesday',
        Wednesday: 'Wednesday',
        Thursday: 'Thursday',
        Friday: 'Friday',
        Saturday: 'Saturday',
        day: 'Day',
        week: 'Week',
        month: 'Month',
        year: 'Year',
        today: 'Today',
        Sunday: 'Sunday',
        enable: 'Enable',
        disable: 'Disable',
        to: 'To',
        yes: 'Yes',
        no: 'No',
        nothing: 'None',
        selectArea: 'Select area',
        selectAreaPlaceholder: 'Please select an area',
        selectOrg: 'Select organization',
        selectOrgPlaceholder: 'Please select an organization',
        selectOrgPer: 'Select organization personnel',
        selectOrgPlaceholderPer: 'Please select organization personnel',
        selectGroup: 'Select group',
        groupPlaceholder: 'Please select a group',
        selectDepart: 'Select department',
        departPlaceholder: 'Please select a department',
        selectKsment: 'Select department (section)',
        pSelectKsment: 'Please select a department (section)',
        selectPerson: 'Select person',
        personPlaceholder: 'Please select a person',
        fileName: 'File name',
        fileSize: 'File size',
        fileType: 'File type',
        all: 'All',
        plChoose: 'Please select',
        plChooseContent: 'Please select {content}',
        plInput: 'Please input',
        plInputCon: 'Please input content',
        plInputContent: 'Please input {content}',
        startTimePlaceholder: 'Please select start time',
        endTimePlaceholder: 'Please select end time'
    },
    pageBtns: {
        add: 'Add',
        addTo: 'Add to',
        addToAttach: 'Add attachment',
        edit: 'Edit',
        del: 'Delete',
        save: 'Save',
        saveAsFile: 'SaveAs',
        cancel: 'Cancel',
        sure: 'Confirm',
        look: 'View',
        update: 'Update',
        close: 'Close',
        operate: 'Operate',
        reset: 'Reset',
        search: 'Search',
        resetSearch: 'Reset search',
        export: 'Export',
        import: 'Import',
        print: 'Print',
        download: 'Download',
        forward: 'Forward',
        submit: 'Submit',
        back: 'Return',
        saveAdd: 'Save and add',
        send: 'Send',
        ok: 'OK',
        isCompleted: 'Completed',
        escalation: 'Escalate',
        signFor: 'Sign for',
        open: 'Open',
        saveAndClose: 'Save and close',
        urgeProcessing: 'Urge processing',
        setting: 'Settings',
        preview: 'Preview',
        upload: 'Upload',
        comDel: 'Delete permanently',
        uploadFile: 'Upload file',
        downFile: 'Download file',
        addFile: 'Add file',
        deleteFile: 'Delete file',
        more: 'More'
    },
    fields: {
        index: 'SN',
        flCreateDept: 'CreateDept',
        flCreateDate: 'CreateDate',
        flCreator: 'Creator',
        flCreatorPhone: 'CreatorPhone',
        flSubject: 'Subject',
        wfNodeNames: 'Processing Steps',
        startDate: 'Start Date',
        endDate: 'End Date',
        remark: 'Remark',
        status: 'Status',
        operate: 'Operation',
        caozuo: 'Operation',
        column_operate: 'Operation',
        name: 'Name',
        overDate: 'Deadline',
        startTime: 'Start Time',
        endTime: 'End Time',
        phone: 'Contact Phone',
        wfType: 'Applicable file types'
    },
    wfdProcess: {
        //流程常用环节、路径、意见栏
        nodes: {
            start: 'Start',
            bmsh: 'Dept review',
            bmhq: 'Dept countersign',
            toread: 'To read',
            proposed: 'Proposed Action',
            distribute: 'Distribute',
            ldps: "Leader's Instruction",
            handle: 'Handle',
            completed: 'Completed',
            end: 'End'
        },
        paths: {
            toBmsh: 'Send to Dept for review', //tobmsh为路径i18n代码
            toBmhq: 'Send to Department Countersign',
            toProposed: 'Send for Proposed Action',
            toDistribute: 'Send for Distribution',
            toLdps: "Send for Leader's Instruction",
            toHandle: 'Send for Handling',
            toCompleted: 'Complete',
            toEnd: 'End'
        },
        ideaLabels: {
            bmshyj: 'Dept review opinions', //部门审核意见
            bmhqyj: 'Dept countersign opinions', //部门会签意见
            fbyj: 'Release opinions', //发布意见
            bmshIdea: 'Department Review Opinion',
            bmhqIdea: 'Department Countersign Opinion',
            proposedIdea: 'Proposed Action Opinion',
            distributeIdea: 'Distribution Opinion',
            ldpsIdea: "Leader's Instruction Opinion",
            handleIdea: 'Handling Opinion',
            completedIdea: 'Completion Opinion'
        },
        operations: {
            close: 'Close', //关闭
            save: 'Save', //保存
            saveAndClose: 'Save&Close', //保存关闭
            writeoff: 'WriteOff', //注销文件
            print: 'Print', //打印
            printer: 'Print', //打印
            submit: 'Submit', //提交
            readed: 'Readed', //已阅
            readIdea: 'ReadingOpinion', //阅文意见
            contestClaim: 'Claim', //认领
            addReader: 'Circularize', //传阅
            wflowTrace: 'ProcessTracking', //流程跟踪
            viewBodyDoc: 'ViewFile', //查看正文
            modifyBodyDoc: 'ModifyFile', //修改正文
            editBodyDoc: 'EditFile', //编辑正文
            modifyBusiform: 'ModifyForm', //修改表单信息
            formModifiedTraces: 'FormModifiedTraces', //查看表单修改痕迹
            callback: 'Callback', //收回文件
            modifyWflog: 'ProcessControl', //流程管控
            donext: 'Deputy', //代办
            SendFileToDept: 'SendFileToDept', //发送本单位
            printCld: 'PrintForm', //打印处理单
            printNgz: 'PrintPaper', //打印拟稿纸
            fileJb: 'ToAssignment', //文件交办
            forwardDb: 'ToSupervision', //转督办事项
            selectLinkFile: 'RelatedFile', //关联文件
            switchSingleDualScreen: 'SwitchScreen', //切换双显
            cancellation: 'cancellation', //注销
            fillDocNumber: 'FillInDocNumber', //填写文号
            setRed: 'SetRed', //套红
            bodyFileSign: 'UploadStampedDoc', //上传盖章文件
            pdfFileSign: 'SealAndStamp', //盖章
            saveAs: 'SaveAs', //另存为
            sendFile: 'SendFile', //发送文件
            forward: 'Forward', //转发
            SendFileToCorp: 'SendFileToCorp', //发送外单位
            viewSendlog: 'ViewSendlog', //发送跟踪
            printCpb: 'PrintBatchForm', //打印呈批表
            printFile: 'PrintFile', //打印文件
            transferOffice: 'TransferOffice', //转办
            addWfNodeUsers: 'AddWfNodeUsers' //增加拟办人
        },
        tabItem: {
            form: 'Busiform',
            bodyfile: 'Bodyfile',
            attachs: 'Attachments',
            wftrace: 'FlowChat',
            wflogs: 'TodoLog',
            readlogs: 'ViewLog',
            wfstatistic: 'Statistic'
        }
    }
}
