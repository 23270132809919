<template>
    <div v-if="!item.hidden" class="menu-wrapper">
        <template v-if="hasOneShowingChild(item.children, item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren) && !item.alwaysShow">
            <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
                <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{ 'submenu-title-noDropdown': !isNest }" @click="clickItem(item)">
                    <item :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)" :level="item.meta.level" :title="generateTitle(onlyOneChild.meta)" />
                </el-menu-item>
            </app-link>
        </template>

        <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
            <template slot="title">
                <item v-if="item.meta" :icon="item.meta && item.meta.icon" :level="item.meta.level" :title="generateTitle(item.meta)" />
            </template>
            <sidebar-item v-for="child in item.children" :key="child.path" :is-nest="true" :item="child" :base-path="resolvePath(child.path)" class="nest-menu" :class="{ notop: !showTopMenus }" />
        </el-submenu>
    </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/global/utils/index'
import Item from './Item'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'

export default {
    name: 'SidebarItem',
    components: { Item, AppLink },
    mixins: [FixiOSBug],
    props: {
        // route object
        item: {
            type: Object,
            required: true
        },
        isNest: {
            type: Boolean,
            default: false
        },
        basePath: {
            type: String,
            default: ''
        },
        layoutPortal: {
            type: Number,
            default: 0
        }
    },
    data() {
        // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
        // TODO: refactor with render function
        this.onlyOneChild = null
        return {}
    },
    computed: {
        showTopMenus() {
            return this.$store.state.settings.showTopMenus
        }
    },
    created() {
        if (this.item.meta && this.item.meta.wfName && this.item.meta.wfEngineUrl) {
            var meta = this.item.meta
            var url = location.href.split('#')[0] + '#/wflow?busiCode=' + meta.href + '&wfName=' + meta.wfName + '&startNodeName=' + meta.wfNodeName + '&wfEngineUrl=' + meta.wfEngineUrl
            this.item.path = url
        }
    },
    methods: {
        clickItem(item) {
            if (this.layoutPortal === 1 && sessionStorage.getItem('setSysMenuName') === '首页') {
                this.Cookies.set('topMenuIndex', 0)
                this.$bus.emit('updateleftMenuIndex', item.meta.id)
            }
        },
        hasOneShowingChild(children = [], parent) {
            const showingChildren = children.filter((item) => {
                if (item.hidden) {
                    return false
                } else {
                    // Temp set(will be used if only has one showing child)
                    this.onlyOneChild = item
                    return true
                }
            })

            // When there is only one child router, the child router is displayed by default
            if (showingChildren.length === 1) {
                return true
            }

            // Show parent if there are no child router to display
            if (showingChildren.length === 0) {
                this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
                return true
            }

            return false
        },
        resolvePath(routePath) {
            if (isExternal(routePath)) {
                return routePath
            }
            if (isExternal(this.basePath)) {
                return this.basePath
            }
            return path.resolve(this.basePath, routePath)
        },
        generateTitle(meta) {
            return this.$i18n.generateTitle(meta, this)
        }
    }
}
</script>
<style lang="scss">
@import '~@/wapp/common/styles/variables.module.scss';
#wapp {
    .el-menu {
        .menu-icon-2 {
            font-size: 16px;
        }
        .menu-level-3 {
            // border-left: 1px dotted #9dbdd6;
            display: inline-block;
            // margin-left: -10px;
            padding-left: 10px;
            font-size: 13px;
            opacity: 0.8;
        }
        .menu-level-4 {
            margin-left: -21px !important;

            .prefix {
                display: inline-block;
                width: 15px;
                // border-top: 1px dotted #9dbdd6;
                margin-left: -10px;
                margin-right: 10px;
            }
        }

        .el-submenu {
            color: var(--lmTextColor);
            &:hover:before {
                display: block;
            }
            > .el-submenu__title {
                height: 50px;
                line-height: 49px;
                .el-icon-arrow-down {
                    color: var(--lmTextColor);
                    -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg);
                }
            }
            &.is-opened > .el-submenu__title .el-icon-arrow-down {
                -webkit-transform: rotate(0);
                transform: rotate(0);
            }
        }

        > .nest-menu {
            > a {
                :hover {
                    // :before{
                    //     content: "";
                    //     top: 20px;
                    //     left: 30px;
                    //     display: block;
                    //     position: absolute;
                    //     border: 8px solid transparent;
                    //     border-width: 5px 4px;
                    //     border-left-color:  var(--bgColor);
                    // }
                }
                > li.el-menu-item {
                    line-height: 49px;
                    height: 49px;
                    color: var(--lmTextColor) !important;
                    > span {
                        display: block;
                        padding-left: 4px;
                        // border-left: 1px dotted red;
                        // margin-left:-10px;
                    }
                }
            }

            a.router-link-active {
                > li.is-active {
                    // :before{
                    //     content: "";
                    //     top: 20px;
                    //     left: 30px;
                    //     display: block;
                    //     position: absolute;
                    //     border: 8px solid transparent;
                    //     border-width: 5px 4px;
                    //     border-left-color:  var(--bgColor);
                    // }
                    font-weight: bold;
                    :after {
                        content: '';
                        top: 10px;
                        right: 0;
                        display: block;
                        position: absolute;
                        border: 8px solid transparent;
                        border-width: 14px 10px;
                        border-right-color: var(--lmTextColorActive);
                    }
                }
            }
        }

        > .nest-menu:last-child > a {
        }

        .submenu-title-noDropdown {
            line-height: 49px;
            color: var(--lmTextColor) !important;
            background-color: var(--lmBgColor) !important;
            &:hover {
                color: var(--lmBgColor);
                background-color: var(--lmBgColor);
            }

            &.is-active {
                color: var(--lmTextColorActive);
                font-weight: bold;
                background-color: var(--lmBgColorActive) !important;
                &::after {
                    content: '';
                    top: 10px;
                    right: 0;
                    display: block;
                    position: absolute;
                    border: 8px solid transparent;
                    border-width: 14px 10px;
                    border-right-color: var(--lmTextColorActive);
                }
            }
        }
    }
    /* 有头部导航 */
    .std-scrollbar-wrapper {
        .el-menu {
            > .nest-menu > a {
                // background-color: var(--lmSubBg);

                li.el-menu-item {
                    color: var(--lmTextColor) !important;
                    background-color: transparent !important;
                }
                &.router-link-active > li.is-active {
                    color: var(--lmTextColorActive) !important;
                    background-color: var(--lmBgColorActive) !important;
                    > span {
                        color: var(--lmTextColorActive);
                    }
                }
                &:hover {
                    .el-menu-item {
                        color: var(--lmTextColorActive) !important;
                        background-color: var(--lmBgColorActive) !important;
                        span {
                            // color:var(--lmBgActiveColor);
                            // &:after {
                            //     content: "";
                            //     top: 10px;
                            //     right: 0;
                            //     display: block;
                            //     position: absolute;
                            //     border: 8px solid transparent;
                            //     border-width: 14px 10px;
                            //     border-right-color: var(--lmBgActiveColor);
                            // }
                        }
                    }
                }
            }
            .el-submenu > .el-submenu__title {
                // border-bottom:1px solid #ececec;
                &:hover {
                    color: var(--lmTextColorActive) !important;
                    background-color: var(--lmBgColorActive) !important;
                }
            }
            .submenu-title-noDropdown {
                // border-bottom:1px solid #ececec;
                &:hover {
                    color: var(--lmTextColorActive) !important;
                    background-color: var(--lmBgColorActive) !important;
                }
                &.is-active {
                    color: var(--lmTextColorActive) !important;
                }
            }
        }
    }
    /* 隐藏头部导航 */
    .dark-scrollbar-wrapper {
        .el-menu {
            > .nest-menu > a {
                border-bottom: 1px dotted #777;
            }
            .el-submenu > .el-submenu__title {
                border-bottom: 1px solid #475871;
                color: var(--textColor) !important;
            }
            .submenu-title-noDropdown {
                border-bottom: 1px solid #475871;
                color: var(--textColor) !important;
                &.is-active {
                    background-color: $dark-menu-hover !important;
                }
            }
        }
    }
}
// 修改皮肤更换左导航

.el-menu {
    .el-submenu {
        &:before {
            background-color: var(--bgColor);
        }
    }
    > .nest-menu {
        > a {
            :hover {
                :before {
                    border-left-color: var(--bgColor);
                }
            }
        }
        a.router-link-active {
            > li.is-active {
                ::before {
                    border-left-color: var(--bgColor);
                }
                ::after {
                    border-right-color: var(--bgColor);
                }
            }
        }
    }
    .submenu-title-noDropdown {
        &:hover {
            &::before {
                background-color: var(--bgColor);
            }
            &::after {
                border-right-color: var(--bgColor);
            }
        }
        &.is-active {
            &::before {
                background-color: var(--bgColor);
            }
            &::after {
                border-right-color: var(--bgColor);
            }
        }
    }
}
.std-scrollbar-wrapper {
    .el-menu {
        > .nest-menu > a {
            &.router-link-active > li.is-active {
                background-color: var(--lmBgColor) !important;
                > span {
                    color: var(--bgColor);
                }
            }
            &:hover {
                .el-menu-item {
                    background-color: var(--lmBgColor) !important;
                    span {
                        //   color: var(--bgColor);
                        //   &:after{border-right-color:var(--bgColor);}
                    }
                }
            }
        }
        .el-submenu > .el-submenu__title {
            &:hover {
                color: var(--bgColor) !important;
            }
        }
        .submenu-title-noDropdown {
            &:hover {
                color: var(--bgColor) !important;
                background-color: var(--lmBgColor) !important;
            }
            &.is-active {
                color: var(--bgColor) !important;
                background-color: var(--lmBgColor);
            }
        }
    }
}
.dark-scrollbar-wrapper {
    .el-menu {
        .el-submenu > .el-submenu__title {
            color: var(--bgColor) !important;
        }
        .submenu-title-noDropdown {
            color: var(--bgColor) !important;
            &.is-active {
                background-color: var(--lmBgColor) !important;
            }
        }
    }
}

.el-menu--vertical > .el-menu .el-menu-item {
    color: var(--lmTextColor) !important;
}
.el-menu--vertical > .el-menu .el-menu-item.is-active {
    color: var(--lmTextColorActive) !important;
}
.el-menu--vertical > .el-menu .el-menu-item.is-active:hover {
    color: var(--lmTextColorActive) !important;
}
.el-menu--vertical > .el-menu {
    background: none !important;
}
.el-menu--vertical > .el-menu .nest-menu:last-child {
    //  border-top: 1px solid #ececec !important;
}
</style>
