<template>
    <div v-show="showRoute" :class="classObj" class="app-wrapper" style="min-width: 1200px" @click="handleBodyOnClick">
        <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
        <topbar v-if="showRoute" :style-layout="layoutStyle" :bg-url="bgUrl" />
        <TopMenu v-if="showRoute && layoutStyle === 1" class="topMu" :bg-url="bgUrl" :style-layout="1" @showToggle="showToggle" />
        <template v-if="routeTarget === 'mainpage'">
            <div class="mainpage">
                <Sidebar v-if="showSideBar && layoutStyle === 1" class="sidebar-container" :class="layoutStyle === 1 ? 'leftMenuStyle' : ''" />
                <router-view v-if="showRoute" />
                <div v-if="hoverSideB && layoutStyle === 1" class="hoverSideBox">
                    <hoverSide class="sidebar-container leftMenuStyle" :hover-data="hoverSideData" />
                </div>
            </div>
        </template>
        <template v-else-if="routeTarget">
            <sidebar v-if="showSideBar" class="sidebar-container" :class="layoutStyle === 1 ? 'leftMenuStyle' : ''" />
            <div v-if="hoverSideB && layoutStyle === 1" class="hoverSideBox">
                <hoverSide class="sidebar-container leftMenuStyle" :hover-data="hoverSideData" />
            </div>
            <div :class="[{ hasTagsView: needTagsView }, { 'left-menu-main-container': layoutStyle === 1 }]" class="main-container">
                <div :class="{ 'fixed-header': fixedHeader }">
                    <tags-view v-if="needTagsView && showTopMenus" />
                </div>
                <app-main />
                <right-panel v-if="showSettings">
                    <settings />
                </right-panel>
            </div>
        </template>
    </div>
</template>

<script>
import RightPanel from '@/wapp/common/components/RightPanel'
import { Topbar, AppMain, Settings, Sidebar, TagsView } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState, mapGetters } from 'vuex'
import TopMenu from './components/Topbar/TopMenu.vue'
import hoverSide from './components/Sidebar/hoverSide.vue'
export default {
    name: 'Layout',
    components: {
        Topbar,
        AppMain,
        RightPanel,
        Settings,
        Sidebar,
        TagsView,
        TopMenu,
        hoverSide
    },
    mixins: [ResizeMixin],
    data() {
        return {
            routeTarget: null,
            showSideBar: true,
            hoverSideData: {},
            showRoute: false,
            hoverSideB: false,
            style: '',
            pageName: '',
            isNeed: false,
            bgUrl: '',
            lbUrl: '',
            changeSideFromEmail: false,
            subProducts: false,
            layoutStyle: 0 // 菜单栏布局方式
        }
    },
    computed: {
        ...mapState({
            sidebar: (state) => state.app.sidebar,
            device: (state) => state.app.device,
            showSettings: (state) => state.settings.showSettings,
            needTagsView: (state) => state.settings.tagsView,
            fixedHeader: (state) => state.settings.fixedHeader
        }),
        ...mapGetters(['loginUser', 'routes']),
        classObj() {
            return {
                hideSidebar: !this.sidebar.opened,
                openSidebar: this.sidebar.opened,
                withoutAnimation: this.sidebar.withoutAnimation,
                mobile: this.device === 'mobile',
                hideLeftMenuStyle: this.layoutStyle === 1 && !this.showSideBar,
                emailSide: this.changeSideFromEmail,
                noNeedLeftMenu: this.isNeed,
                subProducts: this.subProducts // 门户子产品不需要左侧菜单
            }
        },
        showTopMenus() {
            return this.$store.state.settings.showTopMenus
        }
    },
    watch: {
        $route(val) {
            this.setRouterView()
            this.pageName = sessionStorage.getItem('setSysMenuName')
        }
    },
    created() {
        this.getUiStyle()
        this.$bus.on('leftSiderHide', this.leftSiderHide)
        this.pageName = sessionStorage.getItem('setSysMenuName')
        document.querySelector('body').setAttribute('id', this.loginUser.orgCode !== 'plat' ? this.style : 'skinScienceBlue')
        this.$bus.on('openSideBar', this.openSideBar)
        this.$bus.on('hoverSideBar', this.hoverSideBar)
        this.$bus.on('hideHoverSideBar', this.hideHoverSideBar)
        this.$bus.on('updateEnevleList', this.getEnevleList)
        this.$bus.on('hideMainLeft', this.hideMainLeft)
        this.$bus.on('enterEmailChangeSide', this.enterEmailChangeSide)
        const productList = this.$store.state.productCodeList
        for (const key in productList) {
            if (productList[key] === 'ecygwgl') {
                this.getEnevleList()
            }
        }
    },
    mounted() {
        if (this.loginUser.pwdExpireTime !== null) {
            this.$message({ message: '您的密码即将于' + this.loginUser.pwdExpireTime + '到期，请在到期前修改您的密码！', type: 'warning', showClose: true, duration: 5000 })
        }
        setTimeout(() => {
            this.setRouterView()
        }, 0)
    },
    destroyed() {
        // this.$bus.off('leftSiderHide', this.leftSiderHide)
        this.$bus.off('updateEnevleList', this.getEnevleList)
        this.$bus.off('openSideBar', this.openSideBar)
        this.$bus.off('hoverSideBar', this.hoverSideBar)
        this.$bus.off('hideHoverSideBar', this.hideHoverSideBar)
        this.$bus.off('hideMainLeft', this.hideMainLeft)
        this.$bus.off('enterEmailChangeSide', this.enterEmailChangeSide)
    },
    methods: {
        hideMainLeft(val) {
            this.isNeed = val
        },
        getUiStyle() {
            this.$http({
                method: 'get',
                url: 'base/user/getById',
                contentType: 'application/x-www-form-urlencoded',
                params: { id: this.loginUser.userId }
            })
                .then((res) => {
                    const cookieStyle = this.Cookies.get('uiStyle')
                    this.style = ''
                    if (res.body.vo.uiStyle) {
                        if (cookieStyle !== res.body.vo.uiStyle) {
                            this.style = res.body.vo.uiStyle
                            this.Cookies.set('uiStyle', res.body.vo.uiStyle)
                        } else {
                            this.style = cookieStyle
                        }
                    } else {
                        this.style = cookieStyle
                    }
                    this.getLayoutStyle(this.style)
                })
                .catch(() => {
                    this.getLayoutStyle()
                    this.showRoute = true
                })
        },
        // 获取菜单栏布局方式
        getLayoutStyle(code) {
            this.$http({
                url: '/base/styleConfig/querydata',
                method: 'post',
                contentType: 'application/x-www-form-urlencoded',
                data: [
                    { compare: '=', field: 'styleCode', merge: true, value: code || '' },
                    { compare: '=', field: 'orgCode', merge: true, value: this.loginUser.orgCode }
                ]
            })
                .then((res) => {
                    this.showRoute = true
                    if (res.code === 'success') {
                        if (res.body.listdata.length > 0) {
                            this.layoutStyle = res.body.listdata[0].columnMenu
                            window.layoutStyle = this.layoutStyle
                            if (res.body.listdata[0].inHoliday) {
                                document.body.style.setProperty('--bgColorFive', res.body.listdata[0].bg + '0D')
                                document.body.style.setProperty('--bgColorThree', res.body.listdata[0].bg + '08')
                                document.body.style.setProperty('--bgColorTen', res.body.listdata[0].bg + '1a')
                                if (res.body.listdata[0].topbarBgImg) {
                                    this.bgUrl = process.env.VUE_APP_BASE_API + '/base/styleConfig/download?nameField=topbarBgImg&dataField=topbarBgImgFile&id=' + res.body.listdata[0].id
                                }
                            } else {
                                document.body.style.setProperty('--bgColorFive', res.body.listdata[0].bg + '0D')
                                document.body.style.setProperty('--bgColorThree', res.body.listdata[0].bg + '08')
                                document.body.style.setProperty('--bgColorTen', res.body.listdata[0].bg + '1a')
                                if (res.body.listdata[0].topbarBgImg) {
                                    this.bgUrl = process.env.VUE_APP_BASE_API + '/base/styleConfig/download?nameField=topbarBgImg&dataField=topbarBgImgFile&id=' + res.body.listdata[0].id
                                }
                            }
                        } else {
                            this.layoutStyle = 0
                            window.layoutStyle = this.layoutStyle
                        }
                    } else {
                        this.layoutStyle = 0
                        window.layoutStyle = this.layoutStyle
                    }
                    if (this.layoutStyle !== 1) {
                        this.showSideBar = true
                    } else {
                        if (this.sidebar.opened && sessionStorage.getItem('setSysMenuName') !== '应用中心' && sessionStorage.getItem('setSysMenuName') !== '首页') {
                            this.showSideBar = true
                        } else {
                            this.showSideBar = false
                        }
                        console.log('=============================', localStorage.getItem('subProducts'))
                        if (localStorage.getItem('subProducts') === '1') {
                            this.subProducts = true
                        }
                    }
                })
                .catch(() => {
                    this.showRoute = true
                    this.layoutStyle = 0
                    this.showSideBar = true
                    window.layoutStyle = this.layoutStyle
                })
        },
        enterEmailChangeSide(falg) {
            this.changeSideFromEmail = falg
        },
        leftSiderHide() {
            this.showSideBar = false
        },
        openSideBar(flag) {
            this.showSideBar = flag
        },
        hoverSideBar(row) {
            this.hoverSideB = true
            this.hoverSideData = row
        },
        hideHoverSideBar() {
            this.hoverSideB = false
        },
        handleClickOutside() {
            this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
        },
        handleBodyOnClick() {
            this.$bus.emit('bodyOnClick', event)
        },
        getEnevleList() {
            const loginUser = this.$store.getters.loginUser
            const param = {
                urlsearch: `orgCode:${loginUser.orgCode}`
            }
            this.$app.listdata('gwgl/fileLevel/listdata', param).then((res) => {
                if (res.code === 'success') {
                    const colorsList = res.body.pageInfo.list
                    sessionStorage.setItem(loginUser.orgCode + '_LEVEL', JSON.stringify(colorsList))
                    this.$nextTick(() => {
                        this.$bus.emit('loadEnevlelist')
                    })
                }
            })
        },
        setRouterView() {
            if (this.$route.path === '/home') {
                this.Cookies.set('topMenuIndex', '0')
                const defaultPath = this.getDefaultPath(this.routes)
                if (defaultPath !== undefined) {
                    this.$router.replace({ ...defaultPath })
                }
            } else if (this.$route.meta && this.$route.meta.target) {
                this.routeTarget = this.$route.meta.target
            } else {
                this.routeTarget = 'content'
            }

            //设置浏览器窗口标题
            if (this.$route.meta) {
                if (this.$route.meta.level && this.$route.meta.level >= 4) {
                    const i18nCode = this.$route.meta.parentI18nCode || this.$route.meta.parentIconCode
                    document.title = this.$i18n.getPageTitle(this.$route.meta.parentTitle, i18nCode, this.$route.meta.href, this)
                } else {
                    const i18nCode = this.$route.meta.i18nCode || this.$route.meta.code
                    document.title = this.$i18n.getPageTitle(this.$route.meta.title, i18nCode, this.$route.meta.href, this)
                }
            }
        },
        getDefaultPath(routes) {
            for (let i = 0; i < routes.length; i++) {
                if (typeof routes[i].children !== 'undefined') {
                    return this.getDefaultPath(routes[i].children)
                } else if (((routes[i].hidden !== true || routes[i].meta.level === 4) && typeof routes[i].redirect === 'undefined') || (routes[i].meta && (routes[i].meta.href.indexOf('@/') === 0 || routes[i].meta.href.indexOf('#/') === 0))) {
                    return routes[i]
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import '~@/wapp/common/styles/mixin.scss';
@import '~@/wapp/common/styles/variables.module.scss';
/deep/.el-scrollbar__bar.is-horizontal {
    height: 0 !important;
}
.main-container {
    background-color: $sys-bg-color;
}
.app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;

    &.mobile.openSidebar {
        position: fixed;
        top: 0;
    }
}

.drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
}

.fixed-header {
    position: fixed;
    top: $headerHeight;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
}

.mobile .fixed-header {
    width: 100%;
}
</style>
